<template>
	<TableWrapper
        :list="list"
        style="--cols: 4"
	>
		<div class="simple-table">
			<p class="first-col st-header">Exception Type</p>
			<p class="st-header">Description</p>
			<p class="st-header">Date</p>
			<p class="last-col st-header">Actions</p>

			<template v-for="row in list.displayedList" :key="row.id">
				<div class="first-col"><span class="st-hidden">Exception Type</span>{{ row.exc_type }}</div>
				<div><span class="st-hidden">Description</span>{{ row.exc_desc }}</div>
				<div><span class="st-hidden">Date</span>{{ row.exc_date }}</div>
                <div>
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <button class="btn-blue" @click="edit(row.guid)">Edit</button>
                    </div>
                </div>
			</template>
		</div>
	</TableWrapper>
    <div class="flex justify-end mt-4">
        <button @click="clearExceptions()" class="btn btn-blue m-0 flex-grow-0">Clear Exceptions</button>
    </div>
</template>

<script>
import EloquentSSFP from "@/EloquentSSFP";
import { reactive } from 'vue';
import TableWrapper from '@/Components/TableWrapper.vue';

export default {
    name: "ExceptionsTable",
    components: {
        TableWrapper
    },
	data: function () {
		return {
			list: reactive(new EloquentSSFP()),
		};
	},
    methods: {
        edit(guid) {
            alert("They didn't hook this up yet!");
        },
        clearExceptions() {
            if (!confirm("Are you sure want to remove all of this patient's exceptions?")) return;
            axios.get(
                    route('clear.exceptions', this.$page.props.patients_guid)
                ).then(res => {
                    this.$page.props.jetstream.flash.banner = res.data.message;
                    this.list.refreshData(this.list.options);
                }).catch(err => {
                    console.error(err);
                    this.$page.props.jetstream.flash.bannerStyle = 'danger';
                    this.$page.props.jetstream.flash.banner = err.response.data.message;
                })
        }
    },
    mounted() {
        this.list.init('fetch_exceptions', {
            activeSorts: ["date"],
            sortTypes: ["date"],
            sortDirs: ["asc"],
            itemsPerPage: 20
        })
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../../scss/_simple-table-styles.scss';
</style>