<template>
    <div class="comm-data">
        <div class="loader" v-if="loading"></div>

        <form @submit.prevent="onSubmit($event)" ref="form">
            <p class="success-message" v-if="successMessage">
                {{ successMessage }}
            </p>
            <p class="error-message" v-if="errorMessage">
                {{ errorMessage }}
            </p>

            <div class="fr-questions">
                <div class="fr-question">
                    <FRQuestionChipSelector
                        questionId="record_status[]"
                        inputType="checkbox"
                        :question="{id: 'record_status[]', question: 'Record Status', options: ['active', 'inactive'], required: true}"
                        :options="{active: {value: 'Active'}, inactive: {value: 'Inactive'}}"
                    />
                </div>

                <div class="fr-question">
                    <FRQuestionChipSelector
                        questionId="survey_status[]"
                        inputType="checkbox"
                        :question="{id: 'survey_status[]', question: 'Survey Status', options: ['due', 'overdue'], required: true}"
                        :options="{due: {value: 'Due'}, overdue: {value: 'Overdue'}}"
                    />
                </div>

                <div class="fr-question">
                    <FRQuestionChipSelector
                        questionId="communication_type[]"
                        inputType="checkbox"
                        :question="{id: 'communication_type[]', question: 'Communication Type', options: ['usps', 'sms', 'email'], required: true}"
                        :options="{usps: {value: 'USPS'}, sms: {value: 'SMS'}, email: {value: 'Email'}}"
                    />
                </div>
            </div>

            <button class="btn btn-blue text-white flex-grow-0 mt-6">Export Comm Data</button>
        </form>
    </div>
</template>

<script>
import FRQuestionChipSelector from '../../Components/FormElements/FRQuestionChipSelector.vue';

export default {
    components: {
        FRQuestionChipSelector
    },
    data() {
        return {
            activeView: "CommData",
            loading: false,
            successMessage: false,
            errorMessage: false,
        };
    },
    methods: {
        onSubmit(event) {
            let formData = new FormData(event.target)

            document.body.style.opacity = .5
            document.body.style.pointerEvents = 'none'

            axios({
                url: route('analytics.get-comm-data'),
                method: 'POST',
                data: formData,
                responseType: 'blob'
            }).then(res => {
                if (!res.data.size) {
                    window.alert("There were no results for that combination of filters.")
                } else {
                    const href        = URL.createObjectURL(res.data)
                    const link        = document.createElement('a')
                    const currentDate = new Date().toJSON().slice(0, 10)
                    link.href         = href
                    link.setAttribute('download', `${currentDate}-comm-data.csv`)
                    document.body.appendChild(link)
                    link.click()
                    
                    document.body.removeChild(link)
                    URL.revokeObjectURL(href)
                }
            }).catch(err => {
                console.error(err)
            }).finally(_ => {
                document.body.style.opacity = 1
                document.body.style.pointerEvents = 'all'
            })
        }
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../scss/_mixins.scss';

    form {
        .questions {
            display: flex;
            flex-wrap: wrap;
            margin: .5rem -.5rem;

            .question {
                padding: .5rem;
                flex-grow: 1;

                @include mobile {
                    flex-basis: 100% !important;
                }
            }
        }

        .section {
            margin: 2rem 0;
        }
    }

    .fr-questions {
        margin: -.5rem;
    }

    .fr-question, .fr-sub-question {
        flex-grow: 1;
        padding: .5rem;
        max-width: 100%;
    }

    :deep(.fr-required) {
        color: var(--red-500);
    }
</style>
