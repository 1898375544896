<template>
    <AppLayout title="Team Settings">
        <h1>Thank you for filling out your follow up!</h1>
    </AppLayout>
</template>

<script>
    import AppLayout from '@/Layouts/PatientFormLayout.vue'

    export default {
        components: {
            AppLayout,
        },
    }
</script>
