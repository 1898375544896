<template>
    <div class="checkbox-wrapper">
        <!-- Only one option - normal checkbox -->
        <div class="fr-group" :class="validClass" v-if="!options">
            <label class="fr-option">
                <input
                    v-if="modelValue === undefined"
                    class="fr-control-checkbox"
                    :name="questionId"
                    type="checkbox"
                    :required="required"
                    :aria-required="required"
                    :value="questionId"
                    @focus="focusSection()"
                    @change="checkValue()"
                    @blur="checkValue()"
                />
                <input
                    v-else
                    class="fr-control-checkbox"
                    :name="questionId"
                    type="checkbox"
                    :required="required"
                    :aria-required="required"
                    :value="questionId"
                    v-model="inputModel"
                    @focus="focusSection()"
                    @change="checkValue()"
                    @blur="checkValue()"
                />
                {{ question.question }}<span class="fr-required" v-if="required">*</span>
            </label>
            <p class="fr-assistive">Please select this field to continue</p>
        </div>

        <template v-else>
            <template v-if="modelValue === undefined">
                <!-- Too many options - dropdown  -->
                <FRQuestionFieldsetDropdown v-if="needsDropdown"
                    input-type="checkbox"
                    :question-id="questionId"
                    :question="question"
                    :options="options"
                    :interactions="interactions"
                    :focussed-question="focussedQuestion"
                    :active-section="activeSection"
                    @toggle-trigger="toggleIfTrigger($event)"
                    @focus-section="focusSection()"
                />

                <!-- Not too many options - chip selectors -->
                <FRQuestionChipSelector v-else
                    input-type="checkbox"
                    :question-id="questionId"
                    :question="question"
                    :options="options"
                    :interactions="interactions"
                    @toggle-trigger="toggleIfTrigger($event)"
                    @focus-section="focusSection()"
                />
            </template>

            <template v-else>
                <!-- Too many options - dropdown  -->
                <FRQuestionFieldsetDropdown v-if="needsDropdown"
                    input-type="checkbox"
                    :question-id="questionId"
                    :question="question"
                    :options="options"
                    :interactions="interactions"
                    :focussed-question="focussedQuestion"
                    :active-section="activeSection"
                    v-model="inputModel"
                    @toggle-trigger="toggleIfTrigger($event)"
                    @focus-section="focusSection()"
                />

                <!-- Not too many options - chip selectors -->
                <FRQuestionChipSelector v-else
                    input-type="checkbox"
                    :question-id="questionId"
                    :question="question"
                    :options="options"
                    :interactions="interactions"
                    v-model="inputModel"
                    @toggle-trigger="toggleIfTrigger($event)"
                    @focus-section="focusSection()"
                />
            </template>
        </template>
    </div>
</template>

<script>
import FRQuestionChipSelector from '../FormElements/FRQuestionChipSelector.vue';
import FRQuestionFieldsetDropdown from '../FormElements/FRQuestionFieldsetDropdown.vue';
import { mixin as inputMixin } from './formMixin'; // This is where the inputBinder comes from

export default {
    name: "FRQuestionCheckbox",
    mixins: [ inputMixin ],
    props: [
        "noTab",
        "options",
        "question",
        "questionId",
        "interactions",
        "activeSection",
        "focussedQuestion"
    ],
    emits: ["toggle-trigger", "focus-section"],
    components: {
        FRQuestionChipSelector,
        FRQuestionFieldsetDropdown,
    },
    data() {
        return {
            valid: undefined
        }
    },
    computed: {
        needsDropdown() {
            return this.question.options.length > 7;
        },
        validClass() {
            let res = false;
            if (this.valid) res = `fr-${this.valid}`;
            return res;
        },
    },
    methods: {
        toggleIfTrigger(obj) {
            const inModelValue = (option) => typeof this.modelValue === 'array' ? this.modelValue.map(ans => Number(ans)).includes(option) : false
            const hasOptions = this.options && Object.keys(this.options).length
            const interactionTriggerSelected = hasOptions ? this.interactions?.[obj.questionId]?.options?.some(inModelValue) : false
            let check = interactionTriggerSelected || obj.check

            this.$emit('toggle-trigger', {questionId: obj.questionId, check: check});
        },
        focusSection() {
            this.$emit('focus-section');
        },
        checkValue() {
            if (this.required) {
                let check = event.target.checked;
                this.valid = check ? 'valid' : 'invalid';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../scss/_mixins.scss';

    .fr-option {
        display: flex;
        align-items: center;
        padding: .25rem 0;
        cursor: pointer;

        input {
            margin-right: .5rem;

            .fr-group.fr-invalid & { box-shadow: var(--gbs-pos) 0 2px var(--red-500); }
        }
    }

    .fr-assistive {
        font-size: 12px;
        margin-top: 4px;
        line-height: 1.1;
        color: var(--red-500);
        display: none;

        .fr-group.fr-invalid & {
            display: block;
        }
    }
</style>