<template>
    <div class="wrapper">
        <div class="subnav-btns">
            <h6>Select Filter:</h6>
            <div class="filters">
                <div class="primary-filters">
                    <Link v-bind="linkBinder('time1')">Time 1</Link>
                    <Link v-bind="linkBinder('delivery')">Delivery</Link>
                    <Link v-bind="linkBinder('time2')">Time 2</Link>
                    <Link v-bind="linkBinder('time3')">Time 3</Link>
                    <Link v-bind="linkBinder('time4')">Time 4</Link>
                    <Link v-bind="linkBinder('time5')">Time 5</Link>
                    <Link v-bind="linkBinder('time6')">Time 6</Link>
                    <Link v-bind="linkBinder('time7')">Time 7</Link>
                    <Link v-bind="linkBinder('time8')">Time 8</Link>
                    <Link v-bind="linkBinder('time9')">Time 9</Link>
                    <Link v-bind="linkBinder('time10')">Time 10</Link>
                    <Link v-bind="linkBinder('time11')">Time 11</Link>
                    <Link v-bind="linkBinder('time12')">Time 12</Link>
                    <Link v-bind="linkBinder('time13')">Time 13</Link>
                    <Link v-bind="linkBinder('time14')">Time 14</Link>
                    <Link v-bind="linkBinder('time15')">Time 15</Link>
                </div>
                <div class="secondary-filters">
                    <Link v-bind="secondaryLinkBinder('inactive')"><FontAwesomeIcon class="mr-2 hidden" :icon="check"/>Inactive</Link>
                    <Link v-bind="secondaryLinkBinder('due')"><FontAwesomeIcon class="mr-2 hidden" :icon="check"/>Due</Link>
                    <Link v-bind="secondaryLinkBinder('overdue')"><FontAwesomeIcon class="mr-2 hidden" :icon="check"/>Overdue</Link>
                    <Link v-bind="secondaryLinkBinder('in_transition')"><FontAwesomeIcon class="mr-2 hidden" :icon="check"/>In Transition</Link>
                    <Link v-bind="secondaryLinkBinder('underage')"><FontAwesomeIcon class="mr-2 hidden" :icon="check"/>Underage</Link>
                    <Link v-bind="secondaryLinkBinder('admitted_to_facility')"><FontAwesomeIcon class="mr-2 hidden" :icon="check"/>Admitted to Facility</Link>
                    <Link v-bind="secondaryLinkBinder('score_drop')"><FontAwesomeIcon class="mr-2 hidden" :icon="check"/>Score Drop</Link>
                    <Link v-bind="secondaryLinkBinder('new_wound')"><FontAwesomeIcon class="mr-2 hidden" :icon="check"/>New Wound</Link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { Link } from '@inertiajs/inertia-vue3';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faCheck } from '@fortawesome/free-solid-svg-icons'

    export default {
        components: {
            Link,
            FontAwesomeIcon,
        },
        data() {
            return {
                check: faCheck,
                // Which sub filters belong under which parent filters
                secondaryFilters: {
                    "inactive":             ['time1','delivery','time2','time3','time4','time5','time6','time7','time8','time9','time10','time11','time12','time13','time14','time15'],
                    "due":                  [        'delivery','time2','time3','time4','time5','time6','time7','time8','time9','time10','time11','time12','time13','time14','time15'],
                    "overdue":              [        'delivery','time2','time3','time4','time5','time6','time7','time8','time9','time10','time11','time12','time13','time14','time15'],
                    "in_transition":        ['time1','delivery','time2','time3','time4','time5','time6','time7','time8','time9','time10','time11','time12','time13','time14','time15'],
                    "underage":             ['time1',           'time2','time3','time4','time5','time6','time7','time8','time9','time10','time11','time12','time13','time14','time15'],
                    "admitted_to_facility": ['time1',                   'time3','time4','time5','time6','time7','time8','time9','time10','time11','time12','time13','time14','time15'],
                    "score_drop":           [                   'time2','time3','time4','time5','time6','time7','time8','time9','time10','time11','time12','time13','time14','time15'],
                    "new_wound":            [                   'time2','time3','time4','time5','time6','time7','time8','time9','time10','time11','time12','time13','time14','time15'],
                }
            }
        },
        methods: {
            linkBinder(url) {
                let current = this.$page.url.split("/").slice(2)[0]?.split("?")?.[0] === url;
                return { class: `btn btn-filters${current ? ' active' : ''}`, href: `/patients${current ? '' : '/'+url}` }
            },
            secondaryLinkBinder(url) {
                let urlObj = new URL(location.href);
                let active = Array.from(urlObj.searchParams.keys()).includes(url);

                urlObj.searchParams[active ? 'delete' : 'append'](...(active ? [url] : [url,""]));

                let res = {
                    class: `btn-filterSecondary${active ? ' active' : ''}`,
                    href: urlObj.origin + urlObj.pathname + urlObj.search.replaceAll('=','')
                }

                // If this items key doesn't contain the current primary filter, hide it
                if (!this.secondaryFilters[url].includes(urlObj.pathname.split('/').at(-1))) {
                    res['style'] = { display: 'none' };
                }

                return res;
            },
        }
    }
</script>
<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';

    .subnav-btns {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        margin: 1rem -.25rem;

        > * {
            margin: .25rem;
        }
    }

    .secondary-filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @include bp-up($bp-md + 1) { justify-content: flex-start; }
    }

    .btn-filters, .btn-filterSecondary {
        line-height: 1.1;
        padding: .25rem .5rem;
        font-size: 14px;
    }

    .btn-filterSecondary {
        color: var(--gray-800);
        font-weight: 300;

        &:hover, &.active {
            color: var(--blue-600);
            font-weight: 500;
        }

        &.active {
            :deep(.fa-check) { display: inline-block; }
        }
    }

</style>
