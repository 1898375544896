<template>
    <Head title="Two-factor Confirmation" />
    <JetAuthenticationCard>
        <template #logo>
            <JetAuthenticationCardLogo />
        </template>

        <JetValidationErrors class="mb-4" />

        <div class="mb-4 text-sm text-gray-800">
            <template v-if="! recovery">
                Please confirm access to your account by entering the authentication code sent to your registered mobile.
            </template>

            <template v-else>
                Please confirm access to your account by entering one of your emergency recovery codes.
            </template>
        </div>

        <JetValidationErrors class="mb-4" />
         <div class="loader" v-if="loading"></div>
         <div v-if="validate_msg != ''">
            <p class="text-red-500 my-0 mb-4"> {{validate_msg}}</p>
        </div>
        <form ref="form" @submit.prevent="submitTwofactorChallange">
        <!--<form @submit.prevent="submit">-->
            <div>
                <JetLabel for="recovery_code" value="Authentication Code" />
                <JetInput ref="recovery_code" required maxlength="6" id="recovery_code" type="text" class="mt-1 block w-full" v-model="form.recovery_code" autocomplete="one-time-code" />
            </div>

            <div class="flex items-center justify-end mt-4">
                <button type="button" class="text-sm text-gray-800 hover:text-gray-900 underline cursor-pointer" @click="resendOTP">
                 Resend Code
                </button>

                <JetButton class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    Log in
                </JetButton>
            </div>
        </form>
    </JetAuthenticationCard>
</template>

<script>
    import { Head } from '@inertiajs/inertia-vue3';
    import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
    import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
    import SplashScreen from '@/Components/SplashScreen.vue'

    export default {
        components: {
            Head,
            JetAuthenticationCard,
            JetAuthenticationCardLogo,
            JetButton,
            JetInput,
            JetLabel,
            JetValidationErrors,
            SplashScreen,
        },

        data() {
            return {
                recovery: false,
                form: this.$inertia.form({
                    code: '',
                    recovery_code: '',
                }),
                loading: false,
                validate_msg: null,
            }
        },

        methods: {
            resendOTP(){
                this.loading = true;
                axios.get(route('resend.otp')).then(response => {
                    this.loading = false;
                    this.validate_msg = response.data.message;
                    this.$emit('confirmed');
                    setTimeout(() => this.validate_msg = false, 5000)
                })
            },
            submit() {
                this.form.post(this.route('two-factor.login'))
            },
             submitTwofactorChallange(e) {
                let vm = this;
                vm.loading = true;
                axios
                    .post(route('submitTwofactorChallange'), vm.form)
                    .then(res => {
                        vm.loading = false;
                        vm.validate_msg = false;
                        this.$inertia.visit(route('dashboard'));
                    })
                    .catch(function (error) {
                        vm.loading = false;
                        vm.validate_msg = error.response.data.message
                        if(typeof error.response.data.errors !== "undefined"){
                            vm.validate_msg = error.response.data.errors.code[0]
                        }
                    });
            }
        }
    }
</script>
