<template>
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div class="lg:col-span-2">
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div class="resource-card">
                    <div>
                        <p class="title">VGMU Training</p>
                        <p class="body">2 courses on VGMU by the University of Pittsburgh explaining the importance and use of the FMA</p>
                        <a class="btn btn-blue" href="https://www.vgmeducation.com/vgmu" title="external link in new tab to VGMU Training website" target="_blank"> View Resources</a>
                    </div>
                </div>
                <div class="resource-card">
                    <div>
                        <p class="title">FMA Data Collection Website Training</p>
                        <p class="body">Training on how to use the website and the actual process</p>
                        <a class="btn btn-blue" href="https://register.gotowebinar.com/recording/8493627653558309123" title="external link in new tab to view recording website" target="_blank"> View Resources</a>
                    </div>
                </div>
            </div>
            <div class="my-8 text-center">
                <p class="title">Introducing the Family-Centered FMA</p>
                <p class="body">View the video below to learn how to submit Family-Centered FMA forms on the data</p>
                <iframe class="m-auto video" src="https://www.youtube.com/embed/guBKn0Tz7SM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
        <div>
            <template v-for="(section, i) in downloadSections">
                <h4
                    class="mb-2"
                    :class="{'mt-6': i}"
                >{{ section.title }}</h4>
                <ul>
                    <li v-for="download in section.downloads">
                        <a
                            class="docFetch"
                            @click.prevent="fetchDocument(download.fetchPath)"
                            :title="`Downloads ${download.title}`"
                            target="_blank"
                        ><FontAwesomeIcon :icon="pdf"/>{{ download.title }}</a>
                    </li>
                </ul>
            </template>
        </div>
    </div>
</template>

<script>
    import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

    export default {
        components: {
            FontAwesomeIcon,
        },
        data() {
            return {
                pdf: faFilePdf,
                demoIntake: '',
                downloadSections: [
                    {
                        title: 'Documents',
                        downloads: [
                            {title: 'FMA Client Notification', fetchPath: 'FMA-Client-Notification-Form-2024-Fillable.pdf'},
                            {title: 'UDS: FMA Initial Demographic Intake', fetchPath: 'UDS-FMA-Initial-Demographic-Intake-FILLABLE-04-09-2024.pdf'},
                            {title: 'FMA (Survey)', fetchPath: 'FMA-Score-Sheet-And-Standard-FILLABLE.pdf'},
                            {title: 'FMA (Survey - Family Centric)', fetchPath: 'FMA-FC.pdf'},
                            {title: 'FMA Delivery Form', fetchPath: 'FMA-Delivery-Form-04-09-2024-FILLABLE.pdf'},
                            {title: 'UDS: FMA Follow-Up Form', fetchPath: 'UDS-FMA-Follow-up-Form-04-09-2024-FILLABLE.pdf'},
                            {title: 'Spanish FMA Client Notification', fetchPath: 'Spanish-FMA-Client-Notification.pdf'},
                            {title: 'Spanish UDS: Initial Demographic Intake', fetchPath: 'FMA-Initial-Demographic-Intake-Spanish-translation-3-12-24.pdf'},
                            {title: 'Spanish FMA (Survey)', fetchPath: 'FMA-Spanish-FILLABLE.pdf'},
                            {title: 'Spanish FMA (Survey - Family Centric)', fetchPath: 'Spanish-FMA-FC.pdf'},
                            {title: 'Spanish FMA Delivery Form', fetchPath: 'FMA-Delivery-Form-3-21-24-Spanish.pdf'},
                            {title: 'Spanish UDS: FMA Follow-Up Form', fetchPath: 'FMA-Follow-Up-Form-03-13-23-Spanish-translation.pdf'},
                        ]
                    },
                    {
                        title: 'Resources',
                        downloads: [
                            {title: 'FMA User Guide', fetchPath: 'FMA-User-Guide.pdf'},
                            {title: 'Data Collection User Guide', fetchPath: 'Provider-FMA-Data-Collection-User-Guide.pdf'},
                            {title: 'FMA Definitions', fetchPath: 'FMA-Definitions.pdf'},
                        ]
                    }
                ]
            };
        },
        methods: {
            fetchDocument(docName) {
                axios.post(route('resources.get-resource-document'), {
                    'doc' : docName,
                    }
                ).then(res => {
                    console.log(res.data);
                    window.open(res.data, '_blank');
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../../scss/_mixins.scss';

    .resource-card {
        @include fluid-size(300px, 200px, font-size);
        background-color: var(--gray-300);
        padding: 1rem;
        margin: .5rem .25rem;
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .title {
        @include fluid-size(16px, 20px, font-size);
        font-weight: 700;
        padding-bottom: .5rem;
    }

    .body {
        @include fluid-size(12px, 16px, font-size);
        font-weight: 300;
        padding-bottom: .5rem;
    }

    .video {
        @include fluid-size(300px, 600px, width);
        @include fluid-size(300px, 415px, height);
    }

    li {
        margin: 10px 0;

        &:hover {
            text-decoration: underline;
            font-weight: 600;
        }
    }
    .docFetch {
        cursor: pointer;
        .fa-file-pdf {
            margin-right: .5em;
        }
    }
</style>
