<template>
    <AppLayout title="Create Company User">
        <div class="loader" v-if="loading"></div>
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},'Create Company']"/>
        </template>

        <Card title="Create Company User">
            <div class="buttons">
                <div>
                    <button class="btn-gray" @click.prevent="goBack()">
                        <FontAwesomeIcon class="mr-1" fixed-width :icon="arrowLeft"/> Go Back
                    </button>
                </div>
            </div>

            <div v-if="validate_msg != ''">
                <p class="text-green-500 my-0"><strong> {{validate_msg}}</strong></p>
            </div>
            <div v-if="error_msg != ''">
                <p class="text-red-500 my-0"><strong>  {{error_msg}} </strong></p>
            </div>
            <form ref="form" @submit.prevent="submitForm">
                 <div class="md:flex -mb-2 -mx-2">
                    <div class="m-2 flex-grow">
                        <SimpleSelect @change-role="checkRole($event)"
                            name="Role"
                            label="Select Role"
                            v-model="form.role_type"
                            :options="roleOptions"
                            :required="true"
                        />
                    </div>
                    <div class="m-2 flex-grow" v-if="showCompanyTag != 'AD' && showCompanyTag != null && !this.$page.props.session_company_id">
                        <SimpleSelect
                            name="Company"
                            :label="SelectCompany"
                            v-model="company_id"
                            :options="companyOptions"
                        />
                    </div>
                </div>

                <div class="md:flex -mb-2 -mx-2">
                    <div class="m-2 flex-grow">
                        <JetLabel for="first_name" value="First Name" />
                        <JetInput name="first_name" type="text" class="mt-1 block w-full" v-model="form.first_name" placeholder="First Name" required />
                    </div>

                    <div class="m-2 flex-grow">
                        <JetLabel for="last_name" value="Last Name" />
                        <JetInput name="last_name" type="text" class="mt-1 block w-full" v-model="form.last_name" placeholder="Last Name" required />
                    </div>
                </div>

                <div class="md:flex -mb-2 -mx-2">
                    <div class="m-2 flex-grow">
                        <JetLabel for="email" value="Email" />
                        <JetInput name="email" type="email" placeholder="Email Address" class="mt-1 block w-full" v-model="form.email" required />
                    </div>

                    <div class="m-2 flex-grow">
                        <JetLabel for="mobile" value="Mobile" />
                        <JetInput
                            name="mobile"
                            type="tel"
                            placeholder="Mobile Number"
                            class="mt-1 block w-full"
                            v-mask="'(###) ###-####'"
                            pattern="(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}"
                            v-model="form.mobile"
                            required
                        />
                    </div>
                </div>

                <div class="md:flex -mb-2 -mx-2">
                    <div class="m-2 flex-grow">
                        <JetLabel for="atp_number" value="ATP Number" />
                        <JetInput name="atp_number" type="text" placeholder="ATP Number" class="mt-1 block w-full" v-model="form.atp_number" />
                    </div>
                </div>

                <div class="flex mt-3 -mx-1 -mb-1">
                    <button
                        type="submit"
                        class="btn-green m-1"
                    >Submit</button>

                    <button @click="cancel"
                        type="button"
                        class="btn-outline-gray m-1"
                    >Cancel</button>

                    <button
                        type="button"
                        class="btn-outline-gray m-1"
                        @click.prevent="resetForm()"
                    >Reset</button>
                </div>
            </form>
        </Card>
    </AppLayout>
</template>

<script>
    import SimpleSelect from '@/Components/FormElements/SimpleSelect.vue'
    import AddressInputGroup from '@/Components/AddressInputGroup.vue'
    import formProcessingMixin from '@/Mixins/formProcessingMixin.js'
    import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import Card from '@/Components/Card.vue'
    import { mask } from 'vue-the-mask';

    export default {
        mixins: [formattingMixin,formProcessingMixin],
        directives: { mask },
        components: {
            AddressInputGroup,
            FontAwesomeIcon,
            SimpleSelect,
            Breadcrumbs,
            JetCheckbox,
            faArrowLeft,
            AppLayout,
            JetLabel,
            JetInput,
            Card,
            Link,
        },
        props: ['data', 'errors'],
        data() {
            return {
                form: {
                    role_type: null,
                    first_name: null,
                    last_name: null,
                    email: null,
                    mobile: null,
                    password:null,
                    atp_number:null,
                },
                arrowLeft: faArrowLeft,
                durationOptions: [
                    {name: '', value: '', selected: true, hidden: 'true'},
                    {name: '30 days', value: 30},
                    {name: '60 days', value: 60},
                    {name: '90 days', value: 90},
                ],
                validate_msg: null,
                showCompanyTag: null,
                error_msg: null,
                loading:false,
                user_status_types: [
                    { name: "", value: "", hidden: true },
                    { name: "Active", value: "1" },
                    { name: "Inactive", value: "0" }
                ],
                companyOptions: JSON.parse(this.$page.props.companiesList),
                roleOptions: JSON.parse(this.$page.props.roleList),
            }
        },
        methods: {
            goBack() {
                window.history.back();
            },
            buttonClasses(view) {
                return `btn-gray${view && this.activeView == view ? ' active' : ''}`;
            },
            cancel: function (e) {
                this.$inertia.visit(route('manage.users'))
            },
            resetForm() {
                this.form.first_name = null;
                this.form.email = null;
                this.form.mobile = null;
                this.form.password = null;
                this.form.last_name = null;
                this.form.atp_number = null;
            },
            submitForm(event) {
                this.submit(event, 'saveUser', null, null);
            },
            saveUser(e) {
                let vm = this;
                vm.loading = true;
                axios
                    .post(route('saveUser'), vm.form)
                    .then(res => {
                        vm.loading = false;
                        vm.validate_msg = false;
                        vm.resetForm();
                    })
                    .catch(function (error) {
                        vm.loading = false;
                        vm.validate_msg = error.response.data.errors.email[0]
                        //alert(error.response.data.errors.email);
                    });
            },
            checkRole(event) {
                this.showCompanyTag = event.target.value
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../scss/_mixins.scss';

    .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include bp-up($bp-lg + 1) { margin: 0 -.5rem 1rem; }

        @include bp-down($bp-lg) {
            margin: 0 -.25rem 1rem;
            flex-wrap: wrap;
        }

        > div {
            display: flex;

            @include bp-down($bp-lg) {
                flex-wrap: wrap;
                flex-grow: 1;
            }

            button, a {
                text-align: center;
                @include bp-up($bp-lg + 1) { margin: .5rem; }
                @include bp-down($bp-lg) { margin: .25rem; }
                @include bp-between(451px, $bp-lg) { flex: 1 0 calc(50% - .5rem) }
                @include bp-down(450px) { flex: 1 0 calc(100% - .5rem) }
            }
        }
    }

    .btn-checkboxes {
        display: flex;
        flex-wrap: wrap;
        margin: -.5rem -.5rem 0;

        input {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        label {
            margin: .5rem;
            text-align: center;
        }
    }
</style>
