import { sortBy } from "lodash";
import { orderBy } from "lodash";

export default {
    computed: {
        usStatesObject() {
            return {
                1: { value: "AL" },
                2: { value: "AK" },
                3: { value: "AZ" },
                4: { value: "AR" },
                5: { value: "CA" },
                6: { value: "CO" },
                7: { value: "CT" },
                8: { value: "DE" },
                9: { value: "FL" },
                10: { value: "GA" },
                11: { value: "HI" },
                12: { value: "ID" },
                13: { value: "IL" },
                14: { value: "IN" },
                15: { value: "IA" },
                16: { value: "KS" },
                17: { value: "KY" },
                18: { value: "LA" },
                19: { value: "ME" },
                20: { value: "MD" },
                21: { value: "MA" },
                22: { value: "MI" },
                23: { value: "MN" },
                24: { value: "MS" },
                25: { value: "MO" },
                26: { value: "MT" },
                27: { value: "NE" },
                28: { value: "NV" },
                29: { value: "NH" },
                30: { value: "NJ" },
                31: { value: "NM" },
                32: { value: "NY" },
                33: { value: "NC" },
                34: { value: "ND" },
                35: { value: "OH" },
                36: { value: "OK" },
                37: { value: "OR" },
                38: { value: "PA" },
                39: { value: "RI" },
                40: { value: "SC" },
                41: { value: "SD" },
                42: { value: "TN" },
                43: { value: "TX" },
                44: { value: "UT" },
                45: { value: "VT" },
                46: { value: "VA" },
                47: { value: "WA" },
                48: { value: "WV" },
                49: { value: "WI" },
                50: { value: "WY" },
                51: { value: "DC" },
            };
        },
        usStatesGlossary() {
            let result = [];

            Object.entries(this.usStatesObject()).forEach(([key, {value}]) => {
                result.push([key, value])
            })

            let sort =  result.sort((a,b) => {
                if (a[1] > b[1]) return 1
                if (a[1] < b[1]) return -1
                return 0
            })

            let output = [];
            Object.entries(sort).forEach(([key, value]) => {
                output.push(value[0]);
            })

            return output;
        }
    }
}