<template>
    <AppLayout title="Team Settings">
        <FormEditRenderer :data="data" />
    </AppLayout>
</template>

<script>
    import FormEditRenderer from '../../Components/FormEditRenderer.vue';
    import AppLayout from '@/Layouts/AppLayout.vue'

    export default {
        props: ['data'],
        components: {
            FormEditRenderer,
            AppLayout,
        },
    }
</script>
