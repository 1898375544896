<template>
    <AppLayout title="Team Settings">
        <h1>We’re sorry, the link to this follow up has expired. Please call us at 1-800-987-7342 for additional assistance.</h1>
    </AppLayout>
</template>

<script>
    import AppLayout from '@/Layouts/PatientFormLayout.vue'

    export default {
        components: {
            AppLayout,
        },
    }
</script>
