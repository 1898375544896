<template>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div class="content-group">
            <p class="title">Data Reports</p>
            <p>As you accumulate data, we will be able to provide reports of your FMA data as compared to U.S. Rehab members and that national baseline. Any of the FMA questions can be analyzed. Here are just two examples.</p>
            <p>Primary diagnosis is collected at the baseline/time 1 submission. Here is an example of the data that can be collected.</p>
        </div>
        <img src="/images/piechart.jpg" alt="pie chart with primary diagnosis at baseline"/>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <img src="/images/barchart.jpg" alt="pie chart with primary diagnosis at baseline"/>
        <div class="content-group row-start-1 col-start-1 md:col-start-2">
            <p class="title">Average FMA Scores</p>
            <p>FMA scores are calculated on a scale of 1 to 6, with 1 being "completely disagree" and 6 being "completely agree." This chart provides average FMA scores at baseline/time 1 as compared to follow-up/time 2.</p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../../../scss/_mixins.scss';

    .content-group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 2rem;

        .title {
            @include fluid-size(16px, 20px, font-size);
            font-weight: 700;
            padding-bottom: .5rem;
        }
    }
</style>
