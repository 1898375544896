<template>
    <div class="fr-group" :class="validClass">
        <label class="fr-label" :for="`question_${questionId}`">{{ question.question }}<span class="fr-required" v-if="required">*</span></label>
        <select
            v-if="modelValue === undefined || modelValue === ''"
            class="fr-control"
            :id="`question_${questionId}`"
            :name="questionId"
            v-bind="inputBinder"
            @focus="focusSection()"
            @change="checkValue()"
            @blur="checkValue()"
        >
            <option value="" selected>Please Select...</option>
            <option
                v-for="optionId in question.options"
                :key="`option_${optionId}`"
                :value="optionId"
            >{{ options[optionId].value }}</option>
        </select>
        <select
            v-else
            class="fr-control"
            :id="`question_${questionId}`"
            :name="questionId"
            v-bind="inputBinder"
            v-model="inputModel"
            @focus="focusSection()"
            @change="checkValue()"
            @blur="checkValue()"
        >
            <option value="">Please Select...</option>
            <option
                v-for="optionId in question.options"
                :key="`option_${optionId}`"
                :value="optionId"
            >{{ options[optionId].value }}</option>
        </select>
        <p class="fr-assistive">Please select an option</p>
    </div>
</template>

<script>
import { mixin as inputMixin } from './formMixin'; // This is where the inputBinder comes from

export default {
    name: "FRQuestionSelect",
    mixins: [ inputMixin ],
    props: [
        "noTab",
        "question",
        "questionId",
        "options"
    ],
    emits: ["focus-section"],
    data() {
        return {
            valid: undefined
        }
    },
    computed: {
        validClass() {
            let res = false;
            if (this.valid) res = `fr-${this.valid}`;
            return res;
        }
    },
    methods: {
        focusSection() {
            this.$emit('focus-section');
        },
        checkValue() {
            if (this.required) {
                let check = Array.from(event.target.options).some(o => o.selected && !o.disabled && o.value);
                this.valid = check ? 'valid' : 'invalid';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .fr-label {
        font-size: 14px;
        line-height: 1.1;
        display: block;
        margin-bottom: .25rem;
    }

    .fr-control {
        width: 100%;
        padding-top: .25rem;
        padding-bottom: .25rem;
        font-size: 14px;
        border-radius: 3px;
        padding-right: 1rem;
        border: 0;
        outline: 0;
        background-color: white;
        box-shadow: var(--global-box-shadow);
        transition: 200ms ease-out;

        &:focus {
            box-shadow: var(--gbs-pos) 0 2px var(--blue-500);
        }

        .fr-group.fr-valid & {
            box-shadow: var(--gbs-pos) 0 1px var(--green-500);
            &:focus { box-shadow: var(--gbs-pos) 0 2px var(--green-500); }
        }

        .fr-group.fr-invalid & {
            box-shadow: var(--gbs-pos) 0 1px var(--red-500);
            &:focus { box-shadow: var(--gbs-pos) 0 2px var(--red-500); }
        }
    }

    .fr-assistive {
        font-size: 12px;
        margin-top: 4px;
        line-height: 1.1;
        color: hsl(0deg,0%,40%);
        display: none;

        .fr-group.fr-invalid & {
            color: var(--red-500);
            display: block;
        }

        :deep(span) { font-weight: bold; }
    }
</style>
