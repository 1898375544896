<template>
  <AppLayout title="Resources">
    <Card title="Analytics">
      <div class="subnav-btns">
        <button
          @click="setActiveView('BasicData')"
          :class="buttonFilter('BasicData')"
          class="btn-filters"
        >Basic Data</button>

        <button
          @click="setActiveView('ExportData')"
          :class="buttonFilter('ExportData')"
          class="btn-filters"
          v-if="userType == 'AD'"
        >Export Data</button>

        <button
          @click="setActiveView('CommData')"
          :class="buttonFilter('CommData')"
          class="btn-filters"
          v-if="userType == 'AD'"
        >Comm Data</button>
      </div>

      <BasicData v-if="activeView == 'BasicData'" />
      <ExportData v-if="activeView == 'ExportData'" />
      <CommData v-if="activeView == 'CommData'" />
    </Card>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";
import Card from "@/Components/Card.vue";
import BasicData from "@/Pages/Analytics/BasicData.vue";
import ExportData from "@/Pages/Analytics/ExportData.vue";
import CommData from "@/Pages/Analytics/CommData.vue";

export default {
  components: {
    AppLayout,
    Card,
    BasicData,
    ExportData,
    CommData
  },
  data() {
    return {
      activeView: "BasicData",
    };
  },
  computed: {
    userType() {
        return this.$page.props.user.role_type;
    }
  },
  methods: {
    setActiveView(view) {
      this.activeView = view;
    },
    buttonFilter(view) {
      return `${view && this.activeView == view ? " active" : ""}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/_mixins.scss";

.subnav-btns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -0.25rem 1.75rem -0.25rem;

  > * {
    margin: 0.25rem;
    flex-grow: 0;
  }
}
</style>
