<template>
  <div class="card">
    <h6 class="title">{{ cardData.title }}</h6>
    <p class="totals">{{ data.total }} / {{ total }}</p>

    <div class="loader" v-if="isLoading"></div>

    <div class="card-body">
      <div class="grid grid-flow-col grid-cols-auto gap-2">
        <CardDataPoint
          v-for="(point, pIndex) in cardData.dataPoints"
          :key="`point_${pIndex}`"
          :name="point.name"
          :color="point.color"
          :cta-route="`/patients/${cardData.prefix}?${point.ctaRoute}`"
          :number="data[point.data] || 0"
          :icon="icons[point.icon]"
        />
      </div>
    </div>

    <component
      :is="isRoute ? 'Link' : 'a'"
      class="cta-box"
      v-if="!$slots.cta && ctaRoute"
      :href="ctaHref"
    >
      <p class="btn-txt">View All {{ cardData.title }}</p>
      <FontAwesomeIcon :icon="arrowRight" />
    </component>
  </div>
</template>

<script>
import SimpleLoader from '@/Components/SimpleLoader.vue';
import CardDataPoint from '@/Components/CardElements/CardDataPoint.vue'
import { Link } from "@inertiajs/inertia-vue3";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle, faSpinner, faTimesCircle, faExchangeAlt, faHourglassHalf } from '@fortawesome/free-solid-svg-icons'

export default {
  props: {
    cardData: {
      type: Object,
      required: true,
    },
    total: {
      type: Number,
      required: false,
    },
  },
  components: {
    FontAwesomeIcon,
    Link,
    CardDataPoint,
    SimpleLoader
  },
  data() {
    return {
      isLoading: true,
      data: [],
      arrowRight: faArrowRight,
      isRoute: false,
      icons: {
        checkCircle: faCheckCircle,
        spinner: faSpinner,
        timesCircle: faTimesCircle,
        exchangeAlt: faExchangeAlt,
        hourglassHalf: faHourglassHalf
      },
    };
  },
  computed: {
    ctaRoute() {
      return `/patients/${this.cardData.prefix}`;
    },
    ctaHref() {
      let href;
      try {
        href = this.route(this.ctaRoute);
        this.isRoute = true;
      } catch (err) {
        href = this.ctaRoute;
      }
      return href;
    },
  },
  methods: {
    fetchData() {
      let vm = this;
      axios.get(vm.cardData.endpoint)
      .then(res => {
        vm.data = res.data;
        vm.$emit('on-fetch', res.data.total);
        vm.isLoading = false;
      })
      .catch(err => console.error(err));
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/_mixins.scss";

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-shadow: 3px 3px 6px #9dacd266;
  font-weight: 100;
  position: relative;
  background-color: white;
  border-radius: 4px;

  .title {
    color: var(--font-color);
    font-size: 14px;
    font-weight: 600;
    padding-right: 1rem;
  }

  .totals {
    font-size: 12px;
    font-weight: 400;
    padding-top: 0;
  }

  .card-body {
    margin: 1rem 0;
    flex-grow: 1;

    .due {
      color: var(--green-600);
    }
    .overdue {
      color: var(--yellow-600);
    }
    .incomplete {
      color: var(--red-600);
    }
    .inactive {
      color: var(--gray-800);
    }
    .inTransition {
      color: var(--purple);
    }

    .card-num {
      color: var(--font-color);
    }

    p {
      font-size: 12px;
      font-weight: 400;
    }
  }

  .btn-txt {
    font-size: 12px;
    font-weight: 700;
    color: var(--font-color);
    padding-right: 5px;
  }

  :deep(.cta-box) {
    @include fluid-size(16px, 18px, font-size);
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--font-color);
    font-weight: 700;

    .svg-inline--fa {
      width: 10px;
    }

    &:hover svg {
      animation: bounce 2s infinite both;
    }
  }

  &.cta-cover {
    position: relative;
    cursor: pointer;
    transition: 200ms ease-out;
    top: 0;

    &:hover {
      top: -5px;

      &:before {
        height: 5px;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 0;
      transition: 200ms ease-out;
    }

    a.cta-box {
      background-color: transparent;
      height: 100%;
      width: 100%;
      position: absolute;
      top: -4px;
      left: -1rem;
      text-indent: -9999px;
    }
  }
}
@keyframes bounce {
  0%,
  20%,
  60%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-2px);
  }
  50% {
    transform: translateX(4px);
  }
  70% {
    transform: translateX(2px);
  }
}
</style>
