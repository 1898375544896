<template>
    <AppLayout title="ATPs">
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},'ATPs']"/>
        </template>

        <Card title="ATPs">
            <AtpsTable />
        </Card>
    </AppLayout>
</template>

<script>
    import { faUsers } from '@fortawesome/free-solid-svg-icons';
    import AtpsTable from './Tables/AtpsTable.vue'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import Card from '@/Components/Card.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

    export default {
        components: {
            AtpsTable,
            Breadcrumbs,
            AppLayout,
            Card,
            FontAwesomeIcon,
        },
        props: ['data', 'errors'],
        data() {
            return {
                users: faUsers,
            }
        }
    }
</script>