// Todo
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes
// Particularly value, placeholder, autocomplete, disabled, & pattern
// https://laravel.com/docs/7.x/validation#available-validation-rules
// Steal any potential client side validation rules that can be passed into validate="" as a string/array
// parse the string into an array and loop through each rule and return data 'valid': true if all pass

/**
 * This mixin contains the logic that is shared across all input types among the Vue form components
 */

 export const mixin = {
    inheritAttrs: false,
    props: {
        name: {
            type: String,
            required: true
        },
        width: {
            type: [Number, String],
            validator: (value) => {
                if (parseInt(value)) {
                    return parseInt(value) > 0 && parseInt(value) <= 12;
                }
                console.error(`Property 'width': '${value}' is invalid. Please enter an integer between 1 and 12`);
            }
        },
        required: {
            type: Boolean,
            default: false
        },
        label: {
            type: String
        },
        requiredMessage: {
            type: String
        },
        id: {
            type: String
        },
        groupClass: {
            type: String
        },
        inputClass: {
            type: String
        },
        title: {
            type: String
        },
        modelValue: {
            type: [Object, String, Array, Boolean, Number]
        },
        validate: {
            type: [String, Array]
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            labelFloating: false,
            inputHiddenClass: "",
            notAllowedValues: ['symbol', 'bigint', 'function', 'undefined']
        }
    },
    computed: {
        groupBinder() {
            return {
                class: [this.widthClass, this.groupClass, this.inputHiddenClass]
            }
        },
        inputBinder() {
            let vm = this;

            return {
                ...this.$attrs,
                class: this.inputClass,
                name: this.inputName,
                id: this.inputId,
                title: this.inputTitle,
                required: this.required,
                readonly: this.readonly,
                'data-msg': this.inputRequiredMessage,
                onInput: (event) => {
                    switch ( event.target.type ) {
                        case 'checkbox':
                            if ( typeof vm.modelValue !== 'object' ) {
                                vm.$emit('update:modelValue', event.target.checked);
                            }
                            break;
                        case 'color':
                            break;
                        default:
                            vm.$emit('update:modelValue', event.target.value);
                            break;
                    }
                },
                onChange: (event) => {
                    switch ( event.target.type ) {
                        case 'date':
                        case 'time':
                        case 'color':
                            vm.$emit('update:modelValue', event.target.value);
                            break;
                    }
                }
            }
        },
        widthClass() {
            return this.width ? `viw-${this.width}` : false;
        },
        inputRequiredMessage() {
            let res = false;
            if (this.required) res = this.requiredMessage || `Please enter a ${this.inputLabel.toLowerCase()}`;
            return res;
        },
        inputTitle() {
            if (this.title) {
                return this.title;
            } else {
                let fallbackTitle = this.inputLabel.toLowerCase();
                let aAn = fallbackTitle.match(/^[aeiou].*/gi) ? 'an' : 'a';
                return `Enter ${aAn} ${fallbackTitle}`;
            }
        },
        inputLabel() {
            return this.label || this.name;
        },
        inputId() {
            return this.id || `${this.inputLabel.toLowerCase().replace(/[\s+\W]+/g, '_').replace(/_+$/g, '')}_${this.uid}`;
        },
        inputName() {
            return this.name || `${this.inputLabel.toLowerCase().replace(/[\s+\W]+/g, '_').replace(/_+$/g, '')}`;
        }
    },
    mounted() {
        // Check if input is hidden
        this.inputHiddenClass = document.querySelector(`#${this.inputId}`).getAttribute("hidden") || document.querySelector(`#${this.inputId}`).type == "hidden" ? "hidden" : "";
    }
}