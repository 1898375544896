// Todo
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes
// Particularly value, placeholder, autocomplete, disabled, & pattern
// https://laravel.com/docs/7.x/validation#available-validation-rules
// Steal any potential client side validation rules that can be passed into validate="" as a string/array
// parse the string into an array and loop through each rule and return data 'valid': true if all pass

/**
 * This mixin contains the logic that is shared across all input types among the Vue form components
 */

 export const mixin = {
    emits: ["update:modelValue"],
    props: {
        autocomplete: {
            type: [String, Boolean],
            default: "off"
        },
        modelValue: {
            type: [String, Number, Array],
        }
    },
    computed: {
        inputBinder() {
            return {
                autocomplete: this.autocomplete,
                required: this.required,
                ariaRequired: this.required
            }
        },
        required() {
            return this.question.required;
        },
        inputModel: {
            get() {
                return this.modelValue;
            },
            set(val) {
                // This is firing twice and I'm not sure why
                // It doesn't cause issues but is still annoying
                let res = val;
                let question = event.target;

                switch (question.tagName) {
                    case "INPUT":
                        switch (question.type) {
                            case "checkbox":
                                // Get selected values
                                let wrapper = (q) => { return question.closest(document.querySelectorAll(`input[name="${q.name}"]`).length > 1 ? '.fr-fieldset' : '.fr-group') };
                                let checked = Array.from(wrapper(question).querySelectorAll('input:checked'));
                                if (!checked.length) { res = []; break; }
                                if (checked.length > 1) { res = checked.map(i => i.value); break; }
                                res = [checked[0].value]; break;
                        }
                        break;

                    case "SELECT":
                        let checked = Array.from(question.selectedOptions);
                        if (!checked.length) { res = ""; break; }
                        if (checked.length > 1) { res = checked.map(i => i.value); break; }
                        res = checked[0].value; break;
                }

                this.$emit('update:modelValue', res);
            }
        }
    }
}
