<template>
    <AppLayout title="Team Settings">
        <FormRenderer v-if="data" :data="data" :vgm-number="vgmNumber" />
    </AppLayout>
</template>

<script>
    import AppLayout from '@/Layouts/AppLayout.vue'
    import FormRenderer from '@/Components/FormRenderer.vue'

    export default {
        props: ['data', 'vgmNumber'],
        components: {
            AppLayout,
            FormRenderer
        },
    }
</script>
