<template>
    <form class="fr-form" :class="{'submitted': submitted, 'success': submittedSuccessfully, 'error': submittedWithErrors}" @submit.prevent="submitForm()" ref="form">
        <div class="fr-body" :class="{'fr-hidden':submitted}">
            <h4 class="fr-title">{{ data.form.name }}</h4>

            <div class="fr-progress" :data-progress="currentSectionIndex + 1" :style="`--stop-count:${data.form.sections.length}`" v-if="data.form.sections.length > 1">
                <div class="fr-bar"></div>
                <div
                    class="fr-stop"
                    v-for="(section_id, index) in data.form.sections"
                    :key="`stop_${section_id}`"
                    :class="sectionPositionClass(index)"
                    @click="if (developerMode) { incrementSection(index); }"
                ></div>
            </div>

            <button type="button" class="btn btn-blue my-4" v-if="developerMode" @click.prevent="autofillFields()">God Mode</button>

            <FRSection
                v-for="(section_id, index) in data.form.sections"
                :class="sectionPositionClass(index)"
                :key="`section_${section_id}`"
                :form-reset-inc="formResetInc"
                :section-active="isActiveSection(index)"
                :section="data.sections[section_id]"
                :questions="questionsInSection(section_id)"
                :options="optionsInSection(section_id)"
                :interactions="interactionsInSection(section_id)"
                @focus-section="incrementSection(index)"
            />

            <div class="fr-navigation">
                <button class="fr-btn fr-btn-prev" tabindex="-1" v-if="!inFirstSection" title="Go to the previous section" @click.prevent="incrementSection('prev')">« Prev</button>
                <button class="fr-btn fr-btn-next" tabindex="-1" v-if="!inLastSection" title="Go to the next section" @click.prevent="nextSection()">Next »</button>
                <button class="fr-btn fr-btn-submit" v-if="inLastSection" title="Submit the form">Submit</button>
            </div>
        </div>

        <FRLoader v-show="submitted" :success="submittedSuccessfully" :error="submittedWithErrors" :message="responseMessage" />

    </form>
</template>

<script>
import formattingMixin from '@/Mixins/formattingMixin';
import FRSection from '@/Components/FormElements/FRSection';
import FRLoader from '@/Components/FormElements/FRLoader';

export default {
    name: "FormRenderer",
    props: ["data", "vgmNumber"],
    mixins: [formattingMixin],
    components: {
        FRSection,
        FRLoader,
    },
    data() {
        return {
            activeSection: 0,
            submitted: false,
            submittedSuccessfully: false,
            responseMessage: null,
            submittedWithErrors: false,
            formResetInc: 0,
            developerModeActive: 0 // Shut this off before pushing up, used to toggle god mode
        }
    },
    methods: {
        autoFillVgmNumber() {
            if(this.vgmNumber){
                document.querySelector('.fr-form input').value = this.vgmNumber;
                document.querySelector('.fr-form input').readOnly = true;
            }
        },
        // For development purposes only
        autofillFields() {
            if (this.developerMode) {
                let vm = this;
                let toggles = (id) => {
                    if (vm.data.interactions && vm.data.interactions[id]) return vm.data.interactions[id].toggles;
                }
                let optionsInclude = (id, val) => {
                    if (vm.data.interactions && vm.data.interactions[id] && vm.data.interactions[id].options) return vm.data.interactions[id].options.includes(Number(val));
                }
                let getRandomIntBetween = (min,max) => Math.floor(Math.random() * (max - min + 1) + min);
                vm.data.form.sections.forEach((sectionId, sectionIndex) => {
                    setTimeout(function() {
                        vm.data.sections[sectionId].questions.forEach(questionId => {
                            let question = vm.data.questions[questionId];
                            let setFunc = (i) => i.value = `Value for input ${questionId}`;
                            let els     = Array.from(document.querySelectorAll(`[name="${questionId}"]`));
                            let el      = els[0];
                            let evt     = document.createEvent("HTMLEvents");
                            let evtType = 'input';
                            switch (question.type) {
                                case "text":
                                case "textarea": break; // Use default
                                case "year":            setFunc = (i) => i.value = getRandomIntBetween(1900, 2099); break;
                                case "three-digit-zip": setFunc = (i) => i.value = getRandomIntBetween(100, 999); break;
                                case "tel":             setFunc = (i) => i.value = getRandomIntBetween(1000000000, 9999999999); break;
                                case "numeric":         setFunc = (i) => i.value = getRandomIntBetween(0, 999); break;
                                case "date":            setFunc = (i) => i.value = `${getRandomIntBetween(1901, 2099)}-${String(getRandomIntBetween(1, 12)).padStart(2, '0')}-${String(getRandomIntBetween(1, 28)).padStart(2, '0')}`; break;
                                case "time":            setFunc = (i) => i.value = `${String(getRandomIntBetween(0, 23)).padStart(2, '0')}:${String(getRandomIntBetween(0, 59)).padStart(2, '0')}`; break;
                                case "email":           setFunc = (i) => i.value = `testemail_${questionId}@email.com`; break;
                                case "checkbox":
                                case "radio":
                                    el = els.find((option) => toggles(questionId) && optionsInclude(questionId, option.value)) || els[0];
                                    setFunc = (i) => i.checked = true;
                                    evtType = "change";
                                    break;
                                case "select":
                                    el = Array.from(els[0].options).find((option) => toggles(questionId) && optionsInclude(questionId, option.value)) || el.options[1];
                                    setFunc = (o) => o.selected = true;
                                    evtType = "change";
                                    break;
                            }
                            evt.initEvent(evtType, false, true);
                            setFunc(el);
                            el.dispatchEvent(evt);
                        });
                        if (sectionIndex) vm.incrementSection('next');
                    }, 500 * sectionIndex)
                })
            }
        },
        submitForm() {
            let form = event.target,
                vm   = this;

            this.submitted = true;

            this.$refs.form.scrollIntoView();

            let formData = new FormData(form);
            let formRoute = (this.data.patient_survey == 1) ? 'insertFormAnswersByGuidUnauth' : 'insertFormAnswersByGuid';
            setTimeout(function() {
                axios
                    .post(
                        route(formRoute),
                        vm.formatFormData(vm.data.questions, formData, {
                            forms_ctrl_guid: vm.data.form.guid,
                            patients_guid: vm.data.patients_guid,
                            form_version_id: vm.data.form.version,
                            previous_form_guid: vm.data.previous_form_guid
                        })
                    )
                    .then(res => {
                        vm.submittedSuccessfully = true;
                        vm.responseMessage = res.data.message || "Form submitted successfully!"

                        if (res.data.redirect) {
                            setTimeout(function() {
                                vm.responseMessage = res.data.redirect.message;

                                setTimeout(function() {
                                    vm.$inertia.visit(route(res.data.redirect.to, res.data.redirect.with))
                                }, 2000)
                            }, 2000)
                        } else {
                            vm.resetForm(form);
                        }
                    })
                    .catch((err) => {
                        vm.submittedWithErrors = true;
                        vm.responseMessage = err?.response?.data?.message || "Something went wrong during submission";
                        console.error(err)
                    })
                    .finally(() => {
                        setTimeout(function() {
                            vm.submittedSuccessfully = false;
                            vm.submittedWithErrors   = false;
                            vm.responseMessage       = null;
                            vm.activeSection         = vm.data.form.sections[0];

                            setTimeout(function() {
                                vm.submitted = false;
                            }, 400)
                        }, 10000)
                    });
            }, 400);
        },
        resetForm(form) {
            form.reset();
            this.formResetInc++;
        },
        checkSection() {
            // Located in the formatting mixin
            return this.validateFormatting();
        },
        nextSection() {
            if (this.checkSection()) {
                this.incrementSection('next');
            }
        },
        incrementSection(dir) {
            let index = dir;
            switch (dir) {
                case 'next': index = this.currentSectionIndex + 1; break;
                case 'prev': index = this.currentSectionIndex - 1; break;
            }

            if (dir !== this.activeSection - 1) {
                if (dir.length || dir > (this.activeSection - 1)) this.$refs.form?.scrollIntoView();
                this.activeSection = this.data.form.sections[index];
            }
        },
        sectionPositionClass(index) {
            let res = 'fr-active';
            if (index > this.currentSectionIndex) res = 'fr-after';
            if (index < this.currentSectionIndex) res = 'fr-before';
            return res;
        },
        isActiveSection(index) {
            return index == this.currentSectionIndex && !this.submitted;
        },
        questionsInSection(id) {
            let questionIds = this.data.sections[id].questions;
            let res = Object.entries(this.data.questions).reduce((a, [k, v]) => {
                if (questionIds.includes(parseInt(k))) a[k] = v;
                return a;
            }, {});
            if (!Object.keys(res).length) res = undefined;
            return res;
        },
        optionsInSection(id) {
            let optionIds = [...new Set(Object.values(this.questionsInSection(id)).map(question => question.options).filter(options => options).flat())],
                res = undefined;

            if (optionIds.length) {
                res = Object.entries(this.data.options).reduce((a, [k, v]) => {
                    if (optionIds.includes(parseInt(k))) a[k] = v;
                    return a;
                }, {})
            }

            return res;
        },
        interactionsInSection(id) {
            let questionIds              = this.data.sections[id].questions,
                res                      = undefined,
                inter                    = this.data.interactions,
                existsAndIsObjWithLength = typeof inter === 'object' && !Array.isArray(inter) && inter !== null && Object.keys(inter).length;

            if (existsAndIsObjWithLength) {
                res = Object.entries(this.data.interactions).reduce((a, [k, v]) => {
                    if (questionIds.includes(parseInt(k))) a[k] = v;
                    return a;
                }, {});
            }
            if (res && !Object.keys(res).length) res = undefined;
            return res;
        }
    },
    computed: {
        developerMode() {
            return this.developerModeActive && this.$page.props.user.role_type === "AD";
        },
        currentSectionIndex() {
            let index = this.data.form.sections.indexOf(this.activeSection);
            return index !== -1 ? index : 0;
        },
        inFirstSection() {
            return this.activeSection == this.data.form.sections[0];
        },
        inLastSection() {
            return this.activeSection == this.data.form.sections[this.data.form.sections.length - 1];
        },
    },
    mounted() {
        if(this.data.patient_survey && this.data.form.name === 'Follow up form') {
            this.data.form.sections.shift();
        }
        this.activeSection = this.data.form.sections[0];
        this.autoFillVgmNumber();
    }
}
</script>

<style lang="scss" scoped>
    @keyframes tenSecondLoad {
        0% { width: 100%; }
        5% { opacity: 1; }
        100% { opacity: 1; width: 0%; }
    }

    .fr-form.submitted {
        position: relative;

        &.success, &.error {
            &:before {
                content: "";
                position: absolute;
                opacity: 0;
                width: 0%;
                height: .5rem;
                background-color: hsl(0deg, 0%, 90%);
                left: 0;
                bottom: 0;
                border-radius: 0 0 0 .5rem;
                animation: tenSecondLoad 10s ease-out;
            }
        }

        :deep(.fr-section) {
            height: 0 !important;
            overflow: hidden !important;
        }
    }

    .fr-body {
        padding: 0 1.5rem;

        &.fr-hidden {
            opacity: 0;
            position: absolute;
        }
    }

    // Progress Bar Styles
    .fr-progress {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        height: 0px;
        margin-bottom: 0rem;
        opacity: 0;
        transition: 200ms ease-out;

        &:not(.fr-hidden) {
            opacity: 1;
            height: 10px;
            margin-bottom: 1rem;
        }

        .fr-stop {
            position: relative;
            height: 10px;
            width: 10px;
            background-color: hsl(0deg,0%,95%);
            border-radius: 50rem;
            transition: 200ms ease-out;
            cursor: pointer;

            &.fr-active {
                background-color: white;
                border: 1px solid var(--blue-500);
            }

            &.fr-before {
                background-color: var(--blue-500);
            }
        }

        // This may need to be raised if they ever go above 20 steps
        @for $i from 1 through 20 {
            &[data-progress="#{$i}"] {
                .fr-bar:after { width: calc(((#{$i} - 1) / (var(--stop-count) - 1)) * 100%); }
            }
        }

        .fr-bar {
            position: absolute;
            background-color: hsl(0deg,0%,95%);
            height: 4px;
            width: calc(100% - 4px);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            &:after {
                position: absolute;
                content: '';
                height: 100%;
                left: 0;
                top: 0;
                background-color: var(--blue-500);
                transition: 200ms ease-out;
            }
        }
    }


    // Deep button styles
    ::v-deep(.fr-btn) {
        background-color: var(--blue-500);
        color: white;
        padding: .25rem .75rem;
        border-radius: 5px;

        &:focus {
            box-shadow: 0 0 0 2px black;
        }
    }

    .fr-form {
        --gbs-color: rgba(0,0,0,0.3);
        --gbs-pos: inset 0 0;
        --global-box-shadow: var(--gbs-pos) 3px var(--gbs-color);
        position: relative;
        margin: 2rem auto;
        max-width: 500px;
        padding: 1.5rem 0;
        border-radius: .5rem;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        background-color: white;
        scroll-margin: 2rem;
    }

    .fr-title {
        margin-bottom: 1rem;
        text-transform: capitalize;
    }

    .fr-navigation {
        display: grid;
        grid-template:
            "p n" max-content
            / 1fr 1fr;
        gap: 2rem;
        margin-top: 1rem;

        .fr-btn-prev {
            grid-area: p;
        }

        .fr-btn-next, .fr-btn-submit {
            grid-area: n;
        }

        .fr-btn-submit {
            background-color: var(--green-500)
        }
    }
</style>
