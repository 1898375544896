<template>
    <div>
        <JetLabel :for="'password_'+uid" :value="labelValue || 'Password'" />
        <div class="input-wrapper">
            <JetInput :id="'password_'+uid" :type="type" class="mt-1 block w-full" :value="modelValue" @input="onInput($event)" required v-bind="$attrs" />
            <FontAwesomeIcon :icon="type=='password'?eye:eyeSlash" @click.prevent="switchInputType"/>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import uidMixin from '@/Mixins/uidMixin.js'

export default {
    inheritAttrs: false,
    mixins: [uidMixin],
    props: [
        "modelValue",
        "labelValue"
    ],
    emits: [
        "update:modelValue"
    ],
    components: {
        FontAwesomeIcon,
        JetLabel,
        JetInput,
    },
    data() {
        return {
            type: 'password',
            eye: faEye,
            eyeSlash: faEyeSlash,
        }
    },
    methods: {
        switchInputType() {
            this.type = this.type == 'password' ? 'text' : 'password';
        },
        onInput() {
            this.$emit('update:modelValue', event.target.value);
        }
    }
}
</script>

<style lang="scss" scoped>
    .input-wrapper {
        position: relative;

        input {
            padding-right: 2.9rem;
        }

        svg {
            position: absolute;
            top: 50%;
            right: 1.5rem;
            transform: translate(50%, -50%);
            opacity: .5;
            cursor: pointer;
        }
    }
</style>