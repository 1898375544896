<template>
    <ManageLayout
        name="Companies"
    >
        <CompaniesTable />
    </ManageLayout>
</template>

<script>
    import CompaniesTable from '@/Pages/Manage/Tables/CompaniesTable.vue'
    import ManageLayout from '@/Pages/Manage/Layouts/ManageLayout.vue'
    import Card from '@/Components/Card.vue'

    export default {
        components: {
            CompaniesTable,
            ManageLayout,
            Card
        },
        methods: {
            deleteUser(data) {
                if (!confirm('Are you sure want to remove?')) return;
                let vm = this;
                this.loading = true;
                axios.get(route('delete.user',data.id))
                    .then(response => {
                        vm.$page.props.jetstream.flash.banner = response.data.message;
                        vm.loading =  false,
                        vm.fetchCompanyUsers();
                    })
            },
            deleteUsers() {
              if (confirm("Do you really want to delete all users?")){
                let vm = this;
                axios
                    .get(route('delete.users'))
                    .then(response => {
                        vm.$page.props.jetstream.flash.banner = response.data.message;
                        vm.loading =  false,
                        vm.fetchUsers();
                    })
                }
            },
        }
    }
</script>
