<template>
    <form class="fr-form" :class="{'submitted': submitted, 'success': submittedSuccessfully, 'error': submittedWithErrors}" @submit.prevent="submitForm()" ref="form">
        <div class="fr-body" :class="{'fr-hidden':submitted}">
            <h4 class="fr-title">{{ data.form.name }}</h4>
            <h5 class="locked" v-if="data.locked">LOCKED BY: {{ data.locked_by }}</h5>

            <FRESection
                v-for="(section_id) in data.form.sections"
                :key="`section_${section_id}`"
                :section="data.sections[section_id]"
                :questions="questionsInSection(section_id)"
                :options="optionsInSection(section_id)"
                :interactions="interactionsInSection(section_id)"
                :answers="answersInSection(section_id)"
            />

            <div class="fr-navigation">
                <button v-if="!data.locked" class="fr-btn fr-btn-submit" title="Submit the form">Submit</button>
            </div>
        </div>

        <FRLoader v-show="submitted" :success="submittedSuccessfully" :error="submittedWithErrors" :message="responseMessage" />

    </form>
</template>

<script>
import formattingMixin from '@/Mixins/formattingMixin';
import FRESection from '@/Components/FormElements/FRESection';
import FRLoader from '@/Components/FormElements/FRLoader';

export default {
    name: "FormEditRenderer",
    props: ["data"],
    mixins: [formattingMixin],
    components: {
        FRESection,
        FRLoader,
    },
    data() {
        return {
            activeSection: 0,
            submitted: false,
            submittedSuccessfully: false,
            responseMessage: null,
            submittedWithErrors: false,
            formResetInc: 0
        }
    },
    methods: {
        submitForm() {
            let form = event.target,
                vm   = this;

            this.submitted = true;

            let formData = new FormData(form);
            if (this.data.patients_guid) formData.append('patients_guid', this.data.patients_guid);

            setTimeout(function() {
                axios
                    .post(
                        route('insertFormAnswersByGuid'),
                        vm.formatFormData(vm.data.questions, formData, {
                            forms_ctrl_guid: vm.data.form.guid,
                            patients_guid: vm.data.patients_guid,
                            forms_guid: vm.data.forms_guid,
                            form_version_id: vm.data.form.version
                        })
                    )
                    .then(res => {
                        vm.submittedSuccessfully = true;
                        vm.responseMessage = res.data.message || "Form submitted successfully!"
                    })
                    .catch(err => {
                        vm.submittedWithErrors = true;
                        vm.responseMessage = err.data.message || "Something went wrong during submission";
                        console.error(err)
                    })
                    .finally(() => {
                        setTimeout(function() {
                            vm.submittedSuccessfully = false;
                            vm.submittedWithErrors   = false;
                            vm.responseMessage       = null;

                            setTimeout(function() {
                                vm.submitted = false;
                            }, 400)
                        }, 10000)
                    });
            }, 400);
        },
        questionsInSection(id) {
            let questionIds = this.data.sections[id].questions;
            let res = Object.entries(this.data.questions).reduce((a, [k, v]) => {
                if (questionIds.includes(parseInt(k))) a[k] = v;
                return a;
            }, {});
            if (!Object.keys(res).length) res = undefined;
            return res;
        },
        optionsInSection(id) {
            let optionIds = [...new Set(Object.values(this.questionsInSection(id)).map(question => question.options).filter(options => options).flat())],
                res = undefined;

            if (optionIds.length) {
                res = Object.entries(this.data.options).reduce((a, [k, v]) => {
                    if (optionIds.includes(parseInt(k))) a[k] = v;
                    return a;
                }, {})
            }

            return res;
        },
        interactionsInSection(id) {
            let questionIds              = this.data.sections[id].questions,
                res                      = undefined,
                inter                    = this.data.interactions,
                existsAndIsObjWithLength = typeof inter === 'object' && !Array.isArray(inter) && inter !== null && Object.keys(inter).length;

            if (existsAndIsObjWithLength) {
                res = Object.entries(this.data.interactions).reduce((a, [k, v]) => {
                    if (questionIds.includes(parseInt(k))) a[k] = v;
                    return a;
                }, {});
            }
            if (res && !Object.keys(res).length) res = undefined;
            return res;
        },
        answersInSection(id) {
            let questionIds = this.data.sections[id].questions;
            let res = Object.entries(this.data.answers).reduce((a, [k, v]) => {
                if (questionIds.includes(parseInt(k))) a[k] = v;
                return a;
            }, {});
            if (!Object.keys(res).length) res = undefined;
            return res;
        },
    },
    mounted() {
        // This is gross and is a last-resort band-aid caused by the combination of both not having backup developers
        // and making it nearly impossible to create backup developers as none of our development instances or permissions are the same
        setTimeout(_ => {
            // Look for labels that match the text value we are looking for
            let vgmAccountNumberLabel = Array.from(document.querySelectorAll('.fr-label')).find(l => l.textContent.includes('VGM Account Number:'))
            // If we find one
            if (vgmAccountNumberLabel) {
                // select the input it's associated with
                let vgmAccountNumberInput = vgmAccountNumberLabel.nextElementSibling
                // set it to be readonly, make it nonfocusable, and grey it out
                vgmAccountNumberInput.setAttribute('readonly', '')
                vgmAccountNumberInput.setAttribute('tabindex', '-1')
                vgmAccountNumberInput.style.backgroundColor = 'hsl(0deg, 0%, 90%)'
                // and make it so you cannot click anywhere on the input group
                vgmAccountNumberInput.closest('.fr-group').style.pointerEvents = 'none'
            }
        }, 0)
    }
}
</script>

<style lang="scss" scoped>
    @keyframes tenSecondLoad {
        0% { width: 100%; }
        5% { opacity: 1; }
        100% { opacity: 1; width: 0%; }
    }

    .fr-form.submitted {
        position: relative;

        &.success, &.error {
            &:before {
                content: "";
                position: absolute;
                opacity: 0;
                width: 0%;
                height: .5rem;
                background-color: hsl(0deg, 0%, 90%);
                left: 0;
                bottom: 0;
                border-radius: 0 0 0 .5rem;
                animation: tenSecondLoad 10s ease-out;
            }
        }

        :deep(.fr-section) {
            height: 0 !important;
            overflow: hidden !important;
        }
    }

    .fr-body {
        padding: 0 1.5rem;

        &.fr-hidden {
            opacity: 0;
            position: absolute;
        }
    }

    // Deep button styles
    ::v-deep(.fr-btn) {
        background-color: var(--blue-500);
        color: white;
        padding: .25rem .75rem;
        border-radius: 5px;

        &:focus {
            box-shadow: 0 0 0 2px black;
        }
    }

    .fr-form {
        --gbs-color: rgba(0,0,0,0.3);
        --gbs-pos: inset 0 0;
        --global-box-shadow: var(--gbs-pos) 3px var(--gbs-color);
        position: relative;
        padding: 1.5rem 0;
        border-radius: .5rem;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        background-color: white;
        scroll-margin: 2rem;
    }

    .fr-title {
        margin-bottom: 1rem;
        text-transform: capitalize;
    }

    .fr-navigation {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;

        .fr-btn-submit {
            flex-grow: 0;
            background-color: var(--green-500)
        }
    }
</style>
