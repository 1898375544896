<template>
    <SimpleTableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('id')" @click="sortBy('id','number')">ID</p>
            <p :class="sortClass('name')" @click="sortBy('name')">Name</p>
        </div>
        <div class="simple-table">
            <p class="st-header first-col" :class="sortClass('id')" @click="sortBy('id','number')">ID</p>
            <p class="st-header" :class="sortClass('name')" @click="sortBy('name')">Name</p>

            <template v-for="row in displayedList" :key="'example_' + row.id">
                <p class="first-col"><span class="st-hidden">ID</span>{{ row.id }}</p>
                <p><span class="st-hidden">Name</span>{{ row.name }}</p>
            </template>
        </div>
    </SimpleTableWrapper>
</template>

<script>
    import SimpleTableWrapper from '@/Components/SimpleTableWrapper.vue';
    import tableMixin from '@/Mixins/tableMixin.js'

    export default {
        mixins: [tableMixin],
        components: {
            SimpleTableWrapper
        }
    }
</script>