<template>
    <div class="exports">
        <div class="loader" v-if="loading"></div>
        <div v-if="validate_msg != ''">
            <p class="text-green-500 my-0"><strong> {{validate_msg}}</strong></p>
        </div>
        <div v-if="error_msg != ''">
            <p class="text-red-500 my-0"><strong>  {{error_msg}} </strong></p>
        </div>
        <div class="documents">
            <button
                v-for="document in exports"
                :key="document.document_path"
                class="document"
                @click="fetchDocument(document.document_path, document.document_name)"
                :title="`Opens ${document.document_name} in a new tab`"
                target="_blank"
            >
                <FontAwesomeIcon :icon="icons.file" />
                {{ document.document_name }}
            </button>
        </div>
        <form ref="form" @submit.prevent="submitForm" class="mt-4">
            <button
                type="submit"
                class="btn-green m-0"
                :disabled='export_in_process'
            >{{ button_text }}</button>
        </form>
    </div>
</template>

<script>
import SimpleSelect from '@/Components/FormElements/SimpleSelect.vue'
import formattingMixin from "@/Mixins/formattingMixin.js";
import formProcessingMixin from "@/Mixins/formProcessingMixin.js";
import { Link } from "@inertiajs/inertia-vue3";
import JetLabel from "@/Jetstream/Label.vue";
import JetInput from "@/Jetstream/Input.vue";
import Card from "@/Components/Card.vue";
import { faFile, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { defaultsDeep } from 'lodash';

export default {
  mixins: [formattingMixin, formProcessingMixin],
  components: {
    SimpleSelect,
    JetLabel,
    JetInput,
    Card,
    Link,
    FontAwesomeIcon,
  },
  data() {
    return {
        activeView: "ExportData",
        loading: false,
        formList: JSON.parse(this.$page.props.formList),
        form: {
            form_id: 1,
        },
        error_msg: false,
        validate_msg: false,
        button_text: 'Create New Export',
        messages: [],
        exports: [],
        export_in_process: false,
        icons: {
            file: faFile,
        }
    };
  },
  methods: {
    setActiveView(view) {
      this.activeView = view;
    },
    buttonFilter(view) {
      return `${view && this.activeView == view ? " active" : ""}`;
    },
    buttonClasses(view) {
      return `btn-gray${view && this.activeView == view ? " active" : ""}`;
    },
    submitForm(e) {
        let vm = this;

        // After 5 seconds change the feedback to show it is processing
        // By then the background process should already be running
        setTimeout(function(){
            vm.export_in_process = true;
            vm.button_text = 'Export Currently Running...';
            vm.loading = false
        }, 5000);

        // Kick off the background process
        vm.button_text = 'Processing...';
        vm.loading = true;
        vm.error_msg = false;
        vm.validate_msg = false;
        axios
            .get(route('export', vm.form))
            .then(res => {
                vm.loading = false
                vm.fetchExports();
                vm.export_in_process = true;
                vm.$page.props.jetstream.flash.bannerStyle = res.data.result === 'error' ? 'danger' : '';
                vm.$page.props.jetstream.flash.banner = res.data.message;
            })
            .catch(function (error) {
                // Commenting this out since it is supposed to be a background process but doesn't fully detach
                // and you will eventually get a gateway timeout error and the process will continue to run.
                // No need to alert of this error when it happens.

                // console.error(error);
                // vm.loading = false
                // vm.error_msg = error.response.message
            });
    },
    fetchExports() {
        axios({
            method: 'get',
            url: route('fetch_exports'),
        })
        .then(response => {
            if (response.data) {
                this.exports = response.data.data;
            }
        })
    },
    fetchDocument(path, filename) {
        axios({
            method: 'post',
            url: route('fetch_export'),
            data: {'path': path, 'filename': filename}
        })
        .then(response => {
            const link = document.createElement("a");
            link.href = response.data;
            link.download = 'test1';
            link.click();
            link.remove();
        })
        .catch(error => {
            console.error(error);
        });
    },
    fetchExportStatus() {
        axios({
            method: 'get',
            url: route('fetch_export_status'),
        })
        .then(response => {
            if (response.data) {
                this.export_in_process = response.data;
                if (this.export_in_process)
                {
                    this.button_text = 'Export Currently Running...';
                }
            }
        })
    },
  },
  mounted() {
    this.fetchExportStatus();
    this.fetchExports();
  }
};
</script>

<style lang="scss" scoped>
    .documents {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr));
        grid-auto-rows: auto;
        gap: .5rem;

        .document {
            position: relative;
            display: flex;
            align-items: center;
            gap: .5rem;
            font-size: 18px;
            line-height: 1.2;
            color: var(--blue-500);
            padding: .75rem 1.25rem;
            box-shadow: var(--global-box-shadow);
            border-radius: 3px;
            text-align: left;
            text-decoration: underline;

            &:hover { text-decoration: none; }
            svg { font-size: 21px; }
        }
    }
</style>
