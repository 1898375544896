<template>
    <div class="radio-wrapper">
        <template v-if="modelValue === undefined">
            <!-- Too many options - dropdown  -->
            <FRQuestionFieldsetDropdown v-if="needsDropdown"
                input-type="radio"
                :question="question"
                :question-id="questionId"
                :options="options"
                :interactions="interactions"
                :focussed-question="focussedQuestion"
                :active-section="activeSection"
                @toggle-trigger="toggleIfTrigger($event)"
                @focus-section="focusSection()"
            />

            <!-- Not too many options - chip selectors -->
            <FRQuestionChipSelector v-else
                input-type="radio"
                :question="question"
                :question-id="questionId"
                :options="options"
                :interactions="interactions"
                :focussed-question="focussedQuestion"
                :active-section="activeSection"
                @toggle-trigger="toggleIfTrigger($event)"
                @focus-section="focusSection()"
            />
        </template>

        <template v-else>
            <!-- Too many options - dropdown  -->
            <FRQuestionFieldsetDropdown v-if="needsDropdown"
                input-type="radio"
                v-model="inputModel"
                :question="question"
                :question-id="questionId"
                :options="options"
                :interactions="interactions"
                :focussed-question="focussedQuestion"
                :active-section="activeSection"
                @toggle-trigger="toggleIfTrigger($event)"
                @focus-section="focusSection()"
            />

            <!-- Not too many options - chip selectors -->
            <FRQuestionChipSelector v-else
                input-type="radio"
                v-model="inputModel"
                :question="question"
                :question-id="questionId"
                :options="options"
                :interactions="interactions"
                :focussed-question="focussedQuestion"
                :active-section="activeSection"
                @toggle-trigger="toggleIfTrigger($event)"
                @focus-section="focusSection()"
            />
        </template>

    </div>
</template>

<script>
import FRQuestionChipSelector from '../FormElements/FRQuestionChipSelector.vue';
import FRQuestionFieldsetDropdown from '../FormElements/FRQuestionFieldsetDropdown.vue';
import { mixin as inputMixin } from './formMixin'; // This is where the inputBinder comes from

export default {
    name: "FRQuestionRadio",
    mixins: [inputMixin],
    props: [
        "noTab",
        "options",
        "question",
        "questionId",
        "interactions",
        "activeSection",
        "focussedQuestion"
    ],
    emits: ["toggle-trigger", "focus-section"],
    components: {
        FRQuestionChipSelector,
        FRQuestionFieldsetDropdown,
    },
    computed: {
        needsDropdown() {
            return this.question.options?.length > 7;
        },
    },
    methods: {
        toggleIfTrigger(obj) {
            this.$emit('toggle-trigger', obj);
        },
        focusSection() {
            this.$emit('focus-section');
        },
    }
}
</script>
