<template>
    <div class="fr-fieldset fr-dropdown" :class="[selectedOptions.length ? 'fr-options-selected' : '', validClass]" role="group" :aria-labelledby="`question-${questionId}-label`">
        <p class="fr-legend" :id="`question-${questionId}-label`" @click="focusSection()">{{ question.question }}<span class="fr-required" v-if="required">*</span></p>

        <div class="fr-options" :class="{expanded: expanded}" ref="options">
            <div class="fr-fieldset-dropdown">
                <button
                    type="button"
                    class="fr-dropdown-toggle"
                    :title="`Shows/Hides options for question ${question.question}`"
                    tabindex="-1"
                    @click.prevent="toggleDropdown()"
                >Please Select...</button>
                <div class="fr-dropdown-container" ref="dropdown">
                    <label class="fr-option" v-for="optionId in question.options" :key="`option_${optionId}`" >
                        <input
                            v-if="modelValue === undefined"
                            :class="`fr-control-${inputType}`"
                            :name="questionId"
                            :type="inputType"
                            :required="required && valid !== 'valid'"
                            :aria-required="required && valid !== 'valid'"
                            :value="optionId"
                            :data-selected-value="options[optionId].value"
                            @change="toggleIfTrigger(optionId)"
                            @focus="focusSection()"
                            @blur="checkValue()"
                        />
                        <input
                            v-else
                            :class="`fr-control-${inputType}`"
                            :name="questionId"
                            :type="inputType"
                            :required="required && valid !== 'valid'"
                            :aria-required="required && valid !== 'valid'"
                            v-model="inputModel"
                            :value="optionId"
                            :data-selected-value="options[optionId].value"
                            @change="toggleIfTrigger(optionId)"
                            @focus="focusSection()"
                            @blur="checkValue()"
                        />
                        {{ options[optionId].value }}
                    </label>
                </div>
                <p class="fr-assistive" v-html="selectedNames"></p>
            </div>
        </div>
    </div>
</template>

<script>
import { mixin as inputMixin } from './formMixin'; // This is where the inputBinder comes from

export default {
    name: "FRQuestionFieldsetDropdown",
    mixins: [ inputMixin ],
    emits: ['toggle-trigger', 'focus-section'],
    props: [
        "options",
        "question",
        "inputType",
        "questionId",
        "interactions",
        "activeSection",
        "focussedQuestion",
    ],
    data() {
        return {
            expanded: false,
            valid: undefined,
            mounted: false,
            computedInc: 0
        }
    },
    computed: {
        selectedOptions() {
            let c = this.computedInc; // fake dep to refresh
            if (!this.mounted) return [];
            let checked = Array.from(this.$refs.dropdown.querySelectorAll('input:checked'));
            return checked.map(i => i.dataset.selectedValue);
        },
        selectedNames() {
            let res = "Please select an option";
            if (this.selectedOptions.length) {
                let items = this.selectedOptions.map(n => {
                        let span = document.createElement('span');
                        span.textContent = n;
                        return span.outerHTML;
                    }),
                    count = items.length > 1 ? `(${items.length})` : '';

                res = `Selected${count}: ${items.join(", ")}`;
            }
            return res;
        },
        validClass() {
            let res = false;
            if (this.valid) res = `fr-${this.valid}`;
            return res;
        },
    },
    methods: {
        toggleDropdown() {
            if (this.expanded) {
                this.expanded = false;
            } else {
                this.focusSection();
            }
        },
        toggleIfTrigger(id) {
            this.checkValue();
            let checked = this.$refs.dropdown.querySelectorAll('input:checked'),
                questionInteractions = this.interactions?.[this.questionId]?.options,
                check = questionInteractions?.some(triggerOption => {
                    return Array.from(checked).map(input => {
                        return input.value
                    }).includes(`${triggerOption}`)
                });

            if (questionInteractions) {
                this.$emit('toggle-trigger', { 'questionId': this.questionId, 'check': check });
            }

            this.toggleSelectedOptions(id)
        },
        toggleSelectedOptions(id) {
            this.computedInc++;
        },
        focusSection() {
            this.expanded = true;
            this.$emit('focus-section');
        },
        watchCloseExpanded(n,o) {
            if (o && !n) this.expanded = false;
        },
        checkValue() {
            if (this.required) {
                let check = Array.from(this.$refs.options.querySelectorAll('input')).some(i => i.checked);
                this.valid = check ? 'valid' : 'invalid';
            }
        }
    },
    watch: {
        expanded() {
            let el     = this.$refs.dropdown,
                height = el.scrollHeight,
                capped = height > 200 ? 200 : height;

            el.style.setProperty('--height', capped + 'px');
        },
        activeSection(n,o) {
            this.watchCloseExpanded(n,o)
        },
        focussedQuestion(n,o) {
            this.watchCloseExpanded(n,o)
        }
    },
    mounted() {
        this.mounted = true;
    }
}
</script>

<style lang="scss" scoped>
    .fr-legend {
        margin-bottom: 2px;
        font-size: 14px;
        line-height: 1.1;
    }

    .fr-options {
        align-items: flex-start;
        position: relative;
        padding: 0;
        margin: 0;
        width: 100%;

        &.expanded {
            .fr-dropdown-container {
                height: var(--height);
                overflow: auto;
            }

            .fr-dropdown-toggle {
                border-bottom: 1px solid hsl(0deg,0%,57%);
                border-radius: 3px 3px 0 0;

                &:before { transform: translateY(-50%) rotate(-180deg); }
            }
        }
    }

    .fr-dropdown-toggle {
        content: "Please select...";
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        display: block;
        padding: .25rem .75rem;
        border-bottom: 1px solid transparent;
        line-height: 1.5rem;
        font-size: 14px;
        transition: 200ms ease-out;
        color: black;
        background-color: white;
        text-align: left;
        font-size: 14px;
        border-radius: 3px;
        border: 0;
        outline: 0;
        box-shadow: var(--global-box-shadow);

        .fr-fieldset.fr-invalid & {
            box-shadow: var(--gbs-pos) 0 1px var(--red-500);
        }

        .fr-fieldset.fr-invalid:focus-within & {
            box-shadow: var(--gbs-pos) 0 2px var(--red-500);
        }

        &:before {
            --ca-thickness: 20%;
            content: "";
            position: absolute;
            top: 50%;
            right: .75rem;
            transform: translateY(-50%) rotate(0);
            background-color: hsl(0deg,0%,85%);
            width: 12px;
            height: 6px;
            transition: 200ms ease-out;
            clip-path: polygon(0% 0%,50% 100%,100% 0%,calc(100% - var(--ca-thickness)) 0%,50% calc(100% - var(--ca-thickness) * 2),calc(var(--ca-thickness)) 0%);
        }
    }

    .fr-dropdown-container {
        overflow: hidden;
        height: 0;
        transition: 200ms ease-out;
        box-shadow: var(--global-box-shadow);

        &::-webkit-scrollbar-track { background: #e9ecef; }
        &::-webkit-scrollbar-thumb { background: rgba(0,0,0,0.2); }
        &::-webkit-scrollbar-thumb:hover { background: rgba(0,0,0,0.4); }
        &::-webkit-scrollbar {
            width: .5rem;
            height: .5rem;
        }
    }

    .fr-option {
        padding: .5rem .75rem;
        position: relative;
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        line-height: 1.1;

        input { margin-right: .5rem; }
    }

    .fr-assistive {
        font-size: 12px;
        margin-top: 4px;
        line-height: 1.1;
        color: hsl(0deg,0%,40%);
        display: none;

        .fr-options-selected & {
            display: block;
        }

        .fr-fieldset.fr-invalid & {
            color: var(--red-500);
            display: block;
        }

        :deep(span) { font-weight: bold; }
    }
</style>