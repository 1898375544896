<template>
    <DetailsLayout :name="'Notes'" :data="data" >
        <NotesTable />
    </DetailsLayout>
</template>

<script>
    import DetailsLayout from '../Layouts/DetailsLayout.vue';
    import NotesTable from '../Tables/NotesTable.vue';

    export default {
        name: "Notes",
        components: {
            DetailsLayout,
            NotesTable
        },
        props: ["data"],
    }
</script>