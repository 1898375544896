<template>
    <div
        class="form-group"
        v-bind="groupBinder"
    >
        <div class="input-wrapper">
            <JetLabel :for="inputId" :value="inputLabel" />
            <input
                class="date-input border-gray-600 focus:border-blue-300 focus:ring focus:ring-blue-300 focus:ring-opacity-50 shadow-sm bg-white mt-1 block w-full"
                type="date"
                :value="modelValue || undefined"
                v-bind="modifiedBinder"
            >
        </div>
    </div>
</template>

<script>
import JetLabel from '@/Jetstream/Label.vue'
import uidMixin from '@/Mixins/uidMixin.js'
import { mixin } from './simpleFormMixin.js'

export default {
    mixins: [uidMixin,mixin],
    components: {
        JetLabel
    },
    props: {
        min: {
            type: [String],
            validator: (value) => { return value.match(/\d{4}-0*([1-9]|1[0-2])-0*([1-9]|[12][0-9]|3[01])/g); },
            default: null
        },
        max: {
            type: [String],
            validator: (value) => { return value.match(/\d{4}-0*([1-9]|1[0-2])-0*([1-9]|[12][0-9]|3[01])/g); },
            default: null
        },
        step: {
            type: [Number, String],
            validator: (value) => { return parseInt(value); },
            default: null
        }
    },
    computed: {
        modifiedBinder() {
            return {
                ...this.inputBinder,
                min: this.min,
                max: this.max,
                step: this.step,
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .date-input::-webkit-calendar-picker-indicator { cursor: pointer; }
</style>