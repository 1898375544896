<template>
    <form class="-mx-2 flex flex-col justify-between h-full" @submit.prevent="updatePassword">
        <div class="flex flex-wrap">
            <div class="p-2 w-full">
                <JetLabel for="current_password" value="Current Password" />
                <JetInput id="current_password" type="password" class="mt-1 block w-full" v-model="form.current_password" ref="current_password" autocomplete="current-password" />
                <JetInputError :message="form.errors.current_password" class="mt-2" />
            </div>

            <div class="p-2 flex-grow">
                <JetLabel for="password" value="New Password" />
                <JetInput id="password" type="password" class="mt-1 block w-full" v-model="form.password" ref="password" autocomplete="new-password" />
                <JetInputError :message="form.errors.password" class="mt-2" />
            </div>

            <div class="p-2 flex-grow">
                <JetLabel for="password_confirmation" value="Confirm Password" />
                <JetInput id="password_confirmation" type="password" class="mt-1 block w-full" v-model="form.password_confirmation" autocomplete="new-password" />
                <JetInputError :message="form.errors.password_confirmation" class="mt-2" />
            </div>
        </div>

        <div class="pt-2 px-2 w-full flex justify-end">
            <JetActionMessage :on="form.recentlySuccessful" class="mr-3">Saved.</JetActionMessage>
            <button class="flex-grow-0 btn-blue" :class="{'disabled':form.processing}">Save</button>
        </div>
    </form>
</template>

<script>
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetLabel from '@/Jetstream/Label.vue'

    export default {
        components: {
            JetActionMessage,
            JetInputError,
            JetInput,
            JetLabel,
        },

        data() {
            return {
                form: this.$inertia.form({
                    current_password: '',
                    password: '',
                    password_confirmation: '',
                }),
            }
        },

        methods: {
            updatePassword() {
                this.form.put(route('user-password.update'), {
                    errorBag: 'updatePassword',
                    preserveScroll: true,
                    onSuccess: () => this.form.reset(),
                    onError: () => {
                        if (this.form.errors.password) {
                            this.form.reset('password', 'password_confirmation')
                            this.$refs.password.focus()
                        }

                        if (this.form.errors.current_password) {
                            this.form.reset('current_password')
                            this.$refs.current_password.focus()
                        }
                    }
                })
            },
        },
    }
</script>
