<template>
    <div class="simple-table-wrapper" :class="`simple-table-wrapper-${respondAt}`">
        <form @submit.prevent="updateSearch()" v-if="list.searchableData.length">
            <label class="st-label" :for="uuid">Search</label>
            <div class="st-group">
                <input
                    type="search"
                    name="searchModel"
                    class="st-search"
                    v-model="searchModel"
                    :id="uuid"
                />
                <button class="btn-blue m-0">Apply</button>
            </div>
        </form>

        <slot></slot>

        <div class="st-bottom">
            <p>{{ list.rangeText }}</p>
            <Pagination
                v-if="list.itemCount && list.maxPage > 1"
                :active-page="list.activePage"
                :item-count="list.itemCount"
                :items-per-page="list.itemsPerPage"
                @on-update="list.updateActivePage($event)"
            />
        </div>
    </div>
</template>

<script>
    import Pagination from './Pagination.vue';

    export default {
        name: "TableWrapper",
        components: {
            Pagination,
        },
        props: {
            list: {
                type: Object,
                required: true
            },
            respondAt: {
                type: String,
                default: 'sm'
            }
        },
        data() {
            return {
                searchModel: ""
            }
        },
        methods: {
            updateSearch() {
                this.list.updateSearch(this.searchModel);
            },
        },
        computed: {
            searchable() {
                return !!this.list.searchable;
            },
            uuid() {
                return crypto.randomUUID();
            }
        },
        watch: {
            searchModel(n,o) {
                if (!n && o) {
                    this.updateSearch()
                }
            }
        }
    }
</script>