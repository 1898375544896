<template>
    <AppLayout title="Edit User">
        <div class="loader" v-if="loading"></div>
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},'Edit User']"/>
        </template>

        <Card title="Edit User">
            <div class="buttons">
                <div>
                    <button class="btn-gray" @click.prevent="goBack()">
                        <FontAwesomeIcon class="mr-1" fixed-width :icon="arrowLeft"/> Go Back
                    </button>
                </div>
            </div>

            <div v-if="validate_msg != ''">
                <p class="text-green-500 my-0"><strong> {{validate_msg}}</strong></p>
            </div>
            <div v-if="error_msg != ''">
                <p class="text-red-500 my-0"><strong>  {{error_msg}} </strong></p>
            </div>
            <form ref="form" @submit.prevent="submitForm">
                 <div class="md:flex -mb-2 -mx-2">
                    <div class="m-2 flex-grow">
                        <SimpleSelect
                            :modelValue="this.$page.props.data.company_id"
                            name="Company"
                            :label="SelectCompany"
                            v-model="form.company_id"
                            :options="companyOptions"
                            :required="true"
                        />
                    </div>
                    <div class="m-2 flex-grow">
                        <JetLabel for="first_name" value="First Name" />
                        <JetInput name="first_name" type="text" class="mt-1 block w-full" v-model="form.first_name" required />
                    </div>

                    <div class="m-2 flex-grow">
                        <JetLabel for="last_name" value="Last Name" />
                        <JetInput name="last_name" type="text" class="mt-1 block w-full" v-model="form.last_name" required />
                    </div>
                </div>

                <div class="md:flex -mb-2 -mx-2">
                    <div class="m-2 flex-grow">
                        <JetLabel for="email" value="Email" />
                        <JetInput name="email" type="email" class="mt-1 block w-full" v-model="form.email" required />
                    </div>

                    <div class="m-2 flex-grow">
                        <JetLabel for="mobile" value="Mobile" />
                        <JetInput name="mobile" type="text" maxlength="10" class="mt-1 block w-full" v-model="form.mobile" required />
                    </div>
                </div>

                <div class="md:flex -mb-2 -mx-2">
                    <div class="m-2 flex-grow">
                        <JetLabel for="atp_number" value="ATP Number" />
                        <JetInput name="atp_number" type="text" class="mt-1 block w-full" v-model="form.atp_number" />
                    </div>
                </div>

                <div class="flex mt-3 -mx-1 -mb-1">
                    <button
                        type="submit"
                        class="btn-green m-1"
                    >Submit</button>

                    <button @click="cancel"
                        type="button"
                        class="btn-outline-gray m-1"
                    >Cancel</button>
                </div>
            </form>
        </Card>
    </AppLayout>
</template>

<script>
    import SimpleSelect from '@/Components/FormElements/SimpleSelect.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import formProcessingMixin from '@/Mixins/formProcessingMixin.js'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import Card from '@/Components/Card.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

    export default {
        mixins: [formattingMixin,formProcessingMixin],
        components: {
            Breadcrumbs,
            SimpleSelect,
            AppLayout,
            JetLabel,
            JetInput,
            Card,
            FontAwesomeIcon,
            faArrowLeft
        },
        props: ['data', 'errors'],
        data() {
            return {
                form: {
                    company_id: null,
                    email: null,
                    mobile: null,
                    last_name: null,
                    password:null,
                    atp_number:null,
                },
                durationOptions: [
                    {name: '', value: '', selected: true, hidden: 'true'},
                    {name: '30 days', value: 30},
                    {name: '60 days', value: 60},
                    {name: '90 days', value: 90},
                ],
                validate_msg: null,
                error_msg: null,
                loading:false,
                companyOptions: JSON.parse(this.$page.props.companiesList),
                arrowLeft: faArrowLeft,
            }
        },
        methods: {
            goBack() {
                window.history.back();
            },
            cancel: function (e) {
                this.$inertia.visit(route('manage.users'))
            },
            buttonClasses(view) {
                return `btn-gray${view && this.activeView == view ? ' active' : ''}`;
            },
            submitForm(e) {
                let vm = this;
                vm.loading = true;
                axios
                    .post(route('updateUser'), vm.form)
                    .then(res => {
                        vm.loading = false;
                        vm.validate_msg = res.data.message;
                        vm.error_msg = false;
                    })
                    .catch(function (error) {
                        vm.loading = false
                        vm.validate_msg = false
                        vm.error_msg = error.response.data.message
                    });
            },
        },
        mounted() {
            this.form = Object.assign({}, this.$page.props.data)
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../scss/_mixins.scss';

    .buttons {
        @include bp-up($bp-lg + 1) { margin: 0 -.5rem 1rem; }
        @include bp-down($bp-lg) {
            margin: 0 -.25rem 1rem;
            flex-wrap: wrap;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
            @include bp-down($bp-lg) {
                flex-wrap: wrap;
                flex-grow: 1;
            }
            display: flex;

            button, a {
                text-align: center;
                @include bp-up($bp-lg + 1) { margin: .5rem; }
                @include bp-down($bp-lg) { margin: .25rem; }
                @include bp-between(451px, $bp-lg) { flex: 1 0 calc(50% - .5rem) }
                @include bp-down(450px) { flex: 1 0 calc(100% - .5rem) }
            }
        }
    }

    .btn-checkboxes {
        display: flex;
        flex-wrap: wrap;
        margin: -.5rem -.5rem 0;

        input {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        label {
            margin: .5rem;
            text-align: center;
        }
    }
</style>
