<template>
	<div class="loader" :style="`--size:${spinnerSize}`">
		<svg class="loader-circle" :viewBox="`${spinnerSize / 2} ${spinnerSize / 2} ${spinnerSize} ${spinnerSize}`">
			<title>Loading Circle</title>
			<circle class="path" :cx="spinnerSize" :cy="spinnerSize" :r="(spinnerSize / 2) - (spinnerSize / 10)" fill="none" :stroke-width="spinnerSize / 10" stroke-miterlimit="10"/>
		</svg>
		<p class="loader-text" v-if="!hideMessage">{{ loaderMessage }}</p>
	</div>
</template>

<script>
export default {
	props: {
        hideMessage: {
            type: Boolean,
            default: false
        },
		loaderMessage: {
			type: String,
			default: "Loading...",
		},
        spinnerSize: {
            type: Number,
            default: 50
        }
	}
};
</script>

<style lang="scss" scoped>
    .loader {
        --w-unit: calc(var(--size) * 1px);
        --text-offset: calc(var(--size) * -.64);

        position: relative;
        width: var(--w-unit);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }

        .loader-text {
            position: absolute;
            width: max-content;
            left: 50%;
            bottom: var(--text-offset);
            transform: translateX(-50%);
            font-family: var(--font-family-sans-serif);
            color: rgba(0, 0, 0, 0.2);
            margin: 0;
        }

        .loader-circle {
            animation: rotate 2s linear infinite;
            height: 100%;
            transform-origin: center center;
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        .path {
            stroke-dasharray: 1, var(--spin1);
            stroke-dashoffset: 0;
            animation: dash 1.5s ease-in-out infinite;
            stroke-linecap: butt;
            stroke: rgba(0, 0, 0, 0.2);
        }
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }
</style>
