<template>
    <AppLayout title="Edit Company">
        <div class="loader" v-if="loading"></div>
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},'Edit Company']"/>
        </template>

        <Card title="Edit Company">
            <div class="buttons">
                <div>
                    <button class="btn-gray" @click.prevent="goBack()">
                        <FontAwesomeIcon class="mr-1" fixed-width :icon="arrowLeft"/> Go Back
                    </button>
                </div>
            </div>

            <div v-if="validate_msg != ''">
                <p class="text-green-500 my-0"><strong> {{validate_msg}}</strong></p>
            </div>
            <div v-if="error_msg != ''">
                <p class="text-red-500 my-0"><strong>  {{error_msg}} </strong></p>
            </div>
            <form enctype="multipart/form-data" @submit.prevent="submitForm">
                <JetLabel for="serviceType" value="Service Type" />
                <div class="flex flex-row">
                    <div class="m-2 flex items-center">
                        <JetCheckbox v-model="form.service_type" name="service_type[]" id="service2" true-value="COPD" value="COPD" />
                        <JetLabel for="serviceType2" value="COPD" class="p-2" />
                    </div>

                    <div class="m-2 flex items-center">
                        <JetCheckbox v-model="form.service_type" name="service_type[]" id="service3" true-value="CPGA" value="CPGA" />
                        <JetLabel for="serviceType3" value="CPGA" class="p-2" />
                    </div>
                    <div class="m-2 flex items-center">
                        <JetCheckbox v-model="form.service_type" name="service_type[]" id="service1" true-value="Functional Mobility Assesment" value="Functional Mobility Assesment"/>
                        <JetLabel for="serviceType1" value="Functional Mobility Assesment" class="p-2" />
                    </div>
                </div>

                <div class="md:flex -mb-2 -mx-2">
                    <div class="m-2 flex-grow">
                        <JetLabel for="company_name" value="Company Name" />
                        <JetInput name="company_name" placeholder="Company Name" type="text" class="mt-1 block w-full" v-model="form.company_name" required />
                    </div>

                    <div class="m-2 flex-grow">
                        <JetLabel for="vgm_number" value="VGM Number" />
                        <JetInput name="vgm_number" placeholder="VGM Number" type="text" class="mt-1 block w-full" v-model="form.vgm_number" required />
                    </div>
                </div>

                <div class="md:flex -mb-2 -mx-2">
                    <div class="m-2 flex-grow">
                        <JetLabel for="regdate" value="Company Reg Date" />
                        <JetInput
                            class="date-input border-gray-600 focus:border-blue-300 focus:ring focus:ring-blue-300 focus:ring-opacity-50 shadow-sm bg-white mt-1 block w-full"
                            type="date" name="reg_date" v-model="form.reg_date"
                             />
                     </div>

                    <div class="m-2 flex-grow">
                        <JetLabel for="homelink" value="Home Link" />
                        <JetInput name="homelink" type="text" placeholder="Homelink" class="mt-1 block w-full" v-model="form.homelink" required />
                    </div>
                </div>

                <div class="md:flex -mb-2 -mx-2">
                    <div class="m-2 flex-grow">
                        <JetLabel for="website" value="Company Website" />
                        <JetInput name="website" placeholder="https://website.com" type="text" class="mt-1 block w-full" v-model="form.website" required />
                    </div>

                     <div class="m-2 flex-grow">
                        <JetLabel for="fax" value="Fax" />
                        <JetInput name="fax" placeholder="12334567890" type="text" class="mt-1 block w-full" v-model="form.fax" />
                    </div>
                </div>

                <div class="md:flex -mb-2 -mx-2">
                    <div class="m-2 flex-grow">
                        <JetLabel for="address" value="Company Address" />
                        <JetInput name="address" placeholder="Enter Address" type="text" class="mt-1 block w-full" v-model="form.address" required />
                    </div>
                </div>

                <div class="md:flex -mb-2 -mx-2">
                    <div class="m-2 flex-grow">
                        <JetLabel for="city" value="City" />
                        <JetInput name="city" placeholder="Enter City" type="text" class="mt-1 block w-full" v-model="form.city" required />
                    </div>

                    <div class="m-2 flex-grow">
                        <SimpleSelect
                            name="State"
                            v-model="form.state"
                            :options="stateOptions"
                        />
                    </div>

                    <div class="m-2 flex-grow">
                        <JetLabel for="zip" value="Zip" />
                        <JetInput name="zip" placeholder="Enter Zip" type="text" class="mt-1 block w-full" v-model="form.zip" />
                    </div>
                </div>

                <div class="flex mt-3 -mx-1 -mb-1">
                    <button
                        type="submit"
                        class="btn-green m-1"
                    >Submit</button>

                    <button @click="cancel"
                        type="button"
                        class="btn-outline-gray m-1"
                    >Cancel</button>
                </div>
            </form>
        </Card>
    </AppLayout>
</template>

<script>
    import SimpleSelect from '@/Components/FormElements/SimpleSelect.vue'
    import formProcessingMixin from '@/Mixins/formProcessingMixin.js'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import Card from '@/Components/Card.vue'
    import AddressInputGroup from '@/Components/AddressInputGroup.vue'
    import SimpleDate from '@/Components/FormElements/SimpleDate.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
    export default {
        mixins: [formProcessingMixin, formattingMixin],
        components: {
            SimpleSelect,
            Breadcrumbs,
            JetCheckbox,
            AppLayout,
            JetLabel,
            JetInput,
            Card,
            AddressInputGroup,
            Link,
            SimpleDate,
            FontAwesomeIcon,
            faArrowLeft
        },
        props: ['data', 'errors'],
        data() {
            return {
                form: {
                    id: this.$page.props.data.id,
                    company_name: null,
                    website: null,
                    service_type: [],
                    vgm_number: null,
                    reg_date: null,
                    address: null,
                    city: this.$page.props.data.city,
                    state: this.$page.props.data.state || '' ,
                    zip: this.$page.props.data.zip,
                    fax: null,
                },
                validate_msg: null,
                error_msg: null,
                loading:false,
                arrowLeft: faArrowLeft,
                stateOptions: [
                    {name:"",value:"",hidden:true},
                    {name:"AK",value:"AK"},
                    {name:"AL",value:"AL"},
                    {name:"AR",value:"AR"},
                    {name:"AZ",value:"AZ"},
                    {name:"CA",value:"CA"},
                    {name:"CO",value:"CO"},
                    {name:"CT",value:"CT"},
                    {name:"DE",value:"DE"},
                    {name:"FL",value:"FL"},
                    {name:"GA",value:"GA"},
                    {name:"HI",value:"HI"},
                    {name:"IA",value:"IA"},
                    {name:"ID",value:"ID"},
                    {name:"IL",value:"IL"},
                    {name:"IN",value:"IN"},
                    {name:"KS",value:"KS"},
                    {name:"KY",value:"KY"},
                    {name:"LA",value:"LA"},
                    {name:"MA",value:"MA"},
                    {name:"MD",value:"MD"},
                    {name:"ME",value:"ME"},
                    {name:"MI",value:"MI"},
                    {name:"MN",value:"MN"},
                    {name:"MO",value:"MO"},
                    {name:"MS",value:"MS"},
                    {name:"MT",value:"MT"},
                    {name:"NC",value:"NC"},
                    {name:"ND",value:"ND"},
                    {name:"NE",value:"NE"},
                    {name:"NH",value:"NH"},
                    {name:"NJ",value:"NJ"},
                    {name:"NM",value:"NM"},
                    {name:"NV",value:"NV"},
                    {name:"NY",value:"NY"},
                    {name:"OH",value:"OH"},
                    {name:"OK",value:"OK"},
                    {name:"OR",value:"OR"},
                    {name:"PA",value:"PA"},
                    {name:"RI",value:"RI"},
                    {name:"SC",value:"SC"},
                    {name:"SD",value:"SD"},
                    {name:"TN",value:"TN"},
                    {name:"TX",value:"TX"},
                    {name:"UT",value:"UT"},
                    {name:"VA",value:"VA"},
                    {name:"VT",value:"VT"},
                    {name:"WA",value:"WA"},
                    {name:"WI",value:"WI"},
                    {name:"WV",value:"WV"},
                    {name:"WY",value:"WY"},
                ]
            }
        },
        methods: {
            goBack() {
                window.history.back();
            },
            cancel: function (e) {
                this.$inertia.visit(route('manage.users'))
            },
            buttonClasses(view) {
                return `btn-gray${view && this.activeView == view ? ' active' : ''}`;
            },
            submitForm(e) {
                let vm = this;
                vm.loading = true;
                vm.error_msg = false;
                vm.validate_msg = false;
                axios
                    .post(route('updateCompany'), vm.form)
                    .then(res => {
                        vm.loading = false
                        vm.validate_msg = res.data.message
                        //vm.$inertia.visit(route('users'));
                    })
                    .catch(function (error) {
                        vm.loading = false
                        vm.error_msg = error.response.data.message
                    });
            },
        },
        mounted() {
            this.form = Object.assign({}, this.$page.props.data)
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../scss/_mixins.scss';

    .buttons {
        @include bp-up($bp-lg + 1) { margin: 0 -.5rem 1rem; }
        @include bp-down($bp-lg) {
            margin: 0 -.25rem 1rem;
            flex-wrap: wrap;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
            @include bp-down($bp-lg) {
                flex-wrap: wrap;
                flex-grow: 1;
            }
            display: flex;

            button, a {
                text-align: center;
                @include bp-up($bp-lg + 1) { margin: .5rem; }
                @include bp-down($bp-lg) { margin: .25rem; }
                @include bp-between(451px, $bp-lg) { flex: 1 0 calc(50% - .5rem) }
                @include bp-down(450px) { flex: 1 0 calc(100% - .5rem) }
            }
        }
    }

    .btn-checkboxes {
        display: flex;
        flex-wrap: wrap;
        margin: -.5rem -.5rem 0;

        input {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        label {
            margin: .5rem;
            text-align: center;
        }
    }
</style>
