<template>
    <PatientFilters />

	<TableWrapper
        :list="list"
        :style="`--cols: ${hasFilters+7}`"
        respond-at="lg"
	>
		<div class="st-hidden-sorts">
			<p @click="list.sortBy(urlParams.length ? 'patient_name' : [['first_name'],['last_name']])" :class="list.sortClass(urlParams.length ? 'patient_name' : 'first_name')">Patient Name</p>
			<p @click="list.sortBy('last_note', null, 'date')" :class="list.sortClass('last_note')">Last Note</p>
			<p @click="list.sortBy('current_step_date', null, 'date')" :class="list.sortClass('current_step_date')">Date Added</p>
		</div>

		<div class="simple-table">
			<p class="first-col st-header">ATP</p>
			<p class="st-header" @click="list.sortBy(urlParams.length ? 'patient_name' : [['first_name'],['last_name']])" :class="list.sortClass(urlParams.length ? 'patient_name' : 'first_name')">Patient Name</p>
			<p class="st-header">UPI</p>
			<p class="st-header" @click="list.sortBy('last_note', null, 'date')" :class="list.sortClass('last_note')">Last Note</p>
			<p class="st-header" @click="list.sortBy('current_step_date', null, 'date')" :class="list.sortClass('current_step_date')">Date Added</p>
			<p class="st-header" v-if="hasFilters">Elapsed Days</p>
			<p class="st-header">Comm</p>
			<p class="last-col st-header">Actions</p>

			<template v-for="row in list.displayedList" :key="row.id">
				<div class="first-col"><span class="st-hidden">ATP</span>{{ row.atp_code }}</div>
				<div><span class="st-hidden">Patient Name</span>{{ row.patient_name }}</div>
				<div><span class="st-hidden">UPI</span>{{ row.patient_rts_code }}</div>
				<div><span class="st-hidden">Last Note</span>{{ row.last_note }}</div>
				<div><span class="st-hidden">Date Added</span>{{ row.current_step_date }}</div>
				<div v-if="hasFilters"><span class="st-hidden">Elapsed Days</span>{{ row.elapsed_days }}</div>
				<div><span class="st-hidden">Comm</span>{{ row.notifications }}</div>
                <div>
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <button class="btn-blue" @click="edit(row.id)">View / Edit</button>
                    </div>
                </div>
			</template>
		</div>
	</TableWrapper>
</template>

<script>
import { cloneDeep } from 'lodash';
import EloquentSSFP from "@/EloquentSSFP";
import { reactive } from 'vue';
import TableWrapper from '@/Components/TableWrapper.vue';
import PatientFilters from '@/Components/PatientFilters.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

export default {
    name: "ExceptionsTable",
    components: {
        TableWrapper,
        PatientFilters,
        FontAwesomeIcon,
    },
	data: function () {
		return {
            phone: faMobileAlt,
            email: faEnvelope,
			list: reactive(new EloquentSSFP()),
            hasFilters: false,
            mounted: false
		};
	},
    computed: {
        url() {
            return this.$page.url;
        },
        urlParams() {
            return this.url.split("/").slice(2);
        }
    },
    methods: {
        edit(id) {
            this.$inertia.visit(`/patient/${id}/details`);
        },
        fetchData() {
            let params = [],
                toPush = {},
                currOptions = cloneDeep(this.list.options),
                endpoint = '/patients/handle_filters',
                additional = {},
                additionalOptions = {
                    itemsPerPage: 20,
                    searchableData: this.urlParams.length
                        ? ["patient_name", "patient_rts_code"]
                        : ["first_name", "last_name", "rts_code"]
                };

            if (!this.mounted) params.push(endpoint);

            if (this.urlParams.length) {
                this.hasFilters = true;
                let parts = this.urlParams[0].split("?");
                additional = {
                    primaryFilter: parts[0],
                    secondaryFilter: parts[1]?.split("&") || []
                };
            }

            switch (!!this.urlParams.length + (!!this.mounted * 2)) {
                case 0: toPush = {...currOptions, ...additionalOptions}; break;                   // All Patients View, not mounted
                case 1: toPush = {...additionalOptions, additionalParameters: additional}; break; // Filtered View, not mounted
                case 2: toPush = currOptions; break;                                              // All Patients View, already mounted
                case 3: toPush = {...currOptions, ...{additionalParameters: additional}}; break;  // Filtered View, already mounted
            }

            params.push(toPush);

            this.list[this.mounted ? 'refreshData' : 'init'](...params);
        }
    },
    mounted() {
        this.fetchData();
        this.mounted = true;
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../../scss/_simple-table-styles.scss';

    .simple-table {
        --custom-col-structure: repeat(calc(var(--cols) - 1), 1fr) minmax(min-content, auto);
    }
</style>
