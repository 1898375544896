<template>
    <div class="text-center mb-4">
        <p class="title">What is the Functional Mobility Assessment (FMA)?</p>
        <p>The Functional Mobility Assessment (FMA) tool available through U.S. Rehab is a provider-centric way to measure how consumers self-report their ability to function using mobility equipment. The tool measures a patient’s satisfaction to perform Mobility-Related Activities of Daily Living (MRADL’s) in a client’s old equipment compared to his or her new equipment.</p>

        <iframe class="video mx-auto my-4" src="https://www.youtube.com/embed/sBdjqPZ5SP0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        <p>The FMA is an outcomes management tool that tracks patient health. This is the only outcomes tool that is provider-centric and follows clients after they are provided a new mobility intervention.</p>
    </div>
    <p class="title">Data collected from the FMA will be used several different ways.</p>

    <li>Defend the need and pricing for complex rehab technology (CRT)</li>
    <li>Show what we already know- what type of equipment is appropriate for various diagnoses</li>
    <li>Validate the importance of the ATP</li>
    <li>Produce evidence that when quality equipment is provided by a certified medical professional, patients have better outcomes</li>

    <p class="title mt-4">Why Participate?</p>
    <p>Participating providers will receive a report of their FMA data as compared to the national baseline. A minimum of 50 time 1 and 50 time 2 datasets are required before a report can be created. The information in the report can be used to market to referral sources and within provider accreditation policies and procedures, quality assurance, and site survey recertification.</p>

    <p class="title mt-4">Want to participate?</p>
    <p><strong>Contact us!</strong></p>
    <p>U.S. Rehab: <a href="tel:800-987-7342" title="Open phone number in dialer">800-987-7342</a> | <a href="mailto:info@usrehab.com" title="Open default email browser" target="_blank">info@usrehab.com</a></p>

    <p><em>If insurance companies aren't asking for outcomes data now, they will in the near future.</em></p>
</template>

<style lang="scss" scoped>
    @import '../../../scss/_mixins.scss';

    .title {
        @include fluid-size(16px, 20px, font-size);
        font-weight: 700;
        padding-bottom: .5rem;
    }

    .video {
        @include fluid-size(300px, 600px, width);
        @include fluid-size(300px, 415px, height);
    }

    a {
        &:hover {
            text-decoration: underline;
            font-weight: 600;
        }
    }

</style>
