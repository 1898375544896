<template>
    <div class="file-uploader-wrapper">
        <input hidden type="file" @change.prevent="onInput($event)" ref="fileInput" v-bind="$attrs" multiple/>
        <div class="file-list">
            <p class="file" v-for="(file, i) in files" :key="i+'file'">
                {{ file.name }}
                <FontAwesomeIcon :icon="trashAlt" @click.prevent="removeFile(i)" style="color: var(--red-500)" title="Remove this file from the files to be uploaded" />
            </p>
        </div>
        <div class="buttons">
            <button class="btn-outline-blue" @click.prevent="chooseFiles()" title="Add files to be uploaded">Add Files</button>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

export default {
    inheritAttrs: false,
    props: ['modelValue'],
    emits: ['update:modelValue'],
    components: {
        FontAwesomeIcon,
    },
    data() {
        return {
            files: [],
            trashAlt: faTrashAlt
        }
    },
    watch: {
        files: {
            handler(n) {
                this.$emit('update:modelValue', n);
            },
            deep: true
        }
    },
    methods: {
        onInput() {
            this.files = this.files.length ? this.files.concat([...event.target.files]) : [...event.target.files];
        },
        removeFile(i) {
            this.files.splice(i ,1);
        },
        chooseFiles() {
            this.$refs.fileInput.click();
        },
    }
}
</script>

<style lang="scss" scoped>
    .file-uploader-wrapper {
        padding: .25rem .5rem;
        background-color: white;
        box-shadow: var(--global-box-shadow);
        border-radius: 5px;
    }

    .file-list {
        display: flex;
        flex-direction: column;
        gap: 1px;
        background-color: var(--gray-200);

        .file {
            padding: 2px 4px;
            background-color: white;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            word-break: break-word;

            svg {
                margin-left: .5rem;
                cursor: pointer;
            }
        }
    }

    .buttons {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -.25rem;

        button {
            margin: .25rem;
        }
    }
</style>
