<template>
    <div class="fr-fieldset" :class="validClass" role="group" :aria-labelledby="`question-${question.id}-label`">
        <p class="fr-legend" :id="`question-${question.id}-label`">{{ question.question }}<span class="fr-required" v-if="required">*</span></p>

        <div class="fr-options" ref="options">
            <div class="fr-group" v-for="optionId in question.options" :key="`option_${optionId}`">
                <input
                    v-if="modelValue === undefined"
                    :class="`fr-control-${inputType}`"
                    :name="questionId"
                    :type="inputType"
                    :id="`${inputType}_${optionId}`"
                    :value="optionId"
                    :required="required && valid !== 'valid'"
                    :aria-required="required && valid !== 'valid'"
                    @change="toggleIfTrigger(optionId)"
                    @focus="focusSection()"
                    @blur="checkValue()"
                />
                <input
                    v-else
                    :class="`fr-control-${inputType}`"
                    :name="questionId"
                    :type="inputType"
                    :id="`${inputType}_${optionId}`"
                    :value="optionId"
                    :required="required && valid !== 'valid'"
                    v-model="inputModel"
                    :aria-required="required && valid !== 'valid'"
                    @change="toggleIfTrigger(optionId)"
                    @focus="focusSection()"
                    @blur="checkValue()"
                />
                <label class="fr-option" :for="`${inputType}_${optionId}`">{{ options[optionId].value }}</label>
            </div>
        </div>
        <p class="fr-assistive">Please select {{ inputType === "checkbox" ? "at least one" : "an"}} option</p>
    </div>
</template>

<script>
import { mixin as inputMixin } from './formMixin'; // This is where the inputBinder comes from

export default {
    name: "FRQuestionChipSelector",
    mixins: [ inputMixin ],
    emits: ['focus-section', 'toggle-trigger'],
    props: [
        "options",
        "question",
        "inputType",
        "questionId",
        "interactions",
    ],
    data() {
        return {
            valid: undefined,
        }
    },
    computed: {
        validClass() {
            let res = false;
            if (this.valid) res = `fr-${this.valid}`;
            return res;
        },
    },
    methods: {
        toggleIfTrigger(id) {
            this.checkValue();
            let checked              = event.target.checked,
                questionInteractions = this.interactions?.[this.questionId]?.options,
                optionTriggers       = questionInteractions?.includes(id);

            if (questionInteractions) {
                let typeCheck = this.inputType === 'checkbox' ? checked : optionTriggers;
                this.$emit('toggle-trigger', { 'questionId': this.questionId, 'check': typeCheck });
            }
        },
        focusSection() {
            this.$emit('focus-section');
        },
        checkValue() {
            if (this.required) {
                let check = Array.from(this.$refs.options.querySelectorAll('input')).some(i => i.checked);
                this.valid = check ? 'valid' : 'invalid';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .fr-legend {
        font-size: 14px;
        line-height: 1.1;
    }

    .fr-options {
        align-items: flex-start;
        margin: 0 -.25rem;
    }

    .fr-group {
        display: inline-block;
        margin: .25rem;
        position: relative;

        input {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
            top: 50%;
            left: 50%;

            &:checked + .fr-option {
                background-color: var(--blue-500);
                border-color: var(--blue-500);
                color: white;
            }

            &:focus, &:active {
                + .fr-option {
                    box-shadow: var(--global-box-shadow), 0 0 0 2px var(--blue-500);
                    .fr-fieldset.fr-invalid & { box-shadow: var(--global-box-shadow), 0 0 0 2px var(--red-500); }
                }
            }
        }

        .fr-option {
            font-size: 14px;
            display: inline-flex;
            padding: .25rem 1.25rem;
            box-shadow: var(--global-box-shadow), 0 0 0 0 var(--blue-500);
            background-color: white;
            border-radius: 55rem;
            transition: 200ms ease-out;
            align-items: center;
            cursor: pointer;

            &:hover {
                background-color: hsl(0deg,0%,95%);
            }

            .fr-fieldset.fr-invalid & { box-shadow: var(--global-box-shadow), 0 0 0 1px var(--red-500); }
        }
    }

    .fr-assistive {
        font-size: 12px;
        margin-top: 4px;
        line-height: 1.1;
        color: hsl(0deg,0%,40%);
        display: none;

        .fr-fieldset.fr-invalid & {
            color: var(--red-500);
            display: block;
        }

        :deep(span) { font-weight: bold; }
    }
</style>
