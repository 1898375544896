<template>
	<TableWrapper
        :list="list"
        style="--cols: 4"
	>
		<div class="simple-table">
			<p class="first-col st-header">Date Added</p>
			<p class="st-header">Note</p>
			<p class="st-header">Created By</p>
			<p class="last-col st-header">Actions</p>

			<template v-for="row in list.displayedList" :key="row.id">
				<div class="first-col"><span class="st-hidden">Date Added</span>{{ row.created_at }}</div>
				<div><span class="st-hidden">Note</span>{{ row.description }}</div>
				<div><span class="st-hidden">Created By</span>{{ row.created_by }}</div>
                <div>
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <!-- <button class="btn-blue" @click="editNote(row)">Edit</button> -->
                        <button class="btn-blue" @click="deleteNote(row.id)">Delete</button>
                    </div>
                </div>
			</template>
		</div>
	</TableWrapper>

    <div class="flex justify-end mt-4">
        <button @click="showAddModifyNoteModal = true" class="btn btn-blue m-0 flex-grow-0">Add Note</button>
    </div>

    <Teleport to="body">
        <SimpleModal :visible="showAddModifyNoteModal" @on-close="closeModal()">
            <template #header>
                <h5>{{ editing ? 'Edit' : 'Add' }} Note</h5>
            </template>

            <form @submit.prevent="submitNoteForm()">
                <input type="hidden" :value="activeNote.id" name="id" v-if="activeNote.id">
                <input type="hidden" :value="$page.props.data.guid" name="patients_guid">

                <FRQuestionTextarea
                    :question="{question: 'Note'}"
                    :model-value="activeNote.description"
                    question-id="description"
                    name="description"
                />

                <button class="btn-blue">Submit</button>
            </form>
        </SimpleModal>
    </Teleport>

</template>

<script>
import FRQuestionTextarea from '@/Components/FormElements/FRQuestionTextarea.vue';
import SimpleModal from '@/Components/SimpleModal.vue';
import EloquentSSFP from "@/EloquentSSFP";
import { reactive } from 'vue';
import TableWrapper from '@/Components/TableWrapper.vue';

export default {
    name: "NotesTable",
    components: {
        TableWrapper,
        SimpleModal,
        FRQuestionTextarea
    },
	data() {
		return {
			list: reactive(new EloquentSSFP()),
            editing: false,
            showAddModifyNoteModal: false,
            activeNote: reactive({
                id: 0,
                description: ""
            })
		}
	},
    methods: {
        defaultCatch(err) {
            console.error(err);
            this.$page.props.jetstream.flash.bannerStyle = 'danger';
            this.$page.props.jetstream.flash.banner = err.response.data.message;
        },
        closeModal() {
            this.editing = false;
            this.activeNote = this.$options.data().activeNote;
            this.showAddModifyNoteModal = false;
        },
        editNote(row) {
            this.editing = true;
            this.activeNote = row;
            this.showAddModifyNoteModal = true;
        },
        deleteNote(id) {
            axios.get(
                    route('delete.note', id)
                ).then(res => {
                    this.$page.props.jetstream.flash.banner = res.data.message;
                    this.list.refreshData(this.list.options);
                }).catch(this.defaultCatch);
        },
        submitNoteForm() {
            let data = new FormData(event.target);

            axios.post(
                    route('save.note', this.$page.props.patient_id),
                    data
                ).then(res => {
                    this.$page.props.jetstream.flash.banner = res.data.message;
                    this.list.refreshData(this.list.options);
                    this.closeModal()
                }).catch(this.defaultCatch);
        }
    },
    mounted() {
        this.list.init('fetch_notes', {
            activeSorts: ["created_at"],
            sortTypes: ["date"],
            sortDirs: ["asc"],
            itemsPerPage: 20
        })
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../../scss/_simple-table-styles.scss';
</style>