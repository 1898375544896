<template>
    <TableWrapper
        :list="list"
        style="--cols: 6"
    >
        <div class="st-hidden-sorts">
            <p @click="list.sortBy('company_name')" :class="list.sortClass('company_name')">Company Name</p>
            <p @click="list.sortBy('reg_date', null, 'date')" :class="list.sortClass('reg_date')">Reg Date</p>
        </div>

        <div class="simple-table">
            <p class="first-col st-header" @click="list.sortBy('company_name')" :class="list.sortClass('company_name')">Company Name</p>
            <p class="st-header">VGM #</p>
            <p class="st-header">Fax</p>
            <p class="st-header">Website</p>
            <p class="st-header" @click="list.sortBy('reg_date', null, 'date')" :class="list.sortClass('reg_date')">Reg Date</p>
            <p class="last-col st-header">Actions</p>

            <template v-for="row in list.displayedList" :key="row.id">
                <div class="first-col"><span class="st-hidden">Company Name</span>{{ row.company_name }}</div>
                <div><span class="st-hidden">VGM Number</span>{{ row.vgm_number }}</div>
                <div><span class="st-hidden">Fax</span>{{ row.fax }}</div>
                <div>
                    <span class="st-hidden">Website</span>
                    <div class="st-buttons">
                        <a
                            v-if="row.website"
                            :href="row.website"
                            target="_blank"
                            :title="`Opens ${row.company_name}'s website in a new window`"
                        >{{ row.website }}</a>
                        <template v-else>{{ row.website }}</template>
                    </div>
                </div>
                <div><span class="st-hidden">Reg Date</span>{{ row.reg_date }}</div>
                <div>
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <button class="btn-blue" @click="users(row.id)">Users</button>
                        <button class="btn-blue" @click="edit(row.id)">Edit</button>
                        <button class="btn-outline-gray" @click="remove(row.id)">Remove</button>
                    </div>
                </div>
            </template>
        </div>
    </TableWrapper>
</template>

<script>
import EloquentSSFP from "@/EloquentSSFP";
import { reactive } from 'vue';
import TableWrapper from '@/Components/TableWrapper.vue';

export default {
    components: {
        TableWrapper
    },
    data: function () {
        return {
            list: reactive(new EloquentSSFP()),
        };
    },
    methods: {
        edit(id) {
            this.$inertia.visit(route('company.edit', id))
        },
        remove(id) {
            this.$inertia.visit(route('delete.company', id))
        },
        users(id) {
            this.$inertia.visit(route('manage.company.users', id))
        },
    },
    mounted() {
        this.list.init('/fetch_companies', {
            itemsPerPage: 20,
            searchableData: [
                'company_name',
                'vgm_number',
                'website',
            ]
        })
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../../scss/_simple-table-styles.scss';
</style>
