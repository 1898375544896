<template>
    <AppLayout title="Create Company">
        <div class="loader" v-if="loading"></div>
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},'Create Company']"/>
        </template>

        <Card title="Create Company">
            <div class="buttons">
                <div>
                    <button class="btn-gray" @click.prevent="goBack()">
                        <FontAwesomeIcon class="mr-1" fixed-width :icon="arrowLeft"/> Go Back
                    </button>
                </div>
            </div>

            <div v-if="validate_msg != ''">
                <p class="text-green-500 my-0"><strong> {{validate_msg}}</strong></p>
            </div>
            <div v-if="error_msg != ''">
                <p class="text-red-500 my-0"><strong>  {{error_msg}} </strong></p>
            </div>
            <form enctype="multipart/form-data" ref="form" @submit.prevent="submitForm">
                    <JetLabel for="service_type" value="Service Type" />
                    <div class="flex flex-row">
                        <div class="m-2 flex items-center">
                            <JetCheckbox name="service_type" v-model="form.service_type" id="service_type" value="COPD" />
                            <JetLabel for="service_type" value="COPD" class="p-2" />
                        </div>

                        <div class="m-2 flex items-center">
                            <JetCheckbox name="service_type" v-model="form.service_type" id="mortgagee" value="CPGA" />
                            <JetLabel for="service_type" value="CPGA" class="p-2" />
                        </div>
                        <div class="m-2 flex items-center">
                            <JetCheckbox name="service_type" v-model="form.service_type" id="service_type" value="Functional Mobility Assesment"/>
                            <JetLabel for="service_type" value="Functional Mobility Assesment" class="p-2" />
                        </div>
                    </div>

                <div class="md:flex -mb-2 -mx-2">
                    <div class="m-2 flex-grow">
                        <JetLabel for="company_name" value="Company Name" />
                        <JetInput name="company_name" placeholder="Company Name" type="text" class="mt-1 block w-full" v-model="form.company_name" required />
                    </div>

                    <div class="m-2 flex-grow">
                        <JetLabel for="vgm_number" value="VGM Number" />
                        <JetInput name="vgm_number" placeholder="VGM Number" type="text" class="mt-1 block w-full" v-model="form.vgm_number" required />
                    </div>
                </div>

                <div class="md:flex -mb-2 -mx-2">
                    <div class="m-2 flex-grow">
                        <SimpleDate class="mt-1 block w-full" placeholder="Company Reg Date" label="Reg Date" v-model="form.Reg_date" required />
                    </div>

                    <div class="m-2 flex-grow">
                        <JetLabel for="homelink" value="Home Link" />
                        <JetInput name="homelink" placeholder="Homelink" type="text" class="mt-1 block w-full" v-model="form.homelink" required />
                    </div>
                </div>

                <div class="md:flex -mb-2 -mx-2">
                    <div class="m-2 flex-grow">
                        <JetLabel for="website" value="Company Website" />
                        <JetInput name="website" placeholder="https://website.com" type="text" class="mt-1 block w-full" v-model="form.website" required />
                    </div>

                     <div class="m-2 flex-grow">
                        <JetLabel for="fax" value="Fax" />
                        <JetInput name="fax" placeholder="1234567890" type="text" class="mt-1 block w-full" v-model="form.fax" />
                    </div>
                </div>
                <AddressInputGroup  whose-address="Company"/>

                <div class="flex mt-3 -mx-1 -mb-1">
                    <button
                        type="submit"
                        class="btn-green m-1"
                    >Submit</button>

                    <button @click="cancel"
                        type="button"
                        class="btn-outline-gray m-1"
                    >Cancel</button>

                    <button
                        type="button"
                        class="btn-outline-gray m-1"
                        @click.prevent="resetForm()"
                    >Reset</button>
                </div>
            </form>
        </Card>
    </AppLayout>
</template>

<script>
    import SimpleSelect from '@/Components/FormElements/SimpleSelect.vue'
    import SimpleDate from '@/Components/FormElements/SimpleDate.vue'
    import formProcessingMixin from '@/Mixins/formProcessingMixin.js'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import Card from '@/Components/Card.vue'
    import AddressInputGroup from '@/Components/AddressInputGroup.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faArrowLeft, faUsers } from '@fortawesome/free-solid-svg-icons'

    export default {
        mixins: [formProcessingMixin],
        components: {
            SimpleSelect,
            SimpleDate,
            Breadcrumbs,
            JetCheckbox,
            AppLayout,
            JetLabel,
            JetInput,
            Card,
            AddressInputGroup,
            Link,
            FontAwesomeIcon,
            faArrowLeft
        },
        props: ['data', 'errors'],
        data() {
            return {
                form: {
                    email: null,
                    website: null,
                    service_type: [],
                    vgm_number: null,
                    city: null,
                },
                durationOptions: [
                    {name: '', value: '', selected: true, hidden: 'true'},
                    {name: '30 days', value: 30},
                    {name: '60 days', value: 60},
                    {name: '90 days', value: 90},
                ],
                validate_msg: null,
                error_msg: null,
                loading:false,
                arrowLeft: faArrowLeft,
                user_status_types: [
                    { name: "", value: "", hidden: true },
                    { name: "Active", value: "1" },
                    { name: "Inactive", value: "0" }
                ],
            }
        },
        methods: {
            goBack() {
                window.history.back();
            },
            buttonClasses(view) {
                return `btn-gray${view && this.activeView == view ? ' active' : ''}`;
            },
            cancel: function (e) {
                this.$inertia.visit(route('manage.users'))
            },
            resetForm() {
                this.form.company_name = null;
                this.form.email = null;
                this.form.vgm_number = null;
                this.form.website = null;
                this.form.address1 = null;
                this.form.address2 = null;
                this.form.city = null;
                this.form.state = null;
                this.form.zip = null;
                this.form.fax = null;
            },
            submitForm(event) {
                this.submit(event, 'saveCompany', null, null);
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../scss/_mixins.scss';

    .buttons {
        @include bp-up($bp-lg + 1) { margin: 0 -.5rem 1rem; }
        @include bp-down($bp-lg) {
            margin: 0 -.25rem 1rem;
            flex-wrap: wrap;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
            @include bp-down($bp-lg) {
                flex-wrap: wrap;
                flex-grow: 1;
            }
            display: flex;

            button, a {
                text-align: center;
                @include bp-up($bp-lg + 1) { margin: .5rem; }
                @include bp-down($bp-lg) { margin: .25rem; }
                @include bp-between(451px, $bp-lg) { flex: 1 0 calc(50% - .5rem) }
                @include bp-down(450px) { flex: 1 0 calc(100% - .5rem) }
            }
        }
    }

    .btn-checkboxes {
        display: flex;
        flex-wrap: wrap;
        margin: -.5rem -.5rem 0;

        input {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        label {
            margin: .5rem;
            text-align: center;
        }
    }
</style>
