import FRQuestionThreeDigitZip from '@/Components/FormElements/FRQuestionThreeDigitZip';
import FRQuestionCheckbox from '@/Components/FormElements/FRQuestionCheckbox';
import FRQuestionTextarea from '@/Components/FormElements/FRQuestionTextarea';
import FRQuestionNumeric from '@/Components/FormElements/FRQuestionNumeric';
import FRQuestionSelect from '@/Components/FormElements/FRQuestionSelect';
import FRQuestionEmail from '@/Components/FormElements/FRQuestionEmail';
import FRQuestionRadio from '@/Components/FormElements/FRQuestionRadio';
import FRQuestionDate from '@/Components/FormElements/FRQuestionDate';
import FRQuestionText from '@/Components/FormElements/FRQuestionText';
import FRQuestionYear from '@/Components/FormElements/FRQuestionYear';
import FRQuestionTel from '@/Components/FormElements/FRQuestionTel';

const components = {
    FRQuestionThreeDigitZip,
    FRQuestionCheckbox,
    FRQuestionTextarea,
    FRQuestionNumeric,
    FRQuestionSelect,
    FRQuestionEmail,
    FRQuestionRadio,
    FRQuestionDate,
    FRQuestionText,
    FRQuestionYear,
    FRQuestionTel,
}

export default components;