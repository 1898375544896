<template>
    <AppLayout title="Dashboard">
        <template #breadcrumbs>
            <Breadcrumbs :path="['Dashboard']" />
        </template>

        <div class="card-grid">
            <DashCard
                v-for="(card, cIndex) in cardData"
                :key="cIndex"
                :card-data="card"
                :total="total"
                @on-fetch="addTotal($event)"
            />
        </div>
    </AppLayout>
</template>

<script>
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import DashCard from '@/Components/DashCard.vue';
    import Link from '@inertiajs/inertia-vue3';

    const activeObj       = { name: "Active", color: "green-600", ctaRoute: "active", icon: 'checkCircle', data: "active" };
    const incompleteObj   = { name: "Incomplete", color: "red-600", ctaRoute: "incomplete", icon: 'spinner', data: "incomplete" };
    const inactiveObj     = { name: "Inactive", color: "gray-800", ctaRoute: "inactive", icon: 'timesCircle', data: "inactive" };
    const inTransitionObj = { name: "In Transition", color: "purple", ctaRoute: "in_transition", icon: 'exchangeAlt', data: "intransition" };
    const dueObj          = { name: "Due", color: "green-600", ctaRoute: "due", icon: 'checkCircle', data: "due" };
    const overdueObj      = { name: "Overdue", color: "yellow-600", ctaRoute: "overdue", icon: 'hourglassHalf', data: "overdue" };

    export default {
        components: {
            Breadcrumbs,
            AppLayout,
            DashCard,
        },
        data() {
            return {
                total: 0,
                cardData: [
                    {
                        title: "Intake Time 1",
                        prefix: "time1",
                        dataPoints: [ activeObj, incompleteObj, inactiveObj, inTransitionObj ],
                        endpoint: "/dashboard/time1",
                    },
                    {
                        title: "Delivery",
                        prefix: "delivery",
                        dataPoints: [ dueObj, overdueObj, incompleteObj, inactiveObj, inTransitionObj ],
                        endpoint: "/dashboard/delivery",
                    },
                    {
                        title: "Time 2",
                        prefix: "time2",
                        dataPoints: [ dueObj, overdueObj, incompleteObj, inactiveObj, inTransitionObj ],
                        endpoint: "/dashboard/time2",
                    },
                    {
                        title: "Time 3",
                        prefix: "time3",
                        dataPoints: [ dueObj, overdueObj, incompleteObj, inactiveObj, inTransitionObj ],
                        endpoint: "/dashboard/time3",
                    },
                    {
                        title: "Time 4",
                        prefix: "time4",
                        dataPoints: [ dueObj, overdueObj, incompleteObj, inactiveObj, inTransitionObj ],
                        endpoint: "/dashboard/time4",
                    },
                    {
                        title: "Time 5",
                        prefix: "time5",
                        dataPoints: [ dueObj, overdueObj, incompleteObj, inactiveObj, inTransitionObj ],
                        endpoint: "/dashboard/time5",
                    },
                    {
                        title: "Time 6",
                        prefix: "time6",
                        dataPoints: [ dueObj, overdueObj, incompleteObj, inactiveObj, inTransitionObj ],
                        endpoint: "/dashboard/time6",
                    },
                    {
                        title: "Time 7",
                        prefix: "time7",
                        dataPoints: [ dueObj, overdueObj, incompleteObj, inactiveObj, inTransitionObj ],
                        endpoint: "/dashboard/time7",
                    },
                    {
                        title: "Time 8",
                        prefix: "time8",
                        dataPoints: [ dueObj, overdueObj, incompleteObj, inactiveObj, inTransitionObj ],
                        endpoint: "/dashboard/time8",
                    },
                    {
                        title: "Time 9",
                        prefix: "time9",
                        dataPoints: [ dueObj, overdueObj, incompleteObj, inactiveObj, inTransitionObj ],
                        endpoint: "/dashboard/time9",
                    },
                    {
                        title: "Time 10",
                        prefix: "time10",
                        dataPoints: [ dueObj, overdueObj, incompleteObj, inactiveObj, inTransitionObj ],
                        endpoint: "/dashboard/time10",
                    },
                    {
                        title: "Time 11",
                        prefix: "time11",
                        dataPoints: [ dueObj, overdueObj, incompleteObj, inactiveObj, inTransitionObj ],
                        endpoint: "/dashboard/time11",
                    },
                    {
                        title: "Time 12",
                        prefix: "time12",
                        dataPoints: [ dueObj, overdueObj, incompleteObj, inactiveObj, inTransitionObj ],
                        endpoint: "/dashboard/time12",
                    },
                    {
                        title: "Time 13",
                        prefix: "time13",
                        dataPoints: [ dueObj, overdueObj, incompleteObj, inactiveObj, inTransitionObj ],
                        endpoint: "/dashboard/time13",
                    },
                    {
                        title: "Time 14",
                        prefix: "time14",
                        dataPoints: [ dueObj, overdueObj, incompleteObj, inactiveObj, inTransitionObj ],
                        endpoint: "/dashboard/time14",
                    },
                    {
                        title: "Time 15",
                        prefix: "time15",
                        dataPoints: [ dueObj, overdueObj, incompleteObj, inactiveObj, inTransitionObj ],
                        endpoint: "/dashboard/time15",
                    },
                ]
            }
        },
        computed: {
            userType() {
                return this.$page.props.user.role_type;
            }
        },
        methods: {
            addTotal(value) {
                this.total += value;
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "../../scss/_mixins.scss";

.card-grid {
    --n: 3; /* The maximum number of columns */
    display: grid;
    grid-template-columns: repeat(
        auto-fill,
        minmax(max(330px, 100% / var(--n)), 1fr)
    );

    @include bp-up($bp-sm) {
        grid-template-columns: repeat(
            auto-fill,
            minmax(max(440px, 100% / var(--n)), 1fr)
        );
    }

    .card {
        margin: 1rem 0;

        @include bp-up($bp-sm) {
            margin: 1rem;
        }
    }
}

.due {
    color: var(--green-600);
}
.overdue {
    color: var(--yellow-600);
}
.incomplete {
    color: var(--red-600);
}
.inactive {
    color: var(--gray-800);
}
.inTransition {
    color: var(--purple);
}
.card-num {
    color: var(--font-color);
}
</style>
