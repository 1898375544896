/**
    ToDo:
    - Score is currently pointing at the patient score column
      needs to point at the form submission score, otherwise
      they'll all have the same value. Should be able to be
      updated directly in the FormsResource.php file once the
      column is created and update logic is created.
 */

<template>
	<TableWrapper
        :list="list"
        style="--cols: 4"
	>
		<div class="simple-table">
			<p class="first-col st-header">Date Added</p>
			<p class="st-header">Score</p>
			<p class="st-header">Form Key</p>
			<p class="last-col st-header">Actions</p>

			<template v-for="row in list.displayedList" :key="row.id">
				<div class="first-col"><span class="st-hidden">Date Added</span>{{ row.created_at }}</div>
				<div><span class="st-hidden">Score</span>{{ row.score }}</div>
				<div><span class="st-hidden">Form Key</span>{{ row.key }}</div>
				<div>
                    <span class="st-hidden">Actions</span>
					<div class="st-buttons">
                        <button class="btn-blue" @click="edit(row.guid)">Edit</button>
                        <a class="btn btn-blue" target="_blank" :href="route('view_form_and_answers', row.guid)">View/Print</a>
                        <button v-if="['FMADEMOINTAKE', 'FMAPREFOLLOWUP', 'FMAFOLLOWUP'].includes(row.key)" class="btn-blue" @click="addSurvey(row.guid, row.patients_guid, row.family_centric)">Add Survey</button>

					</div>
				</div>
			</template>
		</div>
	</TableWrapper>
</template>

<script>
import EloquentSSFP from "@/EloquentSSFP";
import { reactive } from 'vue';
import TableWrapper from '@/Components/TableWrapper.vue';

export default {
    name: "HistoryTable",
    components: {
        TableWrapper
    },
	data: function () {
		return {
			list: reactive(new EloquentSSFP()),
		};
	},
    methods: {
        edit(guid) {
            this.$inertia.visit(route('get_form_and_answers', guid))
        },
        addSurvey(formGuid, patientsGuid, familyCentric) {
            let formKeyGuid = familyCentric ? '46d5f705-c9d2-7f69-nee8-2g8gd319b48d' : '35c4e694-d469-4c68-bee9-1f7fc219a37b';
            let params =  {
                guid               : formKeyGuid,
                patients_guid      : patientsGuid,
                previous_form_guid : formGuid
            }
            this.$inertia.visit(route('getFormsByGuid', params));
        }
    },
    mounted() {
        this.list.init('fetch_history', {
            activeSorts : ["created_at"],
            sortTypes   : ["date"],
            sortDirs    : ["asc"],
            itemsPerPage: 20
        })
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../../scss/_simple-table-styles.scss';
</style>
