<template>
    <DetailsLayout
        :name="'Delivery & Follow-Up Options'"
        :data="data"
    >
        <div class="section">
            <div class="flex flex-wrap items-center justify-between">
                <div class="questions" :class="{loading: loading}">
                    <FRQuestionRadio
                        :question="{
                            question: 'Confirm Delivery Complete',
                            required: 0,
                            options: [1,2]
                        }"
                        question-id="delivery_complete"
                        :options="{
                            '1': { value: 'No' },
                            '2': { value: 'Yes' }
                        }"
                        @input="savePatient()"
                        v-model="completeModel"
                    />
                    <FRQuestionDate
                        v-if="completeModel == 1"
                        :question="{
                            question: 'Date of Next Call',
                            required: 0,
                        }"
                        @input="savePatient()"
                        question-id="delivery_complete_date"
                        v-model="completeDateModel"
                    />
                </div>
                <template v-if="completeModel == 2">
                    <Link
                        v-if="!deliveryForm"
                        class="btn btn-blue flex-grow-0"
                        :href="route('getFormsByGuid', {'guid': '67aff92c-383b-447d-8e3b-9b6f5d8e6871', 'patients_guid': data.guid})"
                    >Fill Out Delivery Form</Link>

                    <Link
                        v-else
                        class="btn btn-blue flex-grow-0"
                        :href="route('get_form_and_answers', deliveryForm.guid)"
                    >Update Delivery Form</Link>
                </template>
            </div>
        </div>

        <div class="section" v-if="!!deliveryForm">
            <h6>Follow-Up Forms</h6>

            <div class="follow-up-cards">
                <div class="follow-up-card" v-for="(form, index) in followUpForms" :key="`follow_up_${index}`">
                    <div class="status-items">
                        <div class="status-item">
                            <p class="title">Follow-Up Type</p>
                            <p class="value">{{ form.type }}</p>
                        </div>
                        <div class="status-item">
                            <p class="title">Date Added</p>
                            <p class="value">{{ form.date }}</p>
                        </div>
                        <div class="status-item">
                            <p class="title">Form Completed</p>
                            <p class="value">{{ form.completed }}</p>
                        </div>
                    </div>
                    <Link
                        class="btn btn-blue flex-grow-0"
                        :href="route('get_form_and_answers', form.guid)"
                    >Update Follow-Up Form</Link>
                </div>
            </div>

            <div class="flex justify-end">
                <Link
                    class="btn btn-blue flex-grow-0"
                    :href="route('getFormsByGuid', {'guid': 'ebe065ad-a3d6-448a-90bd-326e4f55b9ce', 'patients_guid': data.guid})"
                >Fill Out Follow-Up Form</Link>
            </div>
        </div>

    </DetailsLayout>
</template>

<script>
    import { Link } from '@inertiajs/inertia-vue3';
    import FRQuestionRadio from '../../../Components/FormElements/FRQuestionRadio.vue';
    import FRQuestionDate from '../../../Components/FormElements/FRQuestionDate.vue';
    import DetailsLayout from '../Layouts/DetailsLayout.vue';

    export default {
        name: "DeliveryAndFollowUpOptions",
        components: {
            DetailsLayout,
            FRQuestionRadio,
            FRQuestionDate,
            Link
        },
        props: ["data", "followUpForms", "deliveryForm"],
        data() {
            return {
                completeModel: "",
                completeDateModel: "",
                loading: false
            }
        },
        methods: {
            savePatient() {
                this.loading = true;
                let vm = this;
                setTimeout(function() {
                    let complete = false;
                    if (vm.completeModel == 2) complete = true;
                    let obj = { guid: vm.data.guid, confirm_delivery_complete: complete };
                    if (vm.completeDateModel) obj['date_of_next_call'] = vm.completeDateModel;

                    // As the inputs are already what they need to be, no need to do anything on .then()
                    axios.post(route('patients.save'), obj)
                        .catch(err => console.error(err))
                        .finally(() => vm.loading = false)
                }, 0)
            }
        },
        mounted() {
            this.completeModel = this.data.confirm_delivery_complete ? "2" : "1";
            if (this.data.date_of_next_call) {
                this.completeDateModel = this.data.date_of_next_call;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../scss/_mixins.scss';

    .section {
        padding-top: 2rem;

        + .section {
            margin-top: 2rem;
            border-top: 1px solid hsl(0deg, 0%, 65%);
        }
    }

    .questions {
        display: flex;
        flex-wrap: wrap;
        margin: -.5rem -1rem;

        &.loading {
            pointer-events: none;
            opacity: .5;
        }

        > * {
            padding: .5rem 1rem;
        }
    }

    .follow-up-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        flex-wrap: wrap;

        + .follow-up-card {
            border-top: 1px solid hsl(0deg, 0%, 65%);
        }

        .status-items {
            display: flex;
            align-items: center;
            flex: 1 0 min(100%, 400px);
            flex-wrap: wrap;
            margin: -.5rem;

            .status-item {
                flex: 1 0 150px;
                margin: .5rem;

                .title {
                    font-size: 14px;
                    margin-bottom: .25rem;
                    width: max-content;
                    line-height: 1.1;
                }

                .value {
                    font-size: 20px;
                    font-weight: bold;
                    color: var(--blue-500);
                }
            }
        }
    }
</style>
