<template>
    <Link :href="'/'">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="225" height="100" viewBox="0 0 410 105">
            <image id="logo" width="400" height="97" xlink:href="/images/logo.png"/>
        </svg>
    </Link>
</template>

<script>
    import { Link } from '@inertiajs/inertia-vue3';

    export default {
        components: {
            Link,
        },
    }
</script>
