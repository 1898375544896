<template>
    <DetailsLayout
        :name="'Details'"
        :data="data"
    >
        <div class="readonly-details">
            <div class="item-wrapper">
                <div class="item">
                    <p class="heading">Patient Name</p>
                    <p class="value">{{ data.full_name }}</p>
                </div>
            </div>
            <div class="item-wrapper">
                <div class="item">
                    <p class="heading">Patient RTS Code</p>
                    <p class="value">{{ data.rts_code }}</p> <!-- might need to be changed, not sure what this value is -->
                </div>
            </div>
            <div class="item-wrapper">
                <div class="item">
                    <p class="heading">ATP Name/Number</p>
                    <p class="value">{{ data.atp_number }}</p> <!-- might need to be changed, not sure what this value is -->
                </div>
            </div>
            <div class="item-wrapper">
                <div class="item">
                    <p class="heading">Equipment</p>
                    <p class="value">{{ data.equipment }}</p>
                </div>
            </div>
        </div>

        <form @submit.prevent="onSubmit()" ref="form" class="mb-8 border-b border-gray-200 pb-8">
            <p class="success-message" v-if="successMessage">
                {{ successMessage }}
            </p>
            <p class="error-message" v-if="errorMessage">
                {{ errorMessage }}
            </p>

            <div class="section">
                <h5>Patient Communication Settings</h5>
                <div class="questions">
                    <div class="question" style="flex-basis:20%;">
                        <div class="fr-group">
                            <label><input v-model="data.notificationsValue" :checked="data.notifications == 'Home'" type="radio" name="notifications" value="Home" /> Home Phone</label>
                        </div>
                    </div>
                    <div class="question" style="flex-basis:20%;">
                        <div class="fr-group">
                            <label><input v-model="data.notificationsValue" :checked="data.notifications == 'Cell'" type="radio" name="notifications" value="Cell" /> Mobile Phone</label>
                        </div>
                    </div>
                    <div class="question" style="flex-basis:20%;">
                        <div class="fr-group">
                            <label><input v-model="data.notificationsValue" :checked="data.notifications == 'SMS'" type="radio" name="notifications" value="SMS" /> SMS/Text</label>
                        </div>
                    </div>
                    <div class="question" style="flex-basis:20%;">
                        <div class="fr-group">
                            <label><input v-model="data.notificationsValue" :checked="data.notifications == 'Email'" type="radio" name="notifications" value="Email" /> Email</label>
                        </div>
                    </div>
                    <div class="question" style="flex-basis:20%;">
                        <div class="fr-group">
                            <label><input v-model="data.notificationsValue" :checked="data.notifications == 'Postal'" type="radio" name="notifications" value="Postal" /> US Postal</label>
                        </div>
                    </div>
                    <div class="question" style="flex-basis:50%;">
                        <FRQuestionTel
                            :question="{
                                question:'Cell Phone',
                                required:0,
                                type:'tel',
                                flexBasis:50,
                                initialValue:'',
                            }"
                            v-model="data.cell_phone"
                        />
                    </div>
                    <div class="question" style="flex-basis:50%;" v-if="data.notifications == 'SMS' || data.notifications == 'Email'">
                        <button class="btn-blue" style="margin-top: 19px;" @click="sendFollowupNotification()">Send Follow Up Notification</button>
                    </div>
                </div>
            </div>
            <div class="section" role="group" :aria-labelledby="`${section.prefix}`" v-for="section in sections" :key="section.title">
                <h5 :id="`${section.prefix}`">{{ section.title }}</h5>

                <div class="questions">
                    <div
                        class="question"
                        v-for="(id) in section.questions"
                        :key="`${section.prefix}_${id}`"
                        :style="{flexBasis: `${questions[id].flexBasis}%`}"
                    >
                        <component
                            :is="`f-r-question-${questions[id].type}`"
                            :question="questions[id]"
                            :question-id="id"
                            :options="questions[id].optionsInQuestion"
                            :autocomplete="`${section.prefix} ${questions[id].autocomplete}`"
                            v-model="questions[id].initialValue"
                        />
                    </div>
                </div>
            </div>

            <div class="flex justify-end">
                <button class="btn btn-blue text-white flex-grow-0">Submit</button>
            </div>
        </form>


        <h5 id="#documents">Patient Documents</h5>
        <patient-documents
            :guid="data.guid"
        />
    </DetailsLayout>
</template>

<script>
    import FRQuestionText from '@/Components/FormElements/FRQuestionText';
    import FRQuestionTel from '@/Components/FormElements/FRQuestionTel';
    import FRQuestionEmail from '@/Components/FormElements/FRQuestionEmail';
    import FRQuestionDate from '@/Components/FormElements/FRQuestionDate';
    import FRQuestionCheckbox from '@/Components/FormElements/FRQuestionCheckbox';
    import FRQuestionRadio from '@/Components/FormElements/FRQuestionRadio';
    import FRQuestionSelect from '@/Components/FormElements/FRQuestionSelect';
    import DetailsLayout from '../Layouts/DetailsLayout.vue';
    import usStatesMixin from '../../../Mixins/usStatesMixin';
    import formattingMixin from '../../../Mixins/formattingMixin';
    import PatientDocuments from '../Partials/PatientDocuments';

    const emailSettings  = { question: "Email",     required: 0, type: "email",  flexBasis: 50 },
        phoneSettings    = { question: "Phone",     required: 0, type: "tel",    flexBasis: 50 },
        address1Settings = { question: "Address 1", required: 0, type: "text",   flexBasis: 100 },
        address2Settings = { question: "Address 2", required: 0, type: "text",   flexBasis: 100 },
        citySettings     = { question: "City",      required: 0, type: "text",   flexBasis: (100/3) },
        stateSettings    = { question: "State",     required: 0, type: "select", flexBasis: (100/3), options: usStatesMixin.computed.usStatesGlossary(), optionsInQuestion: usStatesMixin.computed.usStatesObject() },
        zipSettings      = { question: "Zip Code",  required: 0, type: "text",   flexBasis: (100/3) };

    export default {
        name: "Details",
        mixins: [formattingMixin],
        components: {
            DetailsLayout,
            FRQuestionText,
            FRQuestionTel,
            FRQuestionEmail,
            FRQuestionDate,
            FRQuestionCheckbox,
            FRQuestionRadio,
            FRQuestionSelect,
            PatientDocuments,
        },
        props: ["data"],
        data() {
            return {
                successMessage: "",
                errorMessage: "",
                notificationsValue: this.data.notifications,

                // Patient Communication Settings
                cell_phone:         { ...phoneSettings, initialValue: this.data.cell_phone || ''},
                questions: {
                    

                    // Patient Contact Info
                    email:              { ...emailSettings, initialValue: this.data.email || '' },
                    phone:              { ...phoneSettings, initialValue: this.data.phone || '' },
                    address1:           { ...address1Settings, initialValue: this.data.address1 || '' },
                    address2:           { ...address2Settings, initialValue: this.data.address2 || '' },
                    city:               { ...citySettings, initialValue: this.data.city || '' },
                    state:              { ...stateSettings, initialValue: this.data.state || '' },
                    zip:                { ...zipSettings, initialValue: this.data.zip || '' },
                    // Secondary Contact Info
                    secondary_email:    { ...emailSettings, initialValue: this.data.secondary_email || '' },
                    secondary_phone:    { ...phoneSettings, initialValue: this.data.secondary_phone || '' },
                    secondary_address1: { ...address1Settings, initialValue: this.data.secondary_address1 || '' },
                    secondary_address2: { ...address2Settings, initialValue: this.data.secondary_address2 || '' },
                    secondary_city:     { ...citySettings, initialValue: this.data.secondary_city || '' },
                    secondary_state:    { ...stateSettings, initialValue: this.data.secondary_state || '' },
                    secondary_zip:      { ...zipSettings, initialValue: this.data.secondary_zip || '' },
                    // Caregiver Contact Info
                    caregiver_email:    { ...emailSettings, initialValue: this.data.caregiver_email || '' },
                    caregiver_phone:    { ...phoneSettings, initialValue: this.data.caregiver_phone || '' },
                    caregiver_address1: { ...address1Settings, initialValue: this.data.caregiver_address1 || '' },
                    caregiver_address2: { ...address2Settings, initialValue: this.data.caregiver_address2 || '' },
                    caregiver_city:     { ...citySettings, initialValue: this.data.caregiver_city || '' },
                    caregiver_state:    { ...stateSettings, initialValue: this.data.caregiver_state || '' },
                    caregiver_zip:      { ...zipSettings, initialValue: this.data.caregiver_zip || '' },
                }
            }
        },
        computed: {
            sections() {
                return [
                    {
                        title: "Patient Contact Information",
                        prefix: "patient_contact",
                        questions: [
                            'email',
                            'phone',
                            'address1',
                            'address2',
                            'city',
                            'state',
                            'zip'
                        ]
                    },
                    {
                        title: "Secondary Contact Information",
                        prefix: "secondary_contact",
                        questions: [
                            'secondary_email',
                            'secondary_phone',
                            'secondary_address1',
                            'secondary_address2',
                            'secondary_city',
                            'secondary_state',
                            'secondary_zip'
                        ]
                    },
                    {
                        title: "Caregiver Contact Information",
                        prefix: "caregiver_contact",
                        questions: [
                            'caregiver_email',
                            'caregiver_phone',
                            'caregiver_address1',
                            'caregiver_address2',
                            'caregiver_city',
                            'caregiver_state',
                            'caregiver_zip'
                        ]
                    },
                ]
            }
        },
        methods: {
            onSubmit() {
                let data = new FormData(event.target),
                    vm = this,
                    resData  = {};

                data.append('guid', this.data.guid);
                Array.from(data.entries()).forEach(([key, value]) => {
                    resData[key] = value;
                })
                resData['notifications'] = this.data.notificationsValue;
                resData['cell_phone'] = this.data.cell_phone;

                axios.post(route('patients.save'), resData)
                    .then(res => {
                        vm.setMessage('success', res.data.message);
                    })
                    .catch(err => {
                        console.error(err);
                    })
                    .finally(() => {
                        // Scroll up to the top of the form where the success message is
                        let contentSection = document.querySelector('.content-section');
                        contentSection.scrollBy({
                            top:      vm.$refs.form.getBoundingClientRect().top - contentSection.getBoundingClientRect().top - 32,
                            behavior: 'smooth'
                        });
                    })

            },
            setMessage(type, message) {
                this[`${type}Message`] = message;
                document.querySelector('.app-wrapper').scrollTop = 0;
                setTimeout(function() {
                    this[`${type}Message`] = ""
                }, 7000);
            },
            sendFollowupNotification() {
                axios.post(route('patients.notify'), this.data)
                    .then(res => {
                        vm.setMessage('success', res.data.message);
                    })
                    .catch(err => {
                        console.error(err);
                    })
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../../../scss/_mixins.scss';

    .success-message {
        padding: 1rem;
        margin: 1rem 0;
        background-color: var(--green-100);
        color: var(--green-900);
    }

    .error-message {
        padding: 1rem;
        margin: 1rem 0;
        background-color: var(--red-100);
        color: var(--red-900);
    }

    .readonly-details {
        margin: 2rem 0;
        display: flex;
        flex-wrap: wrap;
        gap: 1px;
        background-color: rgba(0,0,0,0.3);

        > * {
            flex: 1 0 0;
            padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
        }

        .item {
            width: max-content;
        }

        .heading {
            font-size: 14px;
            margin-bottom: .25rem;
        }

        .value {
            font-size: 20px;
            font-weight: bold;
            color: var(--blue-500);
        }
    }

    form {
        .questions {
            display: flex;
            flex-wrap: wrap;
            margin: .5rem -.5rem;

            .question {
                padding: .5rem;
                flex-grow: 1;

                @include mobile {
                    flex-basis: 100% !important;
                }
            }
        }

        .section {
            margin: 2rem 0;
        }
    }
</style>
