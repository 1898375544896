<template>
	<TableWrapper
        :list="list"
        style="--cols: 6"
	>
		<div class="st-hidden-sorts">
			<p @click="list.sortBy([['first_name'],['last_name']])" :class="list.sortClass('first_name')">Name</p>
			<p @click="list.sortBy('year_of_birth', null, 'number')" :class="list.sortClass('year_of_birth')">Year of Birth</p>
		</div>

		<div class="simple-table">
			<p class="first-col st-header">ATP Number</p>
			<p class="st-header" @click="list.sortBy([['first_name'],['last_name']])" :class="list.sortClass('first_name')">Name</p>
			<p class="st-header" @click="list.sortBy('year_of_birth', null, 'number')" :class="list.sortClass('year_of_birth')">Year of Birth</p>
			<p class="st-header">Acct #</p>
			<p class="st-header">Email</p>
			<p class="last-col st-header">Phone</p>

			<template v-for="row in list.displayedList" :key="row.id">
				<div class="first-col"><span class="st-hidden">ATP Number</span>{{ row.atp_number }}</div>
				<div>
                    <span class="st-hidden">Name</span>
                    <div class="st-buttons">
                        <a
                            :href="`/patient/${row.id}/details`"
                            :title="`Internal link to ${row.full_name}'s details page in same window`"
                        >{{ row.full_name }}</a>
                    </div>
                </div>
				<div><span class="st-hidden">Year of Birth</span>{{ row.year_of_birth }}</div>
				<div><span class="st-hidden">Acct #</span>{{ row.patient_account_number }}</div>
				<div>
                    <span class="st-hidden">Email</span>
                    <div class="st-buttons">
                        <a
                            v-if="row.email"
                            :href="`mailto:${row.email}`"
                            :title="`Starts email conversation with ${row.full_name} in your default email client`"
                        >{{ row.email }}</a>
                        <template v-else>{{ row.email }}</template>
                    </div>
                </div>
				<div>
                    <span class="st-hidden">Phone</span>
                    <div class="st-buttons">
                        <a
                            v-if="row.phone"
                            :href="`tel:${row.phone.replaceAll(/\D/g,'')}`"
                            :title="`Opens ${row.full_name}'s number in your default calling application`"
                        >{{ row.phone }}</a>
                        <template v-else>{{ row.phone }}</template>
                    </div>
                </div>
			</template>
		</div>
	</TableWrapper>
</template>

<script>
import EloquentSSFP from "@/EloquentSSFP";
import { reactive } from 'vue';
import TableWrapper from '@/Components/TableWrapper.vue';

export default {
    name: "ExceptionsTable",
    components: {
        TableWrapper
    },
	data: function () {
		return {
			list: reactive(new EloquentSSFP()),
		};
	},
    methods: {
        view(id) {
            this.$inertia.visit(route('patient.details', id));
        },
    },
    mounted() {
        this.list.init(`/fetch_patients_by_atps/${this.$page.props.atp_number}`, {
            itemsPerPage: 20,
            searchableData: [
                "atp_number",
                "first_name",
                "last_name",
                "email",
                "patient_account_number"
            ]
        })
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../../scss/_simple-table-styles.scss';
    .simple-table {
        --custom-col-structure: repeat(var(--cols), 1fr);
    }
</style>