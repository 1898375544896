<template>
    <ManageLayout
        :name="`Users${activeCompany.company_name ? ' - ' + activeCompany.company_name : ''}`"
    >
        <UsersTable />
    </ManageLayout>
</template>

<script>
    import { reactive } from 'vue';
    import UsersTable from '@/Pages/Manage/Tables/UsersTable.vue'
    import ManageLayout from '@/Pages/Manage/Layouts/ManageLayout.vue'
    import Card from '@/Components/Card.vue'

    export default {
        components: {
            UsersTable,
            ManageLayout,
            Card
        },
        data() {
            return {
                activeCompany: reactive({})
            }
        },
        computed: {
            activeCompanyId() {
                let parts = this.$page.url.split("/");
                return parts.length > 3 ? Number(parts[3]) : false;
            }
        },
        methods: {
            fetchActiveCompany() {
                if (!this.activeCompanyId) return;
                axios.get(`/fetch_company/${this.activeCompanyId}`)
                    .then(res => this.activeCompany = res.data.data)
                    .catch(err => console.error(err));
            },
            deleteUser(data) {
                if (!confirm('Are you sure want to remove?')) return;
                let vm = this;
                this.loading = true;
                axios.get(route('delete.user',data.id))
                    .then(response => {
                        vm.$page.props.jetstream.flash.banner = response.data.message;
                        vm.loading =  false,
                        vm.fetchCompanyUsers();
                    })
            },
            deleteUsers() {
              if (confirm("Do you really want to delete all users?")){
                let vm = this;
                axios
                    .get(route('delete.users'))
                    .then(response => {
                        vm.$page.props.jetstream.flash.banner = response.data.message;
                        vm.loading =  false,
                        vm.fetchUsers();
                    })
                }
            },
        },
        mounted() {
            this.fetchActiveCompany()
        }
    }
</script>
