<template>
    <div class="patient-docs">
        <div class="documents">
            <div class="document"
                v-for="document in documents"
                :key="document.document_path"
            >
                <button
                    class="file-name"
                    @click="fetchDocument(document.document_path, document.document_name)"
                    :title="`Opens ${document.document_name} in a new tab`"
                    target="_blank"
                >
                    <FontAwesomeIcon :icon="icons.file" />
                    {{ document.document_name }}
                </button>
                <button
                    class="btn-red m-0 flex-grow-0 w-auto"
                    @click.prevent="deleteDocument(document.id)"
                    :title="`Delete ${document.document_name} from this patient`"
                ><FontAwesomeIcon :icon="icons.trashAlt" /></button>
            </div>
        </div>
        <form class="" @submit.prevent="submitFiles()" ref="form">
            <div class="form-group files text-center">
                <SimpleFileUploader
                    multiple="multiple"
                    accept=".pdf,.jpg,.png,.tiff,.xlsx,.xls,.csv,.docx"
                    v-model="form.files"
                    name="files[]"
                >
                    <button v-if="form.files.length" class="btn-blue" :title="`Upload the added file${form.files.length>1?'s':''}`">Upload</button>
                </SimpleFileUploader>
            </div>
        </form>
    </div>
</template>

<script>
    import { ref } from 'vue'
    import moment from 'moment'
    import JetInput from '@/Jetstream/Input.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import { faFile, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
    import SimpleFileUploader from '@/Components/FormElements/SimpleFileUploader.vue';

    export default {
        components: {
            moment,
            JetInput,
            JetLabel,
            JetButton,
            JetInputError,
            JetFormSection,
            FontAwesomeIcon,
            JetSecondaryButton,
            SimpleFileUploader,
        },
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'POST',
                    files: ref([]),
                }),
                documents: [],
                messages: [],
                processing: false,
                buttonText: "Submit File(s)",
                icons: {
                    file: faFile,
                    trashAlt: faTrashAlt
                }
            }
        },
        props: {
            guid: String,
        },
        methods: {
            fetchFiles() {
                axios({
                    method: 'post',
                    url: route('patients.fetch_documents'),
                    data: {
                        guid: this.guid
                    }
                })
                .then(response => {
                    if (response.data) {
                        this.documents = response.data;
                    }
                })
            },
            submitFiles() {
                this.toggleButtonAndClearMessages();
                let vm = this;
                let formData = new FormData(this.$refs.form);

                formData.append('guid', this.guid)

                axios({
                    method: 'post',
                    url: route('patients.upload_documents'),
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: formData
                })
                .then(response => {
                    if (response.data) {
                        vm.$page.props.jetstream.flash.bannerStyle = response.data.result === 'error' ? 'danger' : '';
                        vm.$page.props.jetstream.flash.banner = response.data.message;
                        vm.fetchFiles();
                        vm.toggleButtonAndClearMessages();
                        vm.form.files.splice(0);
                    } else {
                        vm.$page.props.jetstream.flash.bannerStyle = response.data.result = 'error';
                        vm.$page.props.jetstream.flash.banner = 'Something went wrong!';
                    }
                })
                .catch(function (error) {
                    // Lets check on the status...
                })
            },
            toggleButtonAndClearMessages() {
                if (!this.processing)
                {
                    this.processing = true;
                    this.buttonText = "Processing...";
                    this.messages = [];
                }
                else
                {
                    this.processing = false;
                    this.buttonText = "Submit File(s)";
                }
            },
            fetchDocument(path, filename) {
                axios({
                    method: 'post',
                    url: route('patients.fetch_document'),
                    data: {'path': path, 'filename': filename}
                })
                .then(response => {
                    const link = document.createElement("a");
                    link.href = response.data;
                    link.download = 'test1';
                    link.click();
                    link.remove();
                })
                .catch(error => {
                    console.error(error);
                });
            },
            deleteDocument(id) {
                let vm = this;
                axios({
                    method: 'post',
                    url: route('patients.delete_document'),
                    data: {'id': id}
                })
                .then(response => {
                    vm.$page.props.jetstream.flash.bannerStyle = response.data.result === 'error' ? 'danger' : '';
                    vm.$page.props.jetstream.flash.banner = response.data.message;
                    vm.fetchFiles();
                })
                .catch(error => {
                    console.error(error);
                });
            }
        },
        mounted() {
            this.fetchFiles();
        }
    }
</script>

<style lang="scss" scoped>
    .patient-docs {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1rem;
    }

    .documents {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: .5rem;

        .document {
            position: relative;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            gap: .5rem;
            padding: .5rem;
            box-shadow: var(--global-box-shadow);
            border-radius: 3px;

            .file-name {
                flex-grow: 1;
                display: flex;
                align-items: center;
                gap: .5rem;
                color: var(--blue-500);
                text-decoration: underline;
                padding: .25rem .75rem;

                &:hover { text-decoration: none; }
                svg { font-size: 18px; }
            }
        }
    }
</style>
