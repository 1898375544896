<template>
    <AppLayout :title="`Manage ${name}`">
        <div class="wrapper">
            <div class="title-wrapper">
                <h4>Manage {{ name }}</h4>
            </div>
            <div class="subnav-btns">
                <div>
                    <Link
                        class="btn btn-filters"
                        :class="currentClass('Users')"
                        :href="route('manage.users')"
                    >Manage Users</Link>

                    <Link
                        class="btn btn-filters"
                        :class="currentClass('Companies')"
                        :href="route('manage.companies')"
                    >Manage Companies</Link>

                    <Link
                        class="btn btn-filters"
                        :class="currentClass('PendingInvitations')"
                        :href="route('manage.pending_invitations')"
                    >Pending Invitations</Link>
                </div>
                <div>
                    <Link
                        v-if="isCurrentPage('Users')"
                        class="btn-blue"
                        :href="route('users.create')"
                    >Create User</Link>

                    <Link
                        v-if="isCurrentPage('Companies')"
                        class="btn-blue"
                        :href="route('company.create')"
                    >Create Company</Link>
                </div>
            </div>

            <slot></slot>
        </div>
    </AppLayout>
</template>

<script>
    import AppLayout from '@/Layouts/AppLayout.vue';
    import { Link } from '@inertiajs/inertia-vue3';

    export default {
        props: ["name"],
        components: {
            AppLayout,
            Link,
        },
        methods: {
            isCurrentPage(name) {
                return this.$page.component === `Manage/${name}`;
            },
            currentClass(name) {
                return {active: this.isCurrentPage(name)};
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../scss/_mixins.scss';

    .wrapper {
        @include fluid-size(16px, 48px, padding);
        background-color: white;
    }

    .subnav-btns {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 1rem;
        align-items: center;
        margin: 1.75rem 0;

        > div {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: .25rem;
        }

        button, a {
            margin: 0;
            flex-grow: 0;
        }
    }

    .title-wrapper {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        h4 {
            display: block;
            flex-grow: 1;
        }
    }
</style>
