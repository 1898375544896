import moment from 'moment'

export default {
    props: {
        data: {
            type: Array,
            required: true
        },
        cols: {
            type: Number,
            required: true
        },
        searchableData: {
            type: Array,
            default: []
        },
        searchPlaceholder: {
            type: String,
            default: 'Search...'
        },
        itemsPerPage: {
            type: Number,
            default: 10
        },
        respondAt: {
            type: String,
            default: "sm"
        },
    },
    data() {
        return {
            searchModel: '',
            activeSort: null,
            sortDir: 'asc',
            sortType: 'string',
            sortCombo: null,
            activePage: 1,
        }
    },
    methods: {
        // To be attached to any element via click event, e.g. @click="sortBy('id', 'number')"
        // combined values should be passed as an object whose key will be used as the activeSort
        // and whose value is an array of strings to be concattenated and used for the actual sorting
        //
        // EXAMPLE: @click="sortBy({'fullName': ['first_name','last_name']})" :class="sortClass('fullName')"
        sortBy(value, type = 'string') {
            // determine if value is just a string
            let normalValue = typeof value === 'string',
                key, valArr;

            // if not, establish variables for the passed sortCombo
            if (!normalValue) [key,valArr] = (Object.entries(value)[0]);

            let sameAsCurrentActive = this.activeSort == (normalValue ? value : key),
                oppositeSortDir = this.sortDir == 'asc' ? 'desc' : 'asc';

            // go to page 1 so you aren't on the last page when you sort and see the end of the list
            if (this.activePage !== 1) this.activePage = 1;
            this.sortCombo = normalValue ? null : valArr;
            this.sortDir = sameAsCurrentActive ? oppositeSortDir : 'asc' ;
            this.sortType = type;
            this.activeSort = normalValue ? value : key;
        },
        // To be attached to a bound class attribute whenever the sortBy() method is used, e.g. :class="sortClass('id')"
        sortClass(value) {
            return `sort${value == this.activeSort ? ' ' + this.sortDir : ''}`;
        },
        updateSearch(value) {
            this.searchModel = value;
            this.activePage = 1;
        },
        updateActivePage(page) {
            this.activePage = page;
        }
    },
    watch: {
        // This is here specifically to check for issues when injecting/removing items from the table
        // This can all go away if we just refetch on add/modify/delete rather than do a bunch of stuff to do it visually
        itemCount(n) {
            let maxPage = Math.ceil(n/this.itemsPerPage);
            if (maxPage < this.activePage) this.updateActivePage(maxPage);
        }
    },
    computed: {
        wrapperBinder() {
            let onePage = Math.ceil(this.itemCount / this.itemsPerPage) === 1 ? ' one-page' : '',
                oneItem = this.itemCount === 1 ? ' one-item' : '';

            return {
                itemCount: this.itemCount,
                activePage: this.activePage,
                itemsPerPage: this.itemsPerPage,
                searchable: !!this.searchableData.length,
                searchPlaceholder: this.searchPlaceholder,
                class: `simple-table-wrapper simple-table-wrapper-${this.respondAt}${onePage}${oneItem}`,
                style: `--cols:${this.cols}`,
            }
        },
        wrapperEvents() {
            return {
                'update-page': this.updateActivePage,
                'on-search': this.updateSearch
            }
        },
        searchedList() {
            if (!this.searchModel || !this.searchableData.length) return this.data;

            return this.data.filter(d => {
                return this.searchableData.some(sd => {
                    if (d[sd]) {
                        return d[sd].toLowerCase().includes(this.searchModel.toLowerCase())
                    } else {
                        console.error(sd + ' cannot be found in the provided data.\nThe available data points are: ' + Object.keys(d) + '\nBe sure to check your spelling as data points are case sensitive');
                        return false
                    }
                })
            })
        },
        sortedList() {
            if (!this.activeSort) return this.searchedList;
            let searchedClone = [...this.searchedList]

            searchedClone.sort((a,b) => {
                let asc = this.sortDir == 'asc';
                if (this.sortCombo) {
                    a = this.sortCombo.reduce((acc,cur) => acc+a[cur],'');
                    b = this.sortCombo.reduce((acc,cur) => acc+b[cur],'');
                } else {
                    a = a[this.activeSort];
                    b = b[this.activeSort];
                }

                const ascDesc = (a,b) => asc?a-b:b-a;

                switch (this.sortType) {
                    case 'number':
                        return ascDesc(Number(a), Number(b))
                    case 'date':
                        return ascDesc(moment(a).format('YYYYMMDD'), moment(b).format('YYYYMMDD'));
                    default: // string
                        return asc ? (a.toLowerCase()).localeCompare(b.toLowerCase()) : (b.toLowerCase()).localeCompare(a.toLowerCase());
                }
            });

            return searchedClone;
        },
        // To be used as the looped data, e.g. v-for="(row,index) in displayedList"
        displayedList() { // handles pagination
            return this.sortedList.reduce((a,c,i) => {
                if (i >= (this.activePage - 1) * this.itemsPerPage && i < this.activePage * this.itemsPerPage) a.push({...c});
                return a;
            }, []);
        },
        itemCount() { // gets amount of rows before pagination
            return this.sortedList.length;
        }
    }
}