<template>
	<TableWrapper
        :list="formList"
        style="--cols: 3"
	>
		<div class="st-hidden-sorts">
			<p @click="formList.sortBy('key')" :class="formList.sortClass('key')">Key</p>
			<p @click="formList.sortBy('title')" :class="formList.sortClass('title')">Name</p>
		</div>

		<div class="simple-table">
			<p class="first-col st-header" @click="formList.sortBy('key')" :class="formList.sortClass('key')">Key</p>
			<p class="st-header" @click="formList.sortBy('title')" :class="formList.sortClass('title')">Name</p>
			<p class="last-col st-header">Options</p>

			<template v-for="row in formList.displayedList" :key="row.id">
				<div class="first-col"><span class="st-hidden">Key</span>{{ row.key }}</div>
				<div><span class="st-hidden">Name</span>{{ row.title }}</div>
				<div>
                    <span class="st-hidden">Options</span>
					<div class="st-buttons">
                        <button class="btn-blue" @click="editQuestion(row.guid)">Edit</button>
                        <button class="btn-blue" @click="removeHandler">Remove</button>
					</div>
				</div>
			</template>
		</div>
	</TableWrapper>
</template>

<script>
import EloquentSSFP from "@/EloquentSSFP";
import { reactive } from 'vue';
import TableWrapper from '@/Components/TableWrapper.vue';

export default {
    components: {
        TableWrapper
    },
	data: function () {
		return {
			formList: reactive(new EloquentSSFP()),
		};
	},
    methods: {
        editQuestion(guid) {
            this.$inertia.visit(route('getFormsByGuid'), { data: {guid:guid} })
        },
        removeHandler: function() {
            this.$emit('remove', {dataItem:this.dataItem});
        },
    },
    mounted() {
        this.formList.init('fetch_forms', {
            searchableData: ['key', 'title']
        })
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../../scss/_simple-table-styles.scss';
</style>