<template>
    <div class="loading" v-if="loading || list.isLoading"></div>
    <TableWrapper
        :list="list"
        style="--cols: 6"
    >
        <div class="simple-table">
            <p class="first-col st-header">Company Name</p>
            <p class="st-header">Role Type</p>
            <p class="st-header">Full Name</p>
            <p class="st-header">Email</p>
            <p class="st-header">Reg Mobile</p>
            <p class="last-col st-header">Actions</p>

            <template v-for="row in list.displayedList" :key="row.id">
                <div class="first-col"><span class="st-hidden">Company Name</span>{{ row.company_name }}</div>
                <div><span class="st-hidden">Role Type</span>{{ row.role_type }}</div>
                <div><span class="st-hidden">Full Name</span>{{ row.first_name }} {{ row.last_name }}</div>
                <div>
                    <span class="st-hidden">Email</span>
                    <div class="st-buttons">
                        <a
                            v-if="row.email"
                            :href="`mailto:${row.email}`"
                            :title="`Starts email conversation with ${row.full_name} in your default mailing application`"
                        >{{ row.email }}</a>
                        <template v-else>{{ row.email }}</template>
                    </div>
                </div>
                <div>
                    <span class="st-hidden">Reg Phone</span>
                    <div class="st-buttons">
                        <a
                            v-if="row.email"
                            :href="`tel:${row.mobile.replaceAll(/\D/g,'')}`"
                            :title="`Calls ${row.full_name}'s phone number in your default calling application`"
                        >{{ row.email }}</a>
                        <template v-else>{{ row.mobile }}</template>
                    </div>
                </div>
                <div>
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <button class="btn-blue" @click="resendInvitation(row.id)">Resend Invitation</button>
                    </div>
                </div>
            </template>
        </div>
    </TableWrapper>
</template>

<script>
import EloquentSSFP from "@/EloquentSSFP";
import { reactive } from 'vue';
import TableWrapper from '@/Components/TableWrapper.vue';

export default {
    components: {
        TableWrapper
    },
    data() {
        return {
            list: reactive(new EloquentSSFP()),
            loading: false,
        }
    },
    methods: {
        resendInvitation(id) {
            if (!confirm('Are you sure want to send invitation again?')) return;
            let vm = this;
            this.loading = true;
            axios.get(route('resend.invitation',id))
                .then(response => {
                    vm.$page.props.jetstream.flash.banner = response.data.message;
                    vm.loading = false
                })
        },
    },
    mounted() {
        this.list.init('/fetch_invitations', {
            itemsPerPage: 20,
            activeSorts: ['created_at'],
            sortDirs: ['desc'],
            sortTypes: ['string']
        })
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../../scss/_simple-table-styles.scss';
</style>
