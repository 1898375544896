<template>
    <div class="p-4 border">
        <h3 class="text-lg font-medium text-gray-900" >
            <template v-if="twoFactorEnabled">Two factor authentication is <span class="text-green-500">Enabled</span>.</template>
            <template v-else>Two factor authentication is <span class="text-red">Disabled</span>.</template>
        </h3>
        <div>
            <p class="mt-3 max-w-xl text-sm text-gray-800">
                When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your registered mobile phone number.
            </p>
        </div>
        <!--<template v-if="twoFactorEnabled">
            <div v-if="qrCode" class="-mx-4 mt-4 px-4 pt-4 border border-l-0 border-r-0 border-b-0">
                <h3 class="text-lg font-medium text-gray-900">QR Code</h3>
                <p class="mt-3 max-w-xl text-sm text-gray-800">
                    Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application.
                </p>
                <div class="mt-4" v-html="qrCode"></div>
                <div class="flex flex-wrap -mx-2 mt-2 -mb-2">
                    <a
                        class="m-2 flex-grow-0 btn-outline-gray"
                        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US"
                        title="External link to the Google Authenticator App in the Google Play Store in a new window"
                        target="_blank"
                    ><FontAwesomeIcon :icon="googlePlayIcon" /> Google Play Store</a>
                    <a
                        class="m-2 flex-grow-0 btn-outline-gray"
                        href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                        title="External link to the Google Authenticator App in the Apple App Store in a new window"
                        target="_blank"
                    ><FontAwesomeIcon :icon="appStoreIosIcon" /> Apple App Store</a>
                </div>
            </div>
            <div class="-mx-4 mt-4 px-4 pt-4 border border-l-0 border-r-0 border-b-0" v-if="recoveryCodes.length > 0">
                <h3 class="text-lg font-medium text-gray-900">Recovery Codes</h3>
                <p class="mt-3 max-w-xl text-sm text-gray-800">
                    Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.
                </p>
                <div class="recovery-codes mt-4 py-3 px-2 font-mono text-sm bg-gray-200">
                    <p class="recovery-code py-1 px-2 text-center" v-for="code in recoveryCodes" :key="code">{{ code }}</p>
                </div>
                <div class="flex flex-wrap -mx-2 mt-2 -mb-2">
                    <JetConfirmsPassword @confirmed="regenerateRecoveryCodes" v-if="recoveryCodes.length > 0">
                        <button class="m-2 flex-grow-0 btn-outline-gray">Regenerate Recovery Codes</button>
                    </JetConfirmsPassword>
                </div>
            </div>
        </template>-->
    </div>
    <!--<div class="pt-4 -m-2 flex justify-end items-center">
        <template v-if="!twoFactorEnabled">
            <JetConfirmsPassword @confirmed="enableTwoFactorAuthentication">
                <button class="m-2 btn-blue" type="button" :class="{disabled:enabling}">Enable</button>
            </JetConfirmsPassword>
        </template>
        <template v-else> 
            <JetConfirmsPassword @confirmed="showRecoveryCodes" v-if="recoveryCodes.length === 0">
                <button class="m-2 btn-outline-gray">Show Recovery Codes</button>
            </JetConfirmsPassword>
            <JetConfirmsPassword @confirmed="disableTwoFactorAuthentication">
                <button class="m-2 btn-red" :class="{disabled:enabling}">Disable</button>
            </JetConfirmsPassword>
        </template>
    </div>-->

     <form class="-mx-2 flex flex-col justify-between h-full" @submit.prevent="submitMfaForm">
        <div class="flex flex-wrap">
            <div class="p-2 w-full"> 
                <SimpleSelect name="" :modelValue="this.$page.props.user.perform_mfa" :options="mfaOptions" v-model="mfaForm.perform_mfa" required/>
            </div> 
        </div>

        <div class="pt-2 px-2 w-full flex justify-end"> 
            <button class="flex-grow-0 btn-blue">Submit</button>
        </div>
    </form>

    <!--<div class="pt-4 -m-2 flex justify-end items-center">
        <template v-if="!twoFactorEnabled">
            <JetConfirmsPassword @confirmed="enableMfaAuthentication">
                <button class="m-2 btn-blue" type="button" :class="{disabled:enabling}">Enable MFA</button>
            </JetConfirmsPassword>
        </template>
        <template v-else> 
            <JetConfirmsPassword @confirmed="disableMfaAuthentication">
                <button class="m-2 btn-red" :class="{disabled:enabling}">Disable MFA</button>
            </JetConfirmsPassword>
        </template>
    </div>-->

</template>

<script>
    import { faGooglePlay, faAppStoreIos } from '@fortawesome/free-brands-svg-icons'
    import JetConfirmsPassword from '@/Jetstream/ConfirmsPassword.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import SimpleSelect from '@/Components/FormElements/SimpleSelect.vue'

    export default {
        components: {
            JetConfirmsPassword,
            FontAwesomeIcon,
            SimpleSelect,
        },
        data() {
            return {
                enabling: false,
                disabling: false,
                qrCode: null,
                recoveryCodes: [],
                googlePlayIcon: faGooglePlay,
                appStoreIosIcon: faAppStoreIos,  
                mfaForm: { 
                    perform_mfa: ''
                },               
                mfaOptions: [
                    {name: 'Disable', value: 0},
                    {name: 'Enable', value: 1}, 
                ],
            }
        },
        methods: {
            enableTwoFactorAuthentication() {
                this.enabling = true
                this.$inertia.post('/user/two-factor-authentication', {}, {
                    preserveScroll: true,
                    onSuccess: () => Promise.all([
                        this.showQrCode(),
                        this.showRecoveryCodes(),
                    ]),
                    onFinish: () => (this.enabling = false),
                })
            }, 

            showQrCode() {
                return axios.get('/user/two-factor-qr-code')
                        .then(response => {
                            this.qrCode = response.data.svg
                        })
            },

            showRecoveryCodes() {
                return axios.get('/user/two-factor-recovery-codes')
                        .then(response => {
                            this.recoveryCodes = response.data
                        })
            },

            regenerateRecoveryCodes() {
                axios.post('/user/two-factor-recovery-codes')
                        .then(response => {
                            this.showRecoveryCodes()
                        })
            },

            disableTwoFactorAuthentication() {
                this.disabling = true
                this.$inertia.delete('/user/two-factor-authentication', {
                    preserveScroll: true,
                    onSuccess: () => (this.disabling = false),
                })
            },

            enableMfaAuthentication() {
                this.enabling = true
                this.$inertia.post('/user/mfa-authentication', {}, {
                    preserveScroll: true,
                    onSuccess: () => Promise.all([
                        //this.showQrCode(),
                        //this.showRecoveryCodes(),
                    ]),
                    onFinish: () => (this.enabling = false),
                })
            },

            disableMfaAuthentication() {
                this.disabling = true
                this.$inertia.delete('/user/mfa-authentication', {
                    preserveScroll: true,
                    onSuccess: () => (this.disabling = false),
                })
            }, 
            submitMfaForm(e) {    
                let vm = this;
                this.loading = true 
                this.$inertia.post(route('user-mfa.submit'), this.mfaForm, {
                    preserveScroll: true,
                    onFinish: () => {
                        vm.loading = false  
                        //vm.fetchUsers();
                    },
                })
            }
        },

        computed: {
            twoFactorEnabled() {
                return ! this.enabling && this.$page.props.user.user_mfa_status
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../scss/_mixins.scss';

    .recovery-codes {
        display: inline-block;

        @include bp-up($bp-sm + 1) { column-count: 2; }
        @include bp-down($bp-sm) { column-count: 1; }

        .recovery-code { break-inside: avoid; }
    }
</style>