<template>
    <component
        :is="isRoute ? 'Link' : 'a'"
        class="data-link"
        v-if="!$slots.cta && ctaRoute"
        :target="isRoute ? '_blank' : '_self'"
        :href="ctaHref"
    >
        <div
            class="cta-cover grid grid-rows-3 justify-items-center"
            :style="{color: `var(--${color})`}"
        >
            <FontAwesomeIcon :icon="icon" />
            <p class="font-bold card-num">{{ number }}</p>
            <p class="data-name">{{ name }}</p>
        </div>
    </component>
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
    props: {
        ctaRoute: {
            type: String,
            required: false,
        },
        icon: {
            type: Object,
            required: true,
        },
        color: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        number: {
            type: [String,Number],
            required: true
        }
    },
    components: {
        FontAwesomeIcon,
        Link,
    },
    data() {
        return {
            isRoute: false,
        };
    },
    computed: {
        ctaHref() {
            let href;
            try {
                href = this.route(this.ctaRoute);
                this.isRoute = true;
            } catch (err) {
                href = this.ctaRoute;
            }
            return href;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/_mixins.scss";
.cta-cover {
    margin: .25rem;
    position: relative;
    cursor: pointer;
    transition: 200ms ease-out;
    text-align: center;
    text-decoration: underline;

    @include not-mobile {
        text-decoration: none;

        &:hover {
            top: -5px;
            text-decoration: underline;

            &:before {
                height: 5px;
            }
        }

        &:before {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 0;
            transition: 200ms ease-out;
        }
    }
}

.card-num {
    @include fluid-size(12px, 16px, font-size);
    color: var(--font-color);
    text-align: center;
}

.data-name {
    display: flex;
    align-items: center;
    @include fluid-size(12px, 14px, font-size);
    font-weight: 500;
    text-align: center;
}

.svg-inline--fa {
    height: 15px;
    text-align: center;
}
</style>
