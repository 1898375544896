<template>
<td>
    <kbutton
        :class="viewButtonClass"
        :type="'button'"
        :theme-color="'primary'"
        @click="viewAtps">
        {{this.dataItem.first_name}} {{this.dataItem.last_name}}
    </kbutton>
</td>
</template>
<script>
import { Button } from '@progress/kendo-vue-buttons';
import { Link } from '@inertiajs/inertia-vue3'
export default {
    components: {
        kbutton: Button,
        Link
    },
    props: {
        id: String,
        field: String,
        dataItem: Object,
        format: String,
        className: String,
        columnIndex: Number,
        columnsCount: Number,
        rowType: String,
        level: Number,
        expanded: Boolean
    },
    data(){
        return {
            navAttrs: {}
        };
    },
    computed: {
        viewButtonClass(){
            return this.$props.dataItem['inEdit'] ? 'k-grid-save-command' : 'k-grid-edit-command';
        },
    },
    methods: {
        viewAtps: function() {
            this.$inertia.visit(route('patient.details', this.dataItem.id)) 
        },
    }
}
</script>