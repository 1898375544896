<template>
  <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
    <div class="analytic-card">
      <div>
        <p class="title">Number of ATPS / Clinicians</p>
        <p class="num">{{this.$page.props.atps}}</p>
      </div>
    </div>
    <div class="analytic-card">
      <div>
        <p class="title">Number of Intakes</p>
        <p class="num">{{this.$page.props.intakes}}</p>
      </div>
    </div>
    <div class="analytic-card">
      <div>
        <p class="title">Number of Deliveries</p>
        <p class="num">{{this.$page.props.deliveries}}</p>
      </div>
    </div>
    <div class="analytic-card">
      <div>
        <p class="title">Family Centric</p>
        <p class="num">{{this.$page.props.family_centric}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import formattingMixin from "@/Mixins/formattingMixin.js";
import formProcessingMixin from "@/Mixins/formProcessingMixin.js";
import { Link } from "@inertiajs/inertia-vue3";
import JetLabel from "@/Jetstream/Label.vue";
import JetInput from "@/Jetstream/Input.vue";
import Card from "@/Components/Card.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  mixins: [formattingMixin, formProcessingMixin],
  components: {
    JetLabel,
    JetInput,
    Card,
    Link,
    FontAwesomeIcon,
  },
  data() {
    return {
      activeView: "BasicData",
      loading: false,
    };
  },
  methods: {
    setActiveView(view) {
      this.activeView = view;
    },
    buttonFilter(view) {
      return `${view && this.activeView == view ? " active" : ""}`;
    },
    buttonClasses(view) {
      return `btn-gray${view && this.activeView == view ? " active" : ""}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/_mixins.scss";

.analytic-card {
  @include fluid-size(300px, 200px, font-size);
  background-color: var(--gray-300);
  padding: 1rem;
  margin: 0.5rem 0.25rem;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  @include fluid-size(14px, 18px, font-size);
  font-weight: 500;
  padding-bottom: 0.5rem;
}

.num {
  @include fluid-size(18px, 22px, font-size);
  font-weight: 700;
  padding-bottom: 0.5rem;
}
</style>
