<template>
    <div class="wrapper" :class="{'fr-success': success, 'fr-error': error}">
        <div class="circle-loader" :class="{'load-complete':success || error}">
            <div class="checkmark draw" v-show="success"></div>
            <div class="error-x draw" v-show="error"></div>
        </div>
        <h5 v-if="message">{{ message }}</h5>
    </div>
</template>

<script>
export default {
    name: "FRLoader",
    props: ["success", "error", "message"]
}
</script>

<style lang="scss" scoped>
    // Define vars we'll be using
    $brand-success: var(--green-500);
    $brand-error: var(--red-500);

    .wrapper {
        --loader-size:     7em;
        --check-height:    calc(var(--loader-size) / 2);
        --check-width:     calc(var(--check-height) / 2);
        --check-left:      calc((var(--loader-size) / 6) + (var(--loader-size) / 12));
        --check-thickness: 3px;
        min-height: calc(var(--loader-size) + 6rem);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        --color: #{$brand-success};

        &.fr-error {
            transition: 500ms ease-out;
            --color: #{$brand-error};
        }
    }

    .circle-loader {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-left-color: var(--color);
        animation: loader-spin 1.2s infinite linear;
        position: relative;
        display: inline-block;
        vertical-align: top;
        border-radius: 50%;
        width: var(--loader-size);
        height: var(--loader-size);
    }

    .load-complete {
        -webkit-animation: none;
        animation: none;
        border-color: var(--color);
        transition: border 500ms ease-out;
    }

    .checkmark {
        &.draw:after {
            animation-duration: 800ms;
            animation-timing-function: ease;
            animation-name: checkmark;
            transform: scaleX(-1) rotate(135deg);
        }

        &:after {
            opacity: 1;
            height: var(--check-height);
            width: var(--check-width);
            transform-origin: left top;
            border-right: var(--check-thickness) solid var(--color);
            border-top: var(--check-thickness) solid var(--color);
            content: '';
            left: var(--check-left);
            top: var(--check-height);
            position: absolute;
        }
    }

    .error-x {
        &.draw {
            &:before, &:after {
                animation-duration: 800ms;
                animation-timing-function: ease;
                animation-name: error-x;
            }

            &:before {
                transform: translate(-50%, -50%) scaleX(-1) rotate(135deg);
            }

            &:after {
                transform: translate(-50%, -50%) scaleX(-1) rotate(45deg);
            }
        }

        &:after, &:before {
            opacity: 1;
            height: var(--check-thickness);
            width: var(--check-height);
            background-color: var(--color);
            content: '';
            left: 50%;
            top: 50%;
            position: absolute;
        }
    }

    h5 {
        margin-top: .5rem;
        color: var(--color);
        text-align: center;
    }

    @keyframes loader-spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes checkmark {
        0% {
            height: 0;
            width: 0;
            opacity: 1;
        }
        20% {
            height: 0;
            width: var(--check-width);
            opacity: 1;
        }
        40% {
            height: var(--check-height);
            width: var(--check-width);
            opacity: 1;
        }
        100% {
            height: var(--check-height);
            width: var(--check-width);
            opacity: 1;
        }
    }

    @keyframes error-x {
        0% {
            width: 0;
            opacity: 1;
        }
        100% {
            width: var(--check-height);
            opacity: 1;
        }
    }
</style>