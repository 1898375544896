<template>
    <AppLayout title="Forms">
        <div class="loader" v-if="loading"></div>
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},'Forms']"/>
        </template>

        <Card title="Forms">
            <FormsTable />
        </Card>
    </AppLayout>
</template>

<script>
    import {faUsers } from '@fortawesome/free-solid-svg-icons';
    import FormsTable from './Tables/FormsTable.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import formProcessingMixin from '@/Mixins/formProcessingMixin.js'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import Card from '@/Components/Card.vue'

    export default {
        mixins: [formattingMixin,formProcessingMixin],
        components: {
            FormsTable,
            Breadcrumbs,
            AppLayout,
            Card
        },
        props: ['data', 'errors', 'name'],
        data() {
            return {
                validate_msg: null,
                loading:false,
                users: faUsers,
            }
        },
        methods: {
            isCurrentPage(name) {
                return this.$page.component === `FormsGrid/Views/${name}`;
            },
            currentClass(name) {
                return {active: this.isCurrentPage(name)};
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../scss/_mixins.scss';

    .buttons {
        @include bp-up($bp-lg + 1) { margin: 0 -.5rem 1rem; }
        @include bp-down($bp-lg) {
            margin: 0 -.25rem 1rem;
            flex-wrap: wrap;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
            @include bp-down($bp-lg) {
                flex-wrap: wrap;
                flex-grow: 1;
            }
            display: flex;

            button, a, .customLink {
                text-align: center;
                @include bp-up($bp-lg + 1) { margin: .5rem; }
                @include bp-down($bp-lg) { margin: .25rem; }
                @include bp-between(451px, $bp-lg) { flex: 1 0 calc(50% - .5rem) }
                @include bp-down(450px) { flex: 1 0 calc(100% - .5rem) }
            }
        }
    }

    .btn-checkboxes {
        display: flex;
        flex-wrap: wrap;
        margin: -.5rem -.5rem 0;

        input {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        label {
            margin: .5rem;
            text-align: center;
        }
    }


    .subnav-btns {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 1.75rem -.25rem;

        > * {
            margin: .25rem;
            flex-grow: 0;
        }
    }

</style>
