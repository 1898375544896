<template>
    <DetailsLayout :name="'Exceptions'" :data="data" >
        <ExceptionsTable />
    </DetailsLayout>
</template>

<script>
    import DetailsLayout from '../Layouts/DetailsLayout.vue';
    import ExceptionsTable from '../Tables/ExceptionsTable.vue';

    export default {
        name: "Exceptions",
        components: {
            DetailsLayout,
            ExceptionsTable,
        },
        props: ["data"]
    }
</script>