<template>
    <div class="p-4 border">
        <div class="mx-w-xl text-sm text-gray-800">
            If necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.
        </div>
        <!-- Other Browser Sessions -->
        <div class="mt-3 -mx-2 grid grid-cols-2 gap-4" v-if="sessions.length > 0">
            <div class="m-2 items-center flex col-span-1" v-for="(session, i) in sessions" :key="i">
                <div class="text-2xl text-gray-700">
                    <FontAwesomeIcon :icon="desktopIcon" v-if="session.agent.is_desktop" />
                    <FontAwesomeIcon :icon="mobileIcon" v-else />
                </div>
                <div class="ml-3">
                    <p class="text-sm text-gray-800">{{ session.agent.platform }} - {{ session.agent.browser }}</p>
                    <p class="text-xs text-gray-700">
                        {{ session.ip_address }},
                        <span class="text-green-500 font-semibold" v-if="session.is_current_device">This device</span>
                        <span v-else>Last active {{ session.last_active }}</span>
                    </p>
                </div>
            </div>
        </div>

        <!-- Log Out Other Devices Confirmation Modal -->
        <JetDialogModal :show="confirmingLogout" @close="closeModal" max-width="lg">
            <template #title>
                <h4>Log Out Other Browser Sessions</h4>
            </template>
            <template #content>
                Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices.
                <div class="mt-4">
                    <JetInput
                        type="password"
                        class="mt-1 w-full"
                        placeholder="Password"
                        ref="password"
                        v-model="form.password"
                        @keyup.enter="logoutOtherBrowserSessions"
                    />
                    <JetInputError :message="form.errors.password" class="mt-2" />
                </div>
            </template>
            <template #footer>
                <div class="flex flex-wrap -m-2">
                    <button class="m-2 btn-outline-gray" @click="closeModal">Cancel</button>
                    <button class="m-2 btn-blue" @click="logoutOtherBrowserSessions" :class="{disabled:form.processing}">Log Out Other Browser Sessions</button>
                </div>
            </template>
        </JetDialogModal>
    </div>
    <div class="pt-4 -m-2 flex justify-end items-center">
        <JetActionMessage :on="form.recentlySuccessful" class="font-bold text-green-500" :class="{'m-2':form.recentlySuccessful}">Done!</JetActionMessage>
        <button class="m-2 btn-blue flex-grow-0" @click="confirmLogout">Log Out Other Browser Sessions</button>
    </div>
</template>

<script>
    import { faDesktop, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetActionSection from '@/Jetstream/ActionSection.vue'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'

    export default {
        props: ['sessions'],
        components: {
            JetSecondaryButton,
            JetActionMessage,
            JetActionSection,
            FontAwesomeIcon,
            JetDialogModal,
            JetInputError,
            JetButton,
            JetInput,
        },
        data() {
            return {
                confirmingLogout: false,
                form: this.$inertia.form({
                    password: '',
                }),
                desktopIcon: faDesktop,
                mobileIcon: faMobileAlt,
            }
        },

        methods: {
            confirmLogout() {
                this.confirmingLogout = true

                setTimeout(() => this.$refs.password.focus(), 250)
            },

            logoutOtherBrowserSessions() {
                this.form.delete(route('other-browser-sessions.destroy'), {
                    preserveScroll: true,
                    onSuccess: () => this.closeModal(),
                    onError: () => this.$refs.password.focus(),
                    onFinish: () => this.form.reset(),
                })
            },

            closeModal() {
                this.confirmingLogout = false

                this.form.reset()
            },
        },
    }
</script>
