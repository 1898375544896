import moment from 'moment';

export default {
    methods: {
        formatMoney(val) {
            if (typeof val === 'string') val = Number(val.replace(/[^\d.]/g,''));
            if (!val&&val!==0) return '-'

            let formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            });

            return formatter.format(Number(val));
        },
        formatDate(val) {
            if (moment(val).isValid()) {
                return moment(val).format("MM/DD/YYYY");
            }
            return val;
        },
        formatFormDate(val) {
            if (moment(val).isValid()) {
                return moment(val).format("DD-MM-YYYY");
            }
            return val;
        },
        formatFormData(questions, formData, options = {}) {
            let mapKeyToId = ([k,v]) => { return { id: k, ...v } };
            questions = Array.isArray(questions) ? questions : Object.entries(questions).map(mapKeyToId);

            return questions.reduce((acc, cur) => {
                let expectingSingleValue = !['radio', 'checkbox', 'select'].includes(cur.type),
                    optionAns            = !expectingSingleValue,
                    res                  = "",
                    formatIdArray        = (arr) => arr.map(i => parseInt(i) == i ? parseInt(i) : i),
                    pushToAcc            = (id, ans) => acc.push({
                        forms_questions_id: parseInt(id),
                        answer: ans,
                        forms_ctrl_guid: options.forms_ctrl_guid || null,
                        patients_guid: options.patients_guid || null,
                        forms_guid: options.forms_guid || null,
                        form_version_id: options.form_version_id || null,
                        previous_form_guid: options.previous_form_guid || null
                    });

                if (formData.has(cur.id)) res = formData.get(cur.id);

                if (!formData.has(cur.id) || expectingSingleValue) { // If question not answered or if only one answer needed
                    pushToAcc(cur.id, optionAns ? parseInt(res) : res);
                } else { // If question has been answered and it's a checkbox
                    formatIdArray(formData.getAll(cur.id)).forEach(id => pushToAcc(cur.id, parseInt(id)));
                }

                return acc
            }, [])
        },
        validateFormatting() {
            let questions = Array.from(this.$refs.form.querySelectorAll('.fr-section.fr-active input, .fr-section.fr-active select'));
            return questions.every(question => {
                let res = true;
                let validateFunc = (q) => { return true };
                let wrapper = (q) => { return q.closest('.fr-group') };

                // When required
                if (question.required) {
                    validateFunc = (q) => { return q.value.trim().length > 0 };
                    switch (question.tagName) {
                        case "INPUT":
                            switch (question.type) {
                                case "checkbox":
                                case "radio":
                                    // Check if any are selected in options
                                    validateFunc = (q) => { return questions.filter(q1 => q1.name === q.name).some(q2 => q2.checked) };
                                    wrapper      = (q) => { return q.closest(questions.filter(q1 => q1.name === q.name).length > 1 ? '.fr-fieldset' : '.fr-group') };
                                    break;
                            }

                            // Custom Year Input
                            let yearRegex = /((19)|(20))[0-9]{2}/;
                            if (question.pattern === `${yearRegex}`) validateFunc = (q) => { return yearRegex.test(q.value); }
                            break;

                        case "SELECT":
                            validateFunc = (q) => { return Array.from(q.options).some(o => o.selected && !o.disabled && o.value) };
                            break;
                    }
                }

                // Regardless of if question is required
                switch (question.tagName) {
                    case "INPUT":
                        // Custom Year Input
                        let yearRegex = /((19)|(20))[0-9]{2}/;
                        if (question.pattern === `${yearRegex}`) {
                            validateFunc = (q) => {
                                // If a value has been entered check it is a valid year
                                if (!q.value.length) return true;
                                // If it's required, also check that a value has been entered
                                if (q.required) return q.value.length && yearRegex.test(q.value);
                                return yearRegex.test(q.value);
                            }
                        }
                        break;
                }

                if (!validateFunc(question)) {
                    res = false;
                    wrapper(question).classList.add('fr-invalid');
                }

                if (!res) question.focus();

                return res;
            })
        }
    }
}
