<template>
    <div class="app" :class="{expanded: expandedNav}">
        <Head :title="title" />

        <JetBanner />

        <div class="loader" v-if="loading"></div>

        <div class="app-wrapper">

            <div class="app-content">

                <div class="hamburger" @click="expandToggle()">
                    <FontAwesomeIcon v-if="!expandedNav" fixed-width :icon="bars"/>
                    <FontAwesomeIcon v-else fixed-width :icon="times"/>
                </div>

                <div class="content-section">
                    <main>
                        <slot></slot>
                    </main>

                    <AppFooter />
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import { faBars, faTimes, faUser, faMask } from '@fortawesome/free-solid-svg-icons';
    import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import JetDropdownLink from '@/Jetstream/DropdownLink.vue'
    import JetDropdown from '@/Jetstream/Dropdown.vue'
    import AppFooter from '@/Layouts/AppFooter.vue'
    import { Head } from '@inertiajs/inertia-vue3'
    import JetBanner from '@/Jetstream/Banner.vue'
    import moment from 'moment';

    export default {
        props: {
            title: String,
        },
        components: {
            JetApplicationLogo,
            FontAwesomeIcon,
            JetDropdownLink,
            JetDropdown,
            JetBanner,
            AppFooter,
            Head,
        },
        data() {
            return {
                showingNavigationDropdown: false,
                isImpersonating: false, // This hides and shows the button to exit impersonation
                expandedNav: false,
                loading: false,
                times: faTimes,
                bars: faBars,
                user: faUser,
                mask: faMask,
            }
        },
        watch: {
            expandedNav(n,o) {
                if (!n) document.body.style.overflow = '';
            }
        },
        computed: {
            formatCurrentComponent() {
                return this.$page.component;
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';

    .app-wrapper {
        overflow: hidden;
        height: 100vh;
        display: flex;
        flex-direction: column;

        .app-content {
            display: flex;
            overflow: hidden;
            flex-grow: 1;

            .sidebar {
                min-width: max-content;
            }

            .content-section {
                flex-grow: 1;
                z-index: 0;
                overflow: hidden auto;
                max-width: 100vw;
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: space-between;

                @include not-desktop {
                    .app.expanded & { overflow: hidden; }
                }

                .content-top {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    header {
                        align-self: flex-start;
                        @include fluid-size(16px, 32px, padding-right);
                        @include fluid-size(16px, 32px, padding-left);
                        @include fluid-size(16px, 32px, padding-bottom);

                        .header-greeting {
                            @include fluid-size(20px, 42px, font-size);
                            color: var(--blue-500);
                            font-weight: 700;
                            line-height: 1;
                        }
                    }

                    .account-actions {
                        align-self: flex-end;
                        display: flex;
                        align-items: center;
                        @include fluid-size(0px, 16px, padding);

                        button {
                            @include fluid-size(14px, 18px, font-size);
                            display: flex;
                            align-items: center;
                            font-weight: 800;
                            margin: .5rem;
                            text-align: left;
                            line-height: 1;

                            svg { @include fluid-size(18px, 24px, font-size) }

                            svg, span {
                                margin: .25rem;
                            }
                        }
                    }
                }

                main {
                    flex-grow: 1;
                    @include fluid-size(8px, 32px, padding-left);
                    @include fluid-size(8px, 32px, padding-right);
                    @include fluid-size(16px, 32px, padding-bottom);
                    @include fluid-size(32px, 48px, padding-top);
                }
            }

            .hamburger {
                @include fluid-size(18px, 20px, font-size);
                font-weight: bold;
                position: relative;
                color: var(--blue-100);
                overflow: hidden;
                position: fixed;
                bottom: 1rem;
                left: calc(50% - 8.5px); // The width of the average scrollbar
                transform: translate(-50%, 0);
                transition: 200ms ease-out;
                background-color: var(--blue-500);
                border-radius: 50rem;
                box-shadow: 2px 0 5px rgba(0,0,0,.1);
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 3;

                @include not-desktop {
                    height: 40px;
                    width: 40px;
                }

                @include desktop {
                    height: 0;
                    width: 0;
                }
            }
        }
    }
</style>
