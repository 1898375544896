<template>
    <div class="top-nav" ref="topnav">
        <div class="content-top">
            <header>
                <h5 class="header-greeting">
                    FMA - Functional Mobility Assessment
                </h5>
            </header>
            <div class="nav-btn">
                <Link
                    class="btn-blue"
                    href="/get_forms_by_guid/?guid=edbc5a4e-f963-4ce4-beef-5555c17effb9"
                    >+ New Demographic Intake</Link
                >
            </div>
            <div class="account-actions">
                <div
                    class="relative flex flex-row align-items-center justify-between"
                >
                    <!-- Teams Dropdown -->
                    <JetDropdown
                        align="left"
                        width="max"
                        class="mr-5"
                        v-if="$page.props.jetstream.hasTeamFeatures && userType == 'AD'"
                    >
                        <template #trigger>
                            <button
                                class="btn-outline-gray px-6 py-1"
                                ref="dropdownToggleButton"
                            >
                                <p class="font-medium text-xs text-gray-800">
                                    {{
                                        (activeCompany &&
                                            activeCompany.company_name) ||
                                        "All Companies"
                                    }}
                                </p>
                                <FontAwesomeIcon
                                    class="w-2.5 pl-2"
                                    :icon="arrowDown"
                                />
                            </button>
                        </template>
                        <template #content>
                            <div class="w-max" @click.stop="nothing()">
                                <form
                                    @submit.prevent="updateSearch()"
                                    class="company-search-wrapper"
                                >
                                    <input
                                        class="company-search"
                                        type="search"
                                        v-model="searchModel"
                                    />
                                    <FontAwesomeIcon
                                        :icon="search"
                                        v-if="!searchModel"
                                    />
                                </form>
                                <div
                                    class="results"
                                    v-if="companies_list.displayedList.length"
                                >
                                    <JetDropdownLink
                                        as="button"
                                        v-for="company in companies_list.displayedList"
                                        :class="{
                                            active:
                                                activeCompany &&
                                                company.id === activeCompany.id,
                                        }"
                                        :key="`${company.id}_${cookieInc}`"
                                        @click.prevent="
                                            setCompanyCookie(company.id)
                                        "
                                        >{{
                                            company.company_name
                                        }}</JetDropdownLink
                                    >
                                </div>
                                <p
                                    class="no-results px-4 py-2 text-sm leading-5 text-gray-900"
                                    v-else
                                >
                                    No results
                                </p>
                                <div class="reset">
                                    <JetDropdownLink
                                        as="button"
                                        v-if="activeCompany && activeCompany.id"
                                        @click.prevent="removeCompanyCookie()"
                                        >All Companies</JetDropdownLink
                                    >
                                </div>
                            </div>
                        </template>
                    </JetDropdown>
                    <JetDropdown
                        class="flex-end"
                        align="left"
                        width="max"
                        v-if="$page.props.jetstream.hasTeamFeatures"
                    >
                        <template #trigger>
                            <span class="inline-flex rounded-md">
                                <button class="text-blue-500">
                                    <FontAwesomeIcon :icon="user" />
                                    <span
                                        >{{ $page.props.user.first_name }}
                                        {{
                                            $page.props.user.last_name != null
                                                ? $page.props.user.last_name
                                                : ""
                                        }}
                                    </span>
                                </button>
                            </span>
                        </template>
                        <template #content>
                            <div class="w-max">
                                <template
                                    v-if="$page.props.jetstream.hasTeamFeatures"
                                >
                                    <div
                                        class="block px-4 py-2 text-xs text-gray-800"
                                    >
                                        Manage Account
                                    </div>

                                    <JetDropdownLink
                                        :href="route('profile.show')"
                                    >
                                        Profile
                                    </JetDropdownLink>

                                    <form @submit.prevent="logout">
                                        <JetDropdownLink as="button">
                                            Log Out
                                        </JetDropdownLink>
                                    </form>

                                    <a href="/logs" class="block w-full px-4 py-2 text-sm leading-5 text-gray-900 text-left hover:bg-gray-100 hover:text-blue-500 focus:outline-none focus:bg-gray-100 transition">View Error Logs</a>
                                </template>
                            </div>
                        </template>
                    </JetDropdown>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import EloquentSSFP from "@/EloquentSSFP";
import { reactive } from 'vue';
import { faBars, faTimes, faUser, faMask, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import JetDropdownLink from '@/Jetstream/DropdownLink.vue'
import JetDropdown from '@/Jetstream/Dropdown.vue'
import AppSidebar from '@/Layouts/AppSidebar.vue'
import AppFooter from '@/Layouts/AppFooter.vue'
import { Head } from '@inertiajs/inertia-vue3'
import JetBanner from '@/Jetstream/Banner.vue'
import moment from 'moment';
import { Link } from '@inertiajs/inertia-vue3'

export default {
    props: {
        title: String,
    },
    components: {
        JetApplicationLogo,
        FontAwesomeIcon,
        JetDropdownLink,
        JetDropdown,
        AppSidebar,
        JetBanner,
        AppFooter,
        Head,
        Link,
    },
    data() {
        return {
            showingNavigationDropdown: false,
            isImpersonating: false, // This hides and shows the button to exit impersonation
            expandedNav: false,
            loading: false,
            times: faTimes,
            bars: faBars,
            user: faUser,
            mask: faMask,
            search: faSearch,
            arrowDown: faChevronDown,
            searchModel: "",
            cookieInc: 0,
            companies_list: reactive(new EloquentSSFP()),
            activeCompany: {}
        }
    },
    watch: {
        expandedNav(n,o) {
            if (!n) document.body.style.overflow = '';
        },
        searchModel(n,o) {
            if (!n && o.length) {
                this.companies_list.updateSearch("");
            }
        }
    },
    computed: {
        formatLastVistedDate() {
            return moment(this.$page.props.user.last_login_date).format('MMMM D, YYYY');
        },
        formatCurrentComponent() {
            return this.$page.component;
        },
        companyCookie() {
            let cookies = this.getCookiesAsObject();
            return 'company_filter_id' in cookies ? cookies['company_filter_id'] : false;
        },
        userType() {
            return this.$page.props.user.role_type;
        }
    },
    methods: {
        fetchActiveCompany(id) {
            axios.get(`/fetch_company/${id}`)
                .then(res => this.activeCompany = res.data.data)
                .catch(err => console.error(err));
        },
        updateSearch() {
            this.companies_list.updateSearch(this.searchModel);
        },
        setCompanyCookie(id) {
            document.cookie = `company_filter_id=${id}; samesite=strict; secure;path=/`;
            this.fetchActiveCompany(id);
            this.$refs.dropdownToggleButton.click();
            this.$page.props.globalInc++;
            window.location.reload();
        },
        removeCompanyCookie() { //delete the cookie instead?
            document.cookie = `company_filter_id=0; samesite=strict; secure;path=/`;
            this.activeCompany = {};
            this.$refs.dropdownToggleButton.click();
            this.$page.props.globalInc++;
            window.location.reload();
        },
        getCookiesAsObject() {
            let reduce = (a,c) => { let [k,v] = c.trim().split('='); if (!(k in a)) a[k] = v; return a };
            return document.cookie.split(';').reduce(reduce, {});
        },
        logout() {
            this.$inertia.post(route('logout'));
        },
        expandToggle() {
            this.expandedNav = !this.expandedNav;
        },
        getTopNavHeight() {
            if (this.$refs.topnav) {
                document.body.style.setProperty('--topnav-height', this.$refs.topnav.clientHeight + 'px');
            }
        },
        appendWhose(string) {
            if (!this.whoseAddress) return string;
            return `${this.whoseAddress} ${string}`;
        },
        appendForm(string) {
            if (!this.whoseForm) return string;
            return `${this.whoseForm} ${string}`;
        },
        nothing() {
            // Jetstream currently closes the dropdown if you click anywhere, even inside of it.
            // This is here to prevent that
        }
    },
    mounted() {
        this.getTopNavHeight();
        window.addEventListener('resize', this.getTopNavHeight);

        let options = {
            itemsPerPage: 5,
            searchableData: [
                'company_name',
                'vgm_number',
                'website',
            ],
            'sortTypes': ['date'],
            'sortDirs': ['asc'],
            'activeSorts': ['created_at']
        };

        if (Number(this.companyCookie)) {
            this.fetchActiveCompany(this.companyCookie);

            options['filterProps'] = ['id'];
            options['filterValues'] = [[this.companyCookie]];
            options['filterOperators'] = [['=']];
            options['filterExclusivities'] = [1];
        }

        this.companies_list.init('/fetch_companies', options)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.getTopNavHeight);
    }
}
</script>

<style lang="scss" scoped>
@import "../../scss/_mixins.scss";

.company-search-wrapper {
    position: relative;
    padding: 0.5rem 1rem;

    svg {
        color: hsl(0deg, 0%, 85%);
        position: absolute;
        top: 50%;
        right: 2rem;
        transform: translateY(-50%);
    }
}

.company-search {
    width: 100%;
}

.results,
.no-results {
    border-top: 1px solid hsl(0deg, 0%, 95%);
}

.reset {
    border-top: 1px solid hsl(0deg, 0%, 95%);
}

.top-nav {
    grid-area: topnav;
    z-index: 2;

    @include not-desktop {
        .app.expanded & {
            overflow: hidden;
        }
    }

    .content-top {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: white;
        box-shadow: 0px 3px 6px #00000017;

        @include not-mobile {
            flex-direction: row;
        }

        header {
            align-self: flex-start;
            @include fluid-size(14px, 16px, padding);

            .header-greeting {
                @include fluid-size(0px, 16px, padding);
                display: flex;
                align-items: center;
                font-weight: 700;
                line-height: 1;
                text-align: center;
            }
        }

        .btn-blue {
            @include fluid-size(12px, 14px, font-size);
        }

        .nav-btn {
            @include fluid-size(0px, 16px, padding);
            display: flex;
            align-items: center;
        }

        .account-actions {
            display: flex;
            align-items: center;
            @include fluid-size(0px, 16px, padding);

            button {
                @include fluid-size(14px, 18px, font-size);
                display: flex;
                align-items: center;
                font-weight: 800;
                margin: 0.5rem;
                text-align: left;
                line-height: 1;

                svg {
                    @include fluid-size(18px, 24px, font-size);
                }

                svg,
                span {
                    margin: 0.25rem;
                }
            }
        }
    }
}
</style>
