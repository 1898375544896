<template>
    <div class="footer" ref="footer">
        <div class="copyright">
            <p>&copy; {{ new Date().getFullYear() }} Functional Mobility Assessment. All Rights Reserved. Custom web solutions provided by <a href="https://goo.gl/Rwf1Zx" class="underline" title="External link to VGM Forbin's site in a new window" target="_blank">VGM&nbsp;Forbin</a>
                <a class="float-right"  href="javascript:void(0);" onclick="window.sp.showPrivacyBanner();" title="Internal link to Privacy Policy">Privacy</a>
            </p>
        </div>
    </div>
</template>

<script>
    import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { Link } from '@inertiajs/inertia-vue3'

    export default {
        name: "AppFooter",
        components: {
            JetApplicationLogo,
            FontAwesomeIcon,
            Link
        },
        methods: {
            getFooterHeight() {
                if (this.$refs.footer) {
                    document.body.style.setProperty('--footer-height', this.$refs.footer.clientHeight + 'px');
                }
            },
        },
        mounted() {
            this.getFooterHeight();
            window.addEventListener('resize', this.getFooterHeight);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.getFooterHeight);
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';

    .footer {
        z-index: 1;
        grid-area: footer;
        background-color: var(--blue-500);
        color: white;

        @include not-desktop { padding-bottom: 40px; }

        .copyright {
            @include fluid-size(4px, 8px, padding-top);
            @include fluid-size(4px, 8px, padding-bottom);
            @include fluid-size(8px, 16px, padding-left);
            @include fluid-size(8px, 16px, padding-right);
            @include bp-down($bp-lg) { text-align: center; }

            * {
                font-size: 12px;
            }
        }
    }
</style>
