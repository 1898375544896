<template>
    <div>
        <div class="mt-2">
            <JetLabel :for="`address_${uid}`" :value="appendWhose('Address')" />
            <JetInput :id="`address_${uid}`" type="text" placeholder="Enter Address" name="address" :v-model="appendForm('address')" class="mt-1 block w-full" />
        </div>
        <div class="md:flex -mb-2 -mx-2">
            <div class="m-2 flex-grow">
                <JetLabel :for="`city_${uid}`" :value="appendWhose('City')" />
                <JetInput :id="`city_${uid}`" placeholder="City" type="text" name="city" v-model="city" class="mt-1 block w-full" />
            </div>
            <div class="m-2 flex-grow">
                <SimpleSelect
                    name="State"
                    :label="appendWhose('State')"
                    v-model="state"
                    :options="stateOptions"
                />
            </div>
            <div class="m-2 flex-grow">
                <JetLabel :for="`zip_${uid}`" :value="appendWhose('ZIP')" />
                <JetInput :id="`zip_${uid}`" placeholder="Zipcode" name="zip" type="text" class="mt-1 block w-full" />
            </div>
        </div>
    </div>
</template>

<script>
import SimpleSelect from './FormElements/SimpleSelect.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetLabel from '@/Jetstream/Label.vue'
import uidMixin from '@/Mixins/uidMixin.js'

export default {
    mixins: [uidMixin],
    props: ["whoseAddress","whoseForm"],
    components: {
        SimpleSelect,
        JetInput,
        JetLabel,
    },
    data() {
        return {
            state: "",
            stateOptions: [
                {name:"",value:"",hidden:true},
                {name:"AK",value:"AK"},
                {name:"AL",value:"AL"},
                {name:"AR",value:"AR"},
                {name:"AZ",value:"AZ"},
                {name:"CA",value:"CA"},
                {name:"CO",value:"CO"},
                {name:"CT",value:"CT"},
                {name:"DE",value:"DE"},
                {name:"FL",value:"FL"},
                {name:"GA",value:"GA"},
                {name:"HI",value:"HI"},
                {name:"IA",value:"IA"},
                {name:"ID",value:"ID"},
                {name:"IL",value:"IL"},
                {name:"IN",value:"IN"},
                {name:"KS",value:"KS"},
                {name:"KY",value:"KY"},
                {name:"LA",value:"LA"},
                {name:"MA",value:"MA"},
                {name:"MD",value:"MD"},
                {name:"ME",value:"ME"},
                {name:"MI",value:"MI"},
                {name:"MN",value:"MN"},
                {name:"MO",value:"MO"},
                {name:"MS",value:"MS"},
                {name:"MT",value:"MT"},
                {name:"NC",value:"NC"},
                {name:"ND",value:"ND"},
                {name:"NE",value:"NE"},
                {name:"NH",value:"NH"},
                {name:"NJ",value:"NJ"},
                {name:"NM",value:"NM"},
                {name:"NV",value:"NV"},
                {name:"NY",value:"NY"},
                {name:"OH",value:"OH"},
                {name:"OK",value:"OK"},
                {name:"OR",value:"OR"},
                {name:"PA",value:"PA"},
                {name:"RI",value:"RI"},
                {name:"SC",value:"SC"},
                {name:"SD",value:"SD"},
                {name:"TN",value:"TN"},
                {name:"TX",value:"TX"},
                {name:"UT",value:"UT"},
                {name:"VA",value:"VA"},
                {name:"VT",value:"VT"},
                {name:"WA",value:"WA"},
                {name:"WI",value:"WI"},
                {name:"WV",value:"WV"},
                {name:"WY",value:"WY"},
            ]
        }
    },
    methods: {
        appendWhose(string) {
            if (!this.whoseAddress) return string;
            return `${this.whoseAddress} ${string}`;
        },
        appendForm(string) {
            if (!this.whoseForm) return string;
            return `${this.whoseForm} ${string}`;
        }
    }
}
</script>
