<template>
    <DetailsLayout :name="'Tasks'" :data="data" >
        <TasksTable />
    </DetailsLayout>
</template>

<script>
    import DetailsLayout from '../Layouts/DetailsLayout.vue';
    import TasksTable from '../Tables/TasksTable.vue';

    export default {
        name: "Tasks",
        components: {
            DetailsLayout,
            TasksTable,
        },
        props: ["data"],
    }
</script>