<template>
	<TableWrapper
        :list="list"
        style="--cols: 4"
	>
		<div class="simple-table">
			<p class="first-col st-header">Task</p>
			<p class="st-header">Status</p>
			<p class="st-header">Due Date</p>
			<p class="last-col st-header">Actions</p>

			<template v-for="row in list.displayedList" :key="row.id">
				<div class="first-col"><span class="st-hidden">Task</span>{{ row.task_name }}</div>
				<div><span class="st-hidden">Status</span>{{ row.status }}</div>
				<div><span class="st-hidden">Due Date</span>{{ row.due_date }}</div>
                <div>
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <button class="btn-blue" @click="edit(row.guid)">Edit</button>
                    </div>
                </div>
			</template>
		</div>
	</TableWrapper>
</template>

<script>
import EloquentSSFP from "@/EloquentSSFP";
import { reactive } from 'vue';
import TableWrapper from '@/Components/TableWrapper.vue';

export default {
    name: "ExceptionsTable",
    components: {
        TableWrapper
    },
	data: function () {
		return {
			list: reactive(new EloquentSSFP()),
		};
	},
    methods: {
        edit(guid) {
            alert("They didn't hook this up yet!");
        },
    },
    mounted() {
        this.list.init('fetch_tasks', {
            itemsPerPage: 20
        })
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../../scss/_simple-table-styles.scss';
</style>