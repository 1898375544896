<template>
    <div
        class="fr-section"
        :class="{'fr-border': section.border}"
    >
        <h5 class="fr-section-title" v-if="section.title">{{ section.title }}</h5>

        <div class="fr-questions">
            <div
                class="fr-question"
                :class="{'fr-wide': wideCheck(id) }"
                v-for="id in section.questions"
                v-show="showSubQuestion(id)"
                :key="`question_${id}`"
            >
                <component
                    :question-id="id"
                    :is="`f-r-question-${questions[id].type}`"
                    :question="questions[id]"
                    :interactions="interactions"
                    :options="optionsInQuestion(id)"
                    :focussed-question="focussedQuestion == id"
                    v-model="initialAnswers[id]"
                    @focus-section="focusSection(id)"
                    @toggle-trigger="toggleIfTrigger($event)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import formattingMixin from '@/Mixins/formattingMixin';
import components from '@/Mixins/questionComponentMixin';

export default {
    name: "FRESection",
    props: [
        "section",
        "options",
        "questions",
        "interactions",
        "answers"
    ],
    mixins: [formattingMixin],
    emits: ["focus-section"],
    components: {
        ...components
    },
    data() {
        return {
            showSubQuestions: [],
            focussedQuestion: null,
            initialAnswers: []
        }
    },
    methods: {
        showSubQuestion(id) {
            if (!this.interactions) return true
            let is_toggled               = this.showSubQuestions.includes(id);
            let can_be_toggled_by_parent = !!Object.values(this.interactions).some(value => value.toggles === id);
            if (!can_be_toggled_by_parent) return true
            return is_toggled
        },
        toggleIfTrigger({ check, questionId }) {
            let questionInteraction = this.interactions?.[questionId],
                toggles             = questionInteraction?.toggles;

            if (toggles) {
                let arr = this.showSubQuestions,
                    inc = arr.includes(toggles);
                if (check && !inc) arr.push(toggles);
                else if (!check && inc) this.clearInputsAndSubInputs(toggles);
            }
        },
        clearInputsAndSubInputs(id) {
            let arr = this.showSubQuestions;

            // Hide and clear input
            arr.splice(arr.indexOf(id),1);
            this.clearInput(id);

            // Clear and hide any subquestions one level deeper
            let subId = this.interactions?.[id]?.toggles
            if (subId) {
                this.clearInputsAndSubInputs(subId);
            }
        },
        clearInput(id) {
            this.initialAnswers[id] = "";
        },
        focusSection(id) {
            this.focussedQuestion = id;
            this.$emit('focus-section');
        },
        optionsInQuestion(id) {
            let optionIds = this.questions[id].options;
            let res = undefined;
            if (optionIds) {
                res = Object.entries(this.options).reduce((a, [k, v]) => {
                    if (optionIds.includes(parseInt(k))) a[k] = v;
                    return a;
                }, {})
            }
            return res;
        },
        wideCheck(id) {
            return ['checkbox', 'radio'].includes(this.questions[id].type) && this.questions[id].options?.length < 8;
        }
    },
    mounted() {
        this.initialAnswers = this.answers;
        Object.entries(this.answers).forEach(([questionId, answer]) => {
            let interaction = this.interactions?.[questionId];

            if (interaction?.toggles) {
                let check = interaction.options?.includes(Number(answer));
                if (Array.isArray(answer)) check = answer.some(answerOption => interaction.options?.includes(Number(answerOption)));
                if (check) this.showSubQuestions.push(interaction.toggles);
            }
        })
    }
}
</script>

<style lang="scss" scoped>
    .fr-section {
        padding-bottom: 1rem;
        width: 100%;

        &.fr-border {
            padding-left: 1rem;
            padding-right: 1rem;
            border: 1px solid black;
        }
    }

    .fr-section-title {
        margin-bottom: 1rem;
    }

    .fr-questions {
        margin: -.5rem;
        display: flex;
        flex-wrap: wrap;
        max-width: calc(100% + 1rem);
        align-items: flex-start;
    }

    .fr-question, .fr-sub-question {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: calc((100% / 3) - 1rem);
        margin: .5rem;
        padding: .5rem;
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 3px;
        max-width: calc(100% - 1rem);
        min-width: fit-content;
        background-color: hsl(0deg, 0%, 99%);

        &.fr-wide {
            flex-basis: calc(100% - 1rem);
        }
    }

    :deep(.fr-required) {
        color: var(--red-500);
    }
</style>
