<template>
    <TableWrapper
        :list="list"
        style="--cols: 6"
    >
        <div class="st-hidden-sorts">
            <p @click="list.sortBy([['first_name'],['last_name']])" :class="list.sortClass('first_name')">Name</p>
        </div>

        <div class="simple-table">
            <p class="first-col st-header">Company Name</p>
            <p class="st-header">Role Type</p>
            <p class="st-header" @click="list.sortBy([['first_name'],['last_name']])" :class="list.sortClass('first_name')">Name</p>
            <p class="st-header">Email</p>
            <p class="st-header">Mobile</p>
            <p class="last-col st-header">Actions</p>

            <template v-for="row in list.displayedList" :key="row.id">
                <div class="first-col"><span class="st-hidden">Company Name</span>{{ row.company_name }}</div>
                <div><span class="st-hidden">Role Type</span>{{ row.role_type }}</div>
                <div><span class="st-hidden">Name</span>{{ row.first_name }} {{ row.last_name }}</div>
                <div><span class="st-hidden">Email</span>{{ row.email }}</div>
                <div><span class="st-hidden">Mobile</span>{{ row.mobile }}</div>
                <div>
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <button class="btn-blue" @click="edit(row.id)">Edit</button>
                        <button class="btn-outline-gray" @click="remove(row.id)">Remove</button>
                    </div>
                </div>
            </template>
        </div>
    </TableWrapper>
</template>

<script>
import EloquentSSFP from "@/EloquentSSFP";
import { reactive } from 'vue';
import TableWrapper from '@/Components/TableWrapper.vue';

export default {
    components: {
        TableWrapper
    },
    data: function () {
        return {
            list: reactive(new EloquentSSFP()),
        };
    },
    methods: {
        edit(id) {
            this.$inertia.visit(route('user.edit', id))
        },
        remove(id) {
            this.$inertia.visit(route('delete.user', id))
        }
    },
    computed: {
        activeCompanyId() {
            let parts = this.$page.url.split("/");
            return parts.length > 3 ? Number(parts[3]) : false;
        }
    },
    mounted() {
        this.list.init('/fetch_users', {
            itemsPerPage: 20,
            searchableData: [
                'first_name',
                'last_name',
                'email',
            ],
            additionalParameters: this.activeCompanyId ? {id: this.activeCompanyId} : {}
        });
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../../scss/_simple-table-styles.scss';
</style>
