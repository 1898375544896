<template>
    <p class="breadcrumbs">
        <template v-for="(value, index) in path">
            <template
                v-if="typeof value == 'string'"
            >{{ value }}</template>

            <Link
                :key="'breadcrumb-link-' + index"
                v-else
                :href="route(value.route)"
            >{{ value.name }}</Link>

            <span
                class="mx-1"
                :key="'breadcrumb-slash-' + index"
                v-if="index !== path.length - 1"
            >/</span>
        </template>
    </p>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3';

export default {
    name: "Breadcrumbs",
    components: {
        Link
    },
    props: {
        path: {
            type: Array,
            required: true
        }
    },
}
</script>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';

    .breadcrumbs {
        @include fluid-size(14px, 16px, font-size);
        color: var(--gray-700);
        font-weight: 400;
        padding-top: .5em;
        padding-left: 1em;

        :deep(a) {
            font-weight: bold;
        }
    }
</style>