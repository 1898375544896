<template>
    <div
        class="fr-section"
        :class="{'fr-border': section.border}"
    >
        <h5 class="fr-section-title" v-if="section.title">{{ section.title }}</h5>

        <div class="fr-questions">
            <div
                class="fr-question"
                v-for="id in section.questions"
                :key="`question_${id}`"
                v-show="showSubQuestion(id)"
            >
                <component
                    :question-id="id"
                    :is="`f-r-question-${questions[id].type}`"
                    :question="questions[id]"
                    :interactions="interactions"
                    :no-tab="!sectionActive"
                    :options="optionsInQuestion(id)"
                    :active-section="sectionActive"
                    :focussed-question="focussedQuestion == id"
                    @focus-section="focusSection(id)"
                    @toggle-trigger="toggleIfTrigger($event)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import formattingMixin from '@/Mixins/formattingMixin';
import components from '@/Mixins/questionComponentMixin';

export default {
    name: "FRSection",
    props: [
        "section",
        "options",
        "questions",
        "interactions",
        "formResetInc",
        "sectionActive",
    ],
    mixins: [formattingMixin],
    emits: ["focus-section"],
    components: {
        ...components
    },
    data() {
        return {
            showSubQuestions: [],
            focussedQuestion: null
        }
    },
    methods: {
        showSubQuestion(id) {
            if (!this.interactions) return true
            let is_toggled               = this.showSubQuestions.includes(id);
            let can_be_toggled_by_parent = !!Object.values(this.interactions).some(value => value.toggles === id);
            if (!can_be_toggled_by_parent) return true
            return is_toggled
        },
        toggleIfTrigger({ check, questionId }) {
            let questionInteraction = this.interactions?.[questionId],
                toggles             = questionInteraction?.toggles;

            if (toggles) {
                let arr = this.showSubQuestions,
                    inc = arr.includes(toggles);
                if (check && !inc) arr.push(toggles);
                else if (!check && inc) this.clearInputsAndSubInputs(toggles);
            }
        },
        clearInputsAndSubInputs(id) {
            let arr = this.showSubQuestions;

            // Hide and clear input
            arr.splice(arr.indexOf(id),1);
            this.clearInput(id);

            // Clear and hide any subquestions one level deeper
            let subId = this.interactions?.[id]?.toggles
            if (subId) {
                this.clearInputsAndSubInputs(subId);
            }
        },
        clearInput(id) {
            Array.from(document.querySelectorAll(`[name="${id}"]`)).forEach(input => {
                let clearFunc = (i) => i.value = "";
                switch (input.tagName) {
                    case "INPUT":
                        switch (input.type) {
                            case "checkbox":
                            case "radio":
                                // Check if any are selected in options
                                clearFunc = (i) => {
                                    // uncheck the input
                                    i.checked = false;
                                    // create "change" event and fire it so the @change events
                                    // on the input trigger correctly and update the selectedNames computed value
                                    let evt = document.createEvent("HTMLEvents");
                                    evt.initEvent("change", false, true);
                                    i.dispatchEvent(evt);
                                }
                                break;
                        }
                        break;
                    case "SELECT":
                        clearFunc = (i) => i.options.forEach(o => o.selected = false);
                        break;
                }
                clearFunc(input);
            })
        },
        focusSection(id) {
            this.focussedQuestion = id;
            this.$emit('focus-section');
        },
        optionsInQuestion(id) {
            let optionIds = this.questions[id].options;
            let res = undefined;
            if (optionIds) {
                res = Object.entries(this.options).reduce((a, [k, v]) => {
                    if (optionIds.includes(parseInt(k))) a[k] = v;
                    return a;
                }, {})
            }
            return res;
        }
    },
    watch: {
        formResetInc(n,o) {
            if (n > o) this.showSubQuestions.splice(0);
        }
    }
}
</script>

<style lang="scss" scoped>
    .fr-section {
        top: 0;
        position: absolute;
        padding-bottom: 1rem;
        opacity: 0;
        pointer-events: none;
        width: 100%;

        &.fr-active {
            position: relative;
            opacity: 1;
            transform: translateX(0);
            pointer-events: all;
            transition: 200ms ease-out;
        }

        &.fr-before { transform: translateX(-1rem); }
        &.fr-after { transform: translateX(1rem); }
        &.fr-after, &.fr-before {
            height: 0;
            overflow: hidden;
        }

        &.fr-border {
            padding-left: 1rem;
            padding-right: 1rem;
            border: 1px solid black;
        }
    }

    .fr-section-title {
        margin-bottom: 1rem;
    }

    .fr-questions {
        margin: -.5rem;
    }

    .fr-question, .fr-sub-question {
        flex-grow: 1;
        padding: .5rem;
        max-width: 100%;
    }

    :deep(.fr-required) {
        color: var(--red-500);
    }
</style>
