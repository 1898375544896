<template>
    <DetailsLayout :name="'History'" :data="data" >
        <HistoryTable />
    </DetailsLayout>
</template>

<script>
    import HistoryTable from '../Tables/HistoryTable.vue';
    import DetailsLayout from '../Layouts/DetailsLayout.vue';

    export default {
        name: "History",
        components: {
            DetailsLayout,
            HistoryTable,
        },
        props: ["data"]
    }
</script>