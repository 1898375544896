<template>
    <AppLayout :title="`${data.full_name} | Details`">
        <div class="wrapper">
            <div class="title-wrapper">
                <JetDropdown align="right" width="max" class="m-4">
                    <template #trigger>
                        <button class="mark-button btn btn-blue px-6 py-1 m-0">
                            <p class="font-medium text-xs">Patient Settings</p>
                            <FontAwesomeIcon class="w-2.5 pl-2" :icon="icons.chevronDown" />
                        </button>
                    </template>
                    <template #content>
                        <div class="dropdown-wrapper" @click.stop="nothing()">
                            <form class="px-4 py-2" @submit.prevent="updatePatientStatus()">
                                <FRQuestionRadio
                                    :options="humanaOptions"
                                    :question="{question: 'Humana Client', options: Object.keys(humanaOptions)}"
                                    v-model="humana"
                                />

                                <FRQuestionRadio
                                    :options="statusOptions"
                                    :question="{question: 'Status of current client', options: Object.keys(statusOptions)}"
                                    v-model="status"
                                />

                                <FRQuestionRadio
                                    v-if="status === 'status_not_active'"
                                    :options="reasonOptions"
                                    :question="{question: 'What is the reason they are no longer being followed?', options: Object.keys(reasonOptions)}"
                                    v-model="reason"
                                />

                                <FRQuestionText
                                    v-if="reason === 'reason_delivery_not_completed'"
                                    :question="{question: 'Why wasn\'t the delivery completed?'}"
                                    v-model="delivery_reason"
                                />

                                <button class="btn btn-blue m-0">
                                    Update
                                </button>
                            </form>
                        </div>
                    </template>
                </JetDropdown>
                <h4>Patient Name: {{ data.full_name }}</h4>
            </div>
            <div class="subnav-btns">
                <Link
                    class="btn btn-filters"
                    :class="currentClass('Details')"
                    :href="route('patient.details', data.id)"
                >Details</Link>

                <Link
                    class="btn btn-filters"
                    :class="currentClass('DeliveryAndFollowUpOptions')"
                    :href="route('patient.delivery_follow_up_options', data.id)"
                >Delivery &amp; Follow-Up Options</Link>

                <Link
                    class="btn btn-filters"
                    :class="currentClass('History')"
                    :href="route('patient.history', data.id)"
                >History</Link>

                <Link
                    class="btn btn-filters"
                    :class="currentClass('Exceptions')"
                    :href="route('patient.exceptions', data.id)"
                >Exceptions</Link>

                <Link
                    class="btn btn-filters"
                    :class="currentClass('Notes')"
                    :href="route('patient.notes', data.id)"
                >Notes</Link>

                <Link
                    class="btn btn-filters"
                    :class="currentClass('Tasks')"
                    :href="route('patient.tasks', data.id)"
                >Tasks</Link>
            </div>

            <slot></slot>
        </div>
    </AppLayout>
</template>

<script>
    import FRQuestionRadio from '@/Components/FormElements/FRQuestionRadio.vue';
    import FRQuestionText from '@/Components/FormElements/FRQuestionText.vue';
    import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import JetDropdown from '@/Jetstream/Dropdown.vue';
    import AppLayout from '@/Layouts/AppLayout.vue';
    import { Link } from '@inertiajs/inertia-vue3';

    export default {
        props: ["data", "name"],
        components: {
            FontAwesomeIcon,
            FRQuestionRadio,
            FRQuestionText,
            JetDropdown,
            AppLayout,
            Link,
        },
        data() {
            return {
                icons: {
                    chevronDown: faChevronDown
                },
                status: this.data.status ? "status_active" : "status_not_active",
                statusOptions: {
                    "status_active":     {value: 'Active'},
                    "status_not_active": {value: 'Not-Active'}
                },
                humana: this.data.humana ? "humana_yes" : "humana_no",
                humanaOptions: {
                    "humana_yes":        {value: 'Yes'},
                    "humana_no":         {value: 'No'}
                },
                reason: "",
                reasonOptions: {
                    "reason_deceased":                 {value: 'Deceased'},
                    "reason_change_in_status":         {value: 'Change in status'},
                    "reason_funding_issues":           {value: 'Funding issues'},
                    "reason_unable_to_contact_client": {value: 'Unable to contact client'},
                    "reason_accessibility_issue":      {value: 'Accessibility issue'},
                    "reason_delivery_not_completed":   {value: 'Delivery not completed'},
                },
                delivery_reason: this.data.status_other_reason || ""
            }
        },
        watch: {
            status(n) {
                if (n == 'status_active') this.reason = "";
            },
            reason(n) {
                if (n !== 'reason_delivery_not_completed') this.delivery_reason = "";
            }
        },
        mounted() {
            this.reason = this.statusReasonToId(this.data.status_reason);
            if (!this.reason) this.reason="";
        },
        methods: {
            statusReasonToId(statusReason) {
                switch (statusReason) {
                    case 'Deceased': return 'reason_deceased'
                    case 'Change in status (i.e., no longer appropriate for recommended device)': return 'reason_change_in_status'
                    case 'Funding issues': return 'reason_funding_issues'
                    case 'Unable to contact client (i.e., contact information changed)': return 'reason_unable_to_contact_client'
                    case 'Environmental/Accessibility Issue': return 'reason_accessibility_issue'
                    case 'Delivery not completed (provide reason)': return 'reason_delivery_not_completed'
                }
            },
            statusIdToReason(statusReasonId) {
                switch (statusReasonId) {
                    case 'reason_deceased': return 'Deceased'
                    case 'reason_change_in_status': return 'Change in status (i.e., no longer appropriate for recommended device)'
                    case 'reason_funding_issues': return 'Funding issues'
                    case 'reason_unable_to_contact_client': return 'Unable to contact client (i.e., contact information changed)'
                    case 'reason_accessibility_issue': return 'Environmental/Accessibility Issue'
                    case 'reason_delivery_not_completed': return 'Delivery not completed (provide reason)'
                }
            },
            isCurrentPage(name) {
                return this.$page.component === `Patients/Views/${name}`;
            },
            currentClass(name) {
                return {active: this.isCurrentPage(name)};
            },
            nothing() {
                // Jetstream currently closes the dropdown if you click anywhere, even inside of it.
                // This is here to prevent that
            },
            updatePatientStatus() {
                // Axios post to your endpoint with the data formatted however you need it, then return a success message response object if successful otherwise an error message if unsuccessful
                axios.post('/patients/modify_patient_status',
                    {
                        'guid': this.data.guid,
                        'status': this.status === 'status_active' ? 1 : 0,
                        'humana': this.humana === 'humana_yes' ? 1 : 0,
                        'status_reason': this.statusIdToReason(this.reason) || null,
                        'status_other_reason': this.delivery_reason || null
                    })
                    .then(res => {
                        this.$page.props.jetstream.flash.banner = res.message || "Patient updated successfully!";
                    })
                    .catch(err => {
                        console.error(err);
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../scss/_mixins.scss';

    .wrapper {
        @include fluid-size(16px, 48px, padding);
        background-color: white;
    }

    .subnav-btns {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 1.75rem -.25rem;

        > * {
            margin: .25rem;
            flex-grow: 0;
        }
    }

    .title-wrapper {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        h4 {
            display: block;
            flex-grow: 1;
        }
    }

    .mark-button {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dropdown-wrapper {
        width: min(300px, calc(100vw - 3rem - 17px));

        form {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }
</style>
