<template>
	<TableWrapper
        :list="list"
        style="--cols: 5"
	>
		<div v-if="list.isLoading" class="loader"></div>

		<div class="st-hidden-sorts">
			<p @click="list.sortBy('full_name')" :class="list.sortClass('full_name')">ATP</p>
			<p @click="list.sortBy('last_login_date', null, 'date')" :class="list.sortClass('last_login_date')">Last Login</p>
		</div>

		<div class="simple-table">
			<p class="first-col st-header" @click="list.sortBy('full_name')" :class="list.sortClass('full_name')">ATP</p>
			<p class="st-header">Number</p>
			<p class="st-header">Division</p>
			<p class="st-header" @click="list.sortBy('last_login_date', null, 'date')" :class="list.sortClass('last_login_date')">Last Login</p>
			<p class="last-col st-header">Actions</p>

			<template v-for="row in list.displayedList" :key="row.id">
				<div class="first-col"><span class="st-hidden">ATP</span>{{ row.full_name }}</div>
				<div><span class="st-hidden">Number</span>{{ row.atp_number }}</div>
				<div><span class="st-hidden">Division</span>{{ row.company_name }}</div>
				<div><span class="st-hidden">Last Login</span>{{ row.last_login_date }}</div>
				<div>
					<span class="st-hidden">Actions</span>
					<div class="st-buttons">
						<button class="btn-blue" @click="patients(row.atp_number)" v-if="row.atp_number">Patients</button>
					</div>
				</div>
			</template>
		</div>
	</TableWrapper>
</template>

<script>
import EloquentSSFP from "@/EloquentSSFP";
import { reactive } from 'vue';
import TableWrapper from '@/Components/TableWrapper.vue';

export default {
    name: "ExceptionsTable",
    components: {
        TableWrapper
    },
	data: function () {
		return {
			list: reactive(new EloquentSSFP()),
		};
	},
	computed: {
		companyCookie() {
            let cookies = this.getCookiesAsObject();
            return 'company_filter_id' in cookies ? Number(cookies['company_filter_id']) : false;
        },
		cookieInc() {
			return this.$page.props.globalInc;
		}
	},
    methods: {
		getCookiesAsObject() {
            let reduce = (a,c) => { let [k,v] = c.trim().split('='); if (!(k in a)) a[k] = v; return a };
            return document.cookie.split(';').reduce(reduce, {});
        },
        patients(atp_number) {
            this.$inertia.visit(route('patients.by.atps', atp_number));
        },
    },
	watch: {
		cookieInc() {
			this.list.refreshData(this.list.options)
		},
	},
    mounted() {
        this.list.init('fetch_atps', {
            itemsPerPage: 20,
            searchableData: ["atp_number","full_name","company_name",]
        })
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../../scss/_simple-table-styles.scss';
</style>
