<template>
</template>

<script>
export default {
    name: "SplashScreen",
    props: {
        animate: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';

    .splash-background {
        background-color: var(--bk-blue);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
</style>