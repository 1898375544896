<template>
    <AppLayout title="Example Page">
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard', route:'dashboard'}, 'Example Page']" />
        </template>

        <Card title="Example Card">
            <!-- Put whatever here -->
            <ExampleTable
                :data="[{id:1,name:'Brett'},{id:2,name:'Amar'}]"
                :searchable-data="['name']"
            />
        </Card>
    </AppLayout>
</template>

<script>
    import ExampleTable from '@/Components/ExampleTable.vue'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import Card from '@/Components/Card.vue'

    export default {
        name: "ExampleLayout",
        components: {
            ExampleTable,
            Breadcrumbs,
            AppLayout,
            Card,
        }
    }
</script>
