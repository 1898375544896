<template>
    <div
        class="form-group"
        v-bind="groupBinder"
    >
        <div>
            <JetLabel :for="inputId" :value="inputLabel" />
            <select @change="checkRole($event)"
                class="border-gray-600 focus:border-blue-300 focus:ring focus:ring-blue-300 focus:ring-opacity-50 shadow-sm bg-white mt-1 block w-full"
                v-bind="inputBinder"
            >
                <option
                    v-for="(o, i) in options"
                    :key="i"
                    v-bind:value="o.value"
                    :hidden="o.hidden"
                    :disabled="o.disabled"
                    :selected="modelValue == o.value"
                >{{ o.name }}</option>
            </select>
        </div>
    </div>
</template>

<script>
import JetLabel from '@/Jetstream/Label.vue'
import uidMixin from '@/Mixins/uidMixin.js'
import { mixin } from './simpleFormMixin.js'

export default {
    mixins: [uidMixin,mixin],
    components: {
        JetLabel
    },
    props: {
        // options object shaped like this: {name: String, value: String/Number, hidden: Boolean, disabled: Boolean}
        options: {
            type: Array,
            required: true
        }
    },
    methods: {
        checkRole(data) {
            this.$emit('change-role', data);
        }
    }
}
</script>