<template>
    <AppLayout title="Resources">
        <Card title="Resources">
            <div class="subnav-btns">
                <button
                    @click="setActiveView('FMATrainingResources')"
                    :class="buttonFilter('FMATrainingResources')"
                    class="btn-filters"
                >FMA Training and Resources</button>
                <button
                    @click="setActiveView('OutcomesData')"
                    :class="buttonFilter('OutcomesData')"
                    class="btn-filters"
                >Outcomes Data</button>
                <button
                    @click="setActiveView('WhatIsFMA')"
                    :class="buttonFilter('WhatIsFMA')"
                    class="btn-filters"
                >What is FMA</button>
                <a
                    href="https://www.vgm.com/communities/us-rehab/"
                    title="external link to VGM.com"
                    target="_blank"
                    class="btn-filters"
                >What is US Rehab</a>
            </div>

            <FMATrainingResources v-if="activeView == 'FMATrainingResources'" />
            <OutcomesData v-if="activeView == 'OutcomesData'" />
            <WhatIsFMA v-if="activeView == 'WhatIsFMA'" />
        </Card>
    </AppLayout>
</template>

<script>
    import AppLayout from '@/Layouts/AppLayout.vue'
    import Card from '@/Components/Card.vue'
    import FMATrainingResources from '@/Pages/Resources/FMATrainingResources.vue'
    import OutcomesData from '@/Pages/Resources/OutcomesData.vue'
    import WhatIsFMA from '@/Pages/Resources/WhatIsFMA.vue'

    export default {
        components: {
            AppLayout,
            Card,
            FMATrainingResources,
            OutcomesData,
            WhatIsFMA,
        },
        data() {
            return {
                activeView: 'FMATrainingResources',
            }
        },
        methods: {
            setActiveView(view) {
                this.activeView = view;
            },
            buttonFilter(view) {
                return `${view && this.activeView == view ? ' active' : ''}`;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .subnav-btns {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 -.25rem 1.75rem -.25rem;

        > * {
            margin: .25rem;
            flex-grow: 0;
        }
    }
</style>
