<template>
    <Head title="Reset Password" />

    <SplashScreen />

    <JetAuthenticationCard>
        <template #logo>
            <JetAuthenticationCardLogo />
        </template>

        <JetValidationErrors class="mb-4" />

        <form @submit.prevent="submit">
            <div>
                <JetLabel for="email" value="Email" />
                <JetInput id="email" type="email" class="mt-1 block w-full" v-model="form.email" required autofocus />
            </div>

            <div class="mt-4">
                <SimplePassword v-model="form.password" autocomplete="new-password" />
            </div>

            <div class="mt-4">
                <SimplePassword v-model="form.password_confirmation" label-value="Confirm Password" autocomplete="new-password" />
            </div>

            <div class="flex items-center justify-end mt-4">
                <JetButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    Reset Password
                </JetButton>
            </div>
        </form>
    </JetAuthenticationCard>
</template>

<script>
    import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue'
    import SimplePassword from '@/Components/FormElements/SimplePassword.vue'
    import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
    import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
    import { Head } from '@inertiajs/inertia-vue3';
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetLabel from '@/Jetstream/Label.vue'

    export default {
        components: {
            JetAuthenticationCardLogo,
            JetAuthenticationCard,
            JetValidationErrors,
            SimplePassword,
            JetButton,
            JetInput,
            JetLabel,
            Head,
        },

        props: {
            email: String,
            token: String,
        },

        data() {
            return {
                form: this.$inertia.form({
                    token: this.token,
                    email: this.email,
                    password: '',
                    password_confirmation: '',
                })
            }
        },

        methods: {
            submit() {
                this.form.post(this.route('password.update'), {
                    onFinish: () => this.form.reset('password', 'password_confirmation'),
                })
            }
        }
    }
</script>
