<template>
	<div class="h-screen flex flex-col justify-center items-center sm:pt-0 py-6">
		<div>
			<slot name="logo" />
		</div>

		<div class="w-full sm:max-w-md mt-6 px-6 py-4 bg-gray-100 shadow-md overflow-auto">
			<slot />
		</div>
	</div>
</template>