<template>
    <nav class="sidebar" :class="{expanded: expandedSideBar}">
        <div class="sidenav-toggle" @click="openToggle()">
            <FontAwesomeIcon class="ease-in duration-300" fixed-width :icon="arrowLeft" v-if="expandedSideBar"/>
            <FontAwesomeIcon class="ease-in duration-300" fixed-width :icon="arrowRight" v-else/>
        </div>
        <div class="navigation">
            <div class="navigation-link" :class="{active: route().current('dashboard')}">
                <Link class="text-gray-700" :href="route('dashboard')" title="Overview">
                    <FontAwesomeIcon fixed-width :icon="overview"/><span>Overview</span>
                </Link>
            </div>
            <div class="navigation-link" :class="{active: $page.component.startsWith('Patients')}">
                <Link class="text-gray-700" :href="route('patients.index')" title="Patients">
                    <FontAwesomeIcon fixed-width :icon="patients"/><span>Patients</span>
                </Link>
            </div>
            <div class="navigation-link" :class="{active: $page.component.startsWith('Forms')}">
                <Link class="text-gray-700" :href="route('forms-grid.index')" title="Forms">
                    <FontAwesomeIcon fixed-width :icon="resources"/><span>Forms</span>
                </Link>
            </div>
            <div class="navigation-link" :class="{active: route().current('atps.index')}" >
                <Link class="text-gray-700" :href="route('atps.index')" title="ATPs">
                    <FontAwesomeIcon fixed-width :icon="atps"/><span>ATPs</span>
                </Link>
            </div>
            <div class="navigation-link" :class="{active: $page.component.startsWith('Resources')}" >
                <Link class="text-gray-700" :href="route('resources.index')" title="Resources">
                    <FontAwesomeIcon fixed-width :icon="resources"/><span>Resources</span>
                </Link>
            </div>
            <div class="navigation-link" :class="{active: route().current('analytics.index')}" >
                <Link class="text-gray-700" :href="route('analytics.index')" title="Analytics">
                    <FontAwesomeIcon fixed-width :icon="analytics"/><span>Analytics</span>
                </Link>
            </div>
            <div class="navigation-link" :class="{active: $page.component.startsWith('Manage')}" v-if="['AD'].some(r => $page.props.user.role_type == r)">
                <Link class="text-gray-700" :href="route('manage.users')" title="Manage Users">
                    <FontAwesomeIcon fixed-width :icon="users"/><span>Manage Users</span>
                </Link>
            </div>
        </div>
        <div class="numbers">
            <p
                v-if="userCompany.vgm_number"
                @click="copyToClipboard(userCompany.vgm_number)"
            >
                <span>Your VGM #:</span>
                {{ userCompany.vgm_number }}
            </p>

            <p
                v-if="$page.props.user.atp_number"
                @click="copyToClipboard($page.props.user.atp_number)"
            >
                <span>Your ATP #:</span>
                {{ $page.props.user.atp_number }}
            </p>

            <p
                v-if="cookieCompany.vgm_number"
                @click="copyToClipboard(cookieCompany.vgm_number)"
            >
                <span>Company VGM #:</span>
                {{ cookieCompany.vgm_number }}
            </p>
        </div>
    </nav>
</template>

<script>
import { reactive } from 'vue';
import { faUsers, faHome, faHeart, faIdBadge, faFolderOpen, faPoll, faTools, faExclamationCircle, faTimes, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Link } from '@inertiajs/inertia-vue3';

export default {
    name: "AppSidebar",
    components: {
        JetApplicationLogo,
        FontAwesomeIcon,
        Link,
    },
    data() {
        return {
            expandedSideBar: true,
            users: faUsers,
            overview: faHome,
            patients: faHeart,
            atps: faIdBadge,
            resources: faFolderOpen,
            analytics: faPoll,
            tools: faTools,
            alert: faExclamationCircle,
            close: faTimes,
            arrowLeft: faChevronLeft,
            arrowRight: faChevronRight,
            userCompany: reactive({}),
            cookieCompany: reactive({})
        }
    },
    watch: {
        expandedSideBar(n,o) {
            if (!n) document.body.style.overflow = '';
        }
    },
    computed: {
        companyCookie() {
            let cookies = this.getCookiesAsObject();
            return 'company_filter_id' in cookies ? Number(cookies['company_filter_id']) : false;
        }
    },
    methods: {
        getCookiesAsObject() {
            let reduce = (a,c) => { let [k,v] = c.trim().split('='); if (!(k in a)) a[k] = v; return a };
            return document.cookie.split(';').reduce(reduce, {});
        },
        fetchCookieCompanyData(id) {
            axios.get(`/fetch_company/${id}`)
                .then(res => this.cookieCompany = res.data.data)
                .catch(err => console.error(err));
        },
        fetchUserCompanyData(id) {
            axios.get(`/fetch_company/${id}`)
                .then(res => this.userCompany = res.data.data)
                .catch(err => console.error(err));
        },
        openToggle() {
            this.expandedSideBar = !this.expandedSideBar;
            // document.body.style.overflow = this.expandedSideBar ? 'hidden' : '';
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text);
            this.$page.props.jetstream.flash.banner = `${text} successfully copied to clipboard`;
        }
    },
    mounted() {
        if (Number(this.companyCookie)) this.fetchCookieCompanyData(this.companyCookie);
        if (Number(this.$page.props.user.company_id)) this.fetchUserCompanyData(this.$page.props.user.company_id);
    }
}
</script>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';

    .sidebar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        height: 100%;
        transition: 300ms cubic-bezier(0.2, 0, 0, 1) 0s;

        @include not-desktop {
            overflow: hidden;
            overflow-y: auto;
            position: fixed;
            width: 100%;
            left: -100%;
            transition: 200ms ease-out;
            z-index: 3;

            .app.expanded & {
                left: 0;
                top: 0;
            }
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: white;

            @include desktop {
                box-shadow: 2px 0 5px rgba(0,0,0,.1);
            }
        }
    }

    .sidenav-toggle {
        display: none;

        @include desktop {
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;
            top: 1rem;
            right: -10px;
            height: 20px;
            width: 20px;
            border-radius: 100%;
            background-color: var(--blue-500);
            z-index: 4;
            cursor: pointer;

            .fa-fw {
                color: white;
                width: 0.5rem;
                height: auto;
                margin: 4px 5px;
                transition: 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
            }
        }
    }

    .navigation {
        display: flex;
        flex-direction: column;

        .navigation-link {
            position: relative;
            top: 0;

            span {
                @include fluid-size(14px, 16px, margin-left);
            }

            &:before {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: white;
                box-shadow: 0 0 0 rgba(0,0,0,0);
                transition: 200ms ease-out;
            }

            &.active, &:hover {
                &:before {
                    background-color: var(--blue-100);
                    box-shadow: 0 2px 5px rgba(0,0,0,.1);
                }

                a { color: var(--blue-500); }
            }

            a {
                @include fluid-size(16px, 18px, font-size);
                @include fluid-size(10px, 14px, padding-top);
                @include fluid-size(10px, 14px, padding-bottom);
                @include fluid-size(12px, 18px, padding-right);
                @include fluid-size(12px, 18px, padding-left);
                display: block;
                font-weight: 500;
                position: relative;
                color: var(--dark);
                transition: 200ms ease-out;

                span { display: none; }

                .sidebar.expanded & {
                    @include fluid-size(10px, 14px, padding-top);
                    @include fluid-size(10px, 14px, padding-bottom);
                    @include fluid-size(16px, 32px, padding-right);
                    @include fluid-size(16px, 32px, padding-left);

                    span { display: inline; }
                }
            }

            &.child {
                background-color: #F2F2F2;
            }
        }

        .navigation-child-group {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: #F2F2F2;
                box-shadow: inset 0 0px 5px 2px rgb(0,0,0,.1);
            }
        }

        .navigation-child {
            position: relative;

            &.active, &:hover {
                a { color: var(--blue-500); }
            }

            a {
                @include fluid-size(16px, 18px, font-size);
                @include fluid-size(8px, 16px, padding-top);
                @include fluid-size(8px, 16px, padding-bottom);
                @include fluid-size(16px, 32px, padding-right);
                @include fluid-size(16px, 32px, padding-left);
                display: block;
                font-size: 22px;
                font-weight: bold;
                position: relative;
                color: var(--gray-700);
                transition: 200ms ease-out;
            }
        }
    }

    .numbers {
        position: relative;
        padding: .5rem;
        display: none;

        .sidebar.expanded & {
            display: block;
        }

        p {
            cursor: pointer;
            color: var(--blue-500);
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: .5rem;
            flex-wrap: wrap;
            font-size: 12px;

            span {
                font-weight: bold;
            }
        }
    }

</style>
