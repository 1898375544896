<template>
    <Head title="Register" />

    <SplashScreen />

    <PortalRequestCard>
        <template #logo>
            <JetAuthenticationCardLogo />
        </template>

        <JetValidationErrors class="mb-4" />
            <div class="loader" v-if="loading"></div>
            <div v-if="validate_msg != ''" class="bg-green-100 p-2">
                <p class="text-green-500 my-0">{{validate_msg}}</p>
            </div>

            <form enctype="multipart/form-data" ref="form" @submit.prevent="submitForm">   
            
                <div class="flex flex-wrap flex-grow">
                    <div class="p-2 flex-grow">
                        <JetLabel for="name" value="First Name" />
                        <JetInput id="first_name" name="first_name" type="text" class="mt-1 block w-full" v-model="form.first_name" required autofocus autocomplete="first_name" placeholder="First Name"/>
                    </div>

                    <div class="p-2 flex-grow">
                        <JetLabel for="name" value="Last Name" />
                        <JetInput id="last_name" name="last_name" type="text" class="mt-1 block w-full" v-model="form.last_name" autofocus autocomplete="last_name" placeholder="Last Name" required/>
                    </div> 
                </div>
                

                <div class="flex flex-wrap flex-grow">
                    <div class="p-2 flex-grow">
                        <JetLabel for="name" value="Company" />
                        <JetInput id="company" name="company" type="text" class="mt-1 block w-full" v-model="form.company" autofocus autocomplete="company"  placeholder="Company Name" required/>
                    </div>

                    <div class="p-2 flex-grow">
                        <JetLabel for="name" value="State" />
                        <JetInput id="state" name="state" type="text" class="mt-1 block w-full" v-model="form.state" autofocus autocomplete="state"  placeholder="State"/>
                    </div>
                </div>

                <div class="pl-2 mt-4">
                    <JetLabel for="email" value="Email" />
                    <JetInput id="email" name="email" type="email" class="mt-1 block w-full" v-model="form.email" required  placeholder="Email Address"/>
                </div>

                <div class="pl-2 mt-4">
                    <JetLabel for="mobile" value="Mobile" />
                    <JetInput id="mobile" name="mobile" type="text" class="mt-1 block w-full" v-model="form.mobile" required  placeholder="Mobile Number"/>
                </div>

                <div class="pl-2 mt-4">
                    <JetLabel for="name" value="Policy Number" />
                    <JetInput id="policy_number" name="policy_number" type="text" class="mt-1 block w-full" v-model="form.policy_number" autofocus autocomplete="policy_number"  placeholder="Policy Number"/>
                </div>

                <div class="pl-2 mt-4">
                    <div class="g-recaptcha" id="rcaptcha" :data-sitekey="this.data.google_sitekey" data-callback="onSubmit"  data-size="invisible"></div>
                    <span id="captcha" style="color:red"></span>
                </div>

                <div class="flex flex-wrap items-center justify-between mt-3 -mx-1 -mb-1">  
                    <Link :href="route('login')" class="pl-2 underline text-left text-sm text-gray-900 hover:text-dark m-1">
                        Back
                    </Link>

                    <JetButton class="ml-4" :class="{ 'opacity-25': registerBtnDisabled }">
                        Send Request
                    </JetButton>
                </div>
            </form>
    </PortalRequestCard>

</template>

<script>
    import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue'
    import SimplePassword from '@/Components/FormElements/SimplePassword.vue'
    import PortalRequestCard from '@/Jetstream/PortalRequestCard.vue'
    import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
    import SplashScreen from '@/Components/SplashScreen.vue'
    import SimpleModal from '@/Components/SimpleModal.vue'
    import { Head, Link } from '@inertiajs/inertia-vue3'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import formProcessingMixin from '@/Mixins/formProcessingMixin.js'

    export default {
        components: {
            JetAuthenticationCardLogo,
            PortalRequestCard,
            JetValidationErrors,
            SimplePassword,
            SplashScreen,
            JetCheckbox,
            SimpleModal,
            JetButton,
            JetLabel,
            JetInput,
            Head,
            Link,
        },
        mixins: [formProcessingMixin],
        props: ['data', 'errors'],
        data() {
            return { 
                form: this.$inertia.form({
                    first_name: '',
                    last_name: '',
                    company: '',
                    state: '',
                    email: '',
                    mobile: '',                    
                    policy_number: '',
                }),
                validate_msg: '',
                loading: false,
            }
        }, 
        mounted() {
            let recaptchaScript = document.createElement('script')
            recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
            document.head.appendChild(recaptchaScript)
            recpatchaKey: this.data.google_sitekey
        },
        methods: {
            submitForm(event) {
                this.loading = true,
                axios.post('savePortalRequestData', this.form)
                    .then( response =>  { 
                    this.loading = false, 
                    this.reset();
                    this.validate_msg = response.data.message;
                    setTimeout(() => this.validate_msg = false, 20000) 
                    setTimeout(() =>  this.$inertia.visit(route('login')), 10000)
                })
            }
        }
    }
</script>
